import { useCallback, useMemo, useState } from 'react';
import {
  Stack,
  Select,
  Card,
  useColorModeValue,
  FormLabel,
  Heading,
  Textarea,
  useToast,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useCompanyStore } from 'contexts/globalStoreCompanies';
import useUserDataRolesStore from 'contexts/authStore';
import { useLocation } from 'react-router-dom';

const CompanyWorklogForm = ({ viewOnly, handleWorklogLoading }: { viewOnly: boolean, handleWorklogLoading: () => void }) => {
  const toast = useToast();
  const location = useLocation();
  const isCreatePage = useMemo(() => location.pathname.includes('create'), [location]);

  const userId = useUserDataRolesStore((state) => state.userData.id);

  const worklogEntry = useCompanyStore((state) => state.worklogEntry);
  const worklogStatuses = useCompanyStore((state) => state.worklogStatuses);
  const addNewEntryToCompanyWorklog = useCompanyStore(
    (state) => state.addEntryToCompanyWorklog,
  );
  const setWorklogEntry = useCompanyStore(
    (state) => state.setWorklogEntry,
  );
  const resetWorklogEntry = useCompanyStore(
    (state) => state.resetWorklogEntry,
  );

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleEntryChange = (field: any, value: any) => {
    setWorklogEntry(field, value);
  };

  const handleSubmit = useCallback(async () => {
    setIsFormSubmitting(true);

    try {
      const res: any = await addNewEntryToCompanyWorklog(worklogEntry, userId);
      if (res) {
        toast({
          title: res.type === 'success' ? t('success', { ns: ['labels'] }) : t('error', { ns: ['labels'] }),
          description: res.message,
          status: res.type,
        });
        handleWorklogLoading();
        resetWorklogEntry();
      }
    } catch (err: any) {
      toast({
        title: t('error', { ns: ['labels'] }),
        description: err.message,
        status: err.type,
      });
    };

    setIsFormSubmitting(false);
  }, [addNewEntryToCompanyWorklog, worklogEntry, userId, toast, handleWorklogLoading, resetWorklogEntry]);

  const textColor = useColorModeValue('navy.700', 'white');

  return (
    <Stack
      direction="column"
      gap="20px"
      gridColumn={{ base: '1', md: '1/2' }}
      mb="30px"
      mr={{ base: '0', md: '20px' }}
    >
      <Card p={4} w={'100%'}>
        <Heading fontSize="20px" mb={'20px'}>
          {isCreatePage ? t('cantWorklogBeforeCompanyCreate', { ns: ['labels'] }) : t('newEntry', { ns: ['labels'] })}
        </Heading>
        {!isCreatePage ? (
          <>
            <Stack
              direction={'column'}
              mb={'20px'}
            >
              <FormLabel
                htmlFor="status"
                fontSize="sm"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                {t('status', { ns: ['labels'] }) + '*'}
              </FormLabel>
              <Stack direction="column">
                <Select
                  defaultValue={worklogEntry.status || worklogStatuses[0]}
                  onChange={(event) =>
                    handleEntryChange('status', event.target.value)
                  }
                  disabled={viewOnly}
                >
                  {worklogStatuses.map((status: string) => (
                    <option
                      key={status}
                      value={status}
                    >
                      {t(status, { ns: ['status'] })}
                    </option>
                  ))}
                </Select>
              </Stack>
            </Stack>
            <Stack
              direction={'column'}
              mb={'20px'}
            >
              <FormLabel
                htmlFor="comment"
                fontSize="sm"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                {t('comment', { ns: ['labels'] })}
              </FormLabel>
              <Textarea
                rows={4}
                value={worklogEntry.comment || ''}
                color={textColor}
                placeholder={t('comment', { ns: ['labels'] })}
                onChange={(event) =>
                  handleEntryChange('comment', event.target.value)
                }
                disabled={viewOnly}
              />
            </Stack>
            <Button
              variant="darkBrand"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '128px', md: '148px' }}
              h="46px"
              onClick={handleSubmit}
            >
              {isFormSubmitting ? (<><Spinner size="sm" mr="2" />
                {t('loading', { ns: ['labels'] })}</>) :
                t('submit', { ns: ['labels'] })
              }

            </Button>
          </>
        ) : null}
      </Card>
    </Stack>
  );
};

export default CompanyWorklogForm;
