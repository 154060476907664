import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import {
  Flex,
  Text,
  useColorModeValue,
  Icon,
  Stack,
  Select,
  Input,
  IconButton,
  SimpleGrid,
  Spacer,
  Spinner
} from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';
import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import { PostosProject, useProjectStore } from 'contexts/globalStoreProjects';
import useLoaderStore from 'contexts/globalStore';
import { RepeatIcon } from '@chakra-ui/icons';
import { useBookingStore } from 'contexts/globalStoreTimeBookings';
import { useReportsStore } from 'contexts/globalStoreReports';
import { tableStorage } from 'contexts/tableStorage';
import { User, useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';
import useUserDataRolesStore from 'contexts/authStore';
import { convertMinutesToHHmmForInput, zonedEndOfMonth } from 'utils/dateHelpers';
import BookingStatusBadge from 'views/faktura/bookings/components/BookingStatusBadge';
import FakturaTable from 'components/tables/FakturaTable';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = {
  select: string;
  user: string;
  project: string;
  dateFrom: string;
  duration: string;
  unit: string;
  description: string;
  status: string;
  id: number;
  ticketId: string;
};

export default function SearchTableReports() {

  const textColor = useColorModeValue('navy.700', 'white');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const formatDate = useBookingStore((state) => state.formatDate);

  const isInitDone = useReportsStore((state) => state.isInitDone);
  const { fakturaReports } = useReportsStore(
    (state: any) => ({ fakturaReports: state.reports }),
  );
  const { reportsMeta } = useReportsStore(
    (state: any) => ({ reportsMeta: state.reportsMeta }),
  )
  const { fakturaUsers } = useFakturaUsersStore(
    (state: any) => ({ fakturaUsers: state.users }),
  );
  const { fakturaProjects } = useProjectStore(
    (state: any) => ({ fakturaProjects: state.projects }),
  );
  const fetchAllProjectUsers = useProjectStore((state) => state.fetchAllProjectUsers);
  const userData = useUserDataRolesStore((state) => state.userData);
  const getUserAdminProjects = useProjectStore((state) => state.getUserAdminProjects);
  const [userAdminProjects, setUserAdminProjects] = useState<PostosProject[]>([]);

  const getReports = useCallback(async () => {
    await fetchAllProjectUsers();
    const newUserProjects = await getUserAdminProjects(userData);
    // if (activeProjectsOnly) {
    //   setUserAdminProjects(newUserProjects.filter((project) => project.isActive));
    // } else {
    setUserAdminProjects(newUserProjects);
    // }
  }, [fetchAllProjectUsers, getUserAdminProjects, userData]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const beginningOfLastMonth = () => {
    let date = new Date(); // Current date
    date.setMonth(date.getMonth() - 1); // Subtract one month
    date.setDate(1); // Set to the 1st of the resulting month
    date.setHours(2, 0, 0, 0); // Set time to the start of the day
    return date.toISOString();
  };

  const [startDate, setStartDate] = useState(
    formatDate(beginningOfLastMonth(), 'date'),
  );
  const [endDate, setEndDate] = useState(
    formatDate(new Date().toISOString(), 'date'),
  );

  const [projectId, setProjectId] = useState<number | null>(null);

  const [activeProjectsOnly, setActiveProjectsOnly] = useState<boolean>(true);

  const fetchReports = useReportsStore((state) => state.fetchReports);
  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);
  const resetReportList = useReportsStore((state) => state.resetReportList);

  const refreshData = useCallback(() => {
    if (startDate && endDate) {
      setLoadingState(true);
      fetchReports(
        startDate,
        zonedEndOfMonth(new Date(endDate)),
        projectId,
        activeProjectsOnly,
        pagination.pageIndex + 1,
        pagination.pageSize,
        sorting[0]?.id || null,
        sorting[0]?.desc ? 'desc' : 'asc',
      )
        .then(() => {
          setLoadingState(false);
        })
        .catch((error) => {
          setLoadingState(false);
          console.error('Error fetching Faktura Reports:', error);
        });
    } else {
      resetReportList();
    }
  }, [
    startDate,
    endDate,
    projectId,
    activeProjectsOnly,
    pagination,
    sorting,
    fetchReports,
    resetReportList,
    setLoadingState,
  ]);

  const handleStartDateChange = (event: any) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (event: any) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
  };
  const handleProjectIdChange = (event: any) => {
    const newProjectId = event.target.value;
    setProjectId(newProjectId);
  };
  // const handleActiveProjectsToggle = (value: boolean) => {
  //   const newActiveProjectsOnlyValue = value;
  //   setActiveProjectsOnly(newActiveProjectsOnlyValue);
  // };

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && isInitDone) {
      refreshData();
    }

    return () => {
      isCurrent = false;
    };
  }, [isInitDone, refreshData]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaReports);
      setProjectData(fakturaProjects);
    }
    return () => {
      isCurrent = false;
    };
  }, [fakturaReports, fakturaProjects]);

  const [globalFilter, setGlobalFilter] = useState('');

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          id
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          #{info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('user', {
      id: 'user',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const userId = info.row.original.userId;
        const user = fakturaUsers.find((user: User) => user.userId === userId);
        if (user) {
          const userDisplay = `${user.name} (${user.email})`;
          return (
            <Text color={textColor} key={userId} fontSize="md" fontWeight="500">
              {userDisplay}
            </Text>
          );
        } else {
          return null;
        }
      },
    }),

    columnHelper.accessor('project', {
      id: 'project',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('project', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const projectId = info.row.original.projectId;
        const project = projectData.find((project) => project.id === projectId);
        if (project) {
          return (
            <Flex alignItems={'center'}>
              <Text
                color={textColor}
                key={projectId}
                fontSize="md"
                fontWeight="500"
              >
                {project.title}
              </Text>
              <Icon
                ml="5px"
                color={project.isActive ? 'green.500' : 'gray.500'}
                as={MdCircle}
                w="10px"
                h={'10px'}
              />
            </Flex>
          );
        } else {
          return null;
        }
      },
    }),
    columnHelper.accessor('ticketId', {
      id: 'ticket-id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('ticket', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('dateFrom', {
      id: 'dateFrom',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('dateFrom', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const dateValue = new Date(info.getValue()).toLocaleDateString();
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {dateValue}
          </Text>
        );
      },
    }),

    columnHelper.accessor('duration', {
      id: 'duration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('amount', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
          }}
        >
          {convertMinutesToHHmmForInput(Number(info.getValue()))}
        </Text>
      ),
    }),

    columnHelper.accessor('unit', {
      id: 'unit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('unit', { ns: ['labels'] })}
        </Text>
      ),
      cell: () => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {t('hours', { ns: ['labels'] })}
        </Text>
      ),
    }),

    columnHelper.accessor('description', {
      id: 'description',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('description', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            maxWidth: '8ch',
          }}
        >
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          status
        </Text>
      ),
      cell: (info) => (<BookingStatusBadge status={info.getValue()} />),
    }),
  ];

  const [data, setData] = useState(() => []);
  const [projectData, setProjectData] = useState(() => []);

  const tableName = useMemo(() => 'reports', []);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      startDate: newStartDate,
      endDate: newEndDate,
      projectId: newProjectId,
      activeProjectsOnly: newActiveProjectsOnly,
      pageSize: newPageSize,
      sorting: newSorting
    } = tableStorage.fetch(tableName)

    if (newGlobalFilter) setGlobalFilter(newGlobalFilter);
    if (newStartDate) setStartDate(newStartDate);
    if (newEndDate) setEndDate(newEndDate);
    if (newProjectId) setProjectId(newProjectId);
    if (newActiveProjectsOnly) setActiveProjectsOnly(newActiveProjectsOnly);
    if (newPageSize) setPagination({ ...pagination, pageSize: newPageSize });
    if (newSorting) setSorting(newSorting)
    // Don't add 'pagination' below to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, {
      globalFilter,
      startDate,
      endDate,
      projectId,
      activeProjectsOnly,
      pageSize: pagination.pageSize,
      sorting
    })
  }, [globalFilter,
    startDate,
    endDate,
    projectId,
    tableName,
    activeProjectsOnly,
    pagination.pageSize,
    sorting])

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting
    },
    pageCount: reportsMeta.totalPages,
    rowCount: reportsMeta.totalItems,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'auto', lg: 'auto' }}>
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify={{ sm: 'flex-start', lg: 'space-between' }}
        w="100%"
        px="22px"
        mb="10px"
        minWidth="max-content"
        gap="4"
      >
        <SimpleGrid columns={{ base: 1, xl: 4 }} gap="5px">
          <Stack direction="column" gap="20px" width="200px">
            <Input
              type="date"
              size="md"
              value={startDate || ''}
              color={textColor}
              onChange={handleStartDateChange}
            />
          </Stack>
          <Stack direction="column" gap="20px" width="200px">
            <Input
              type="date"
              size="md"
              value={endDate || ''}
              color={textColor}
              onChange={handleEndDateChange}
            />
          </Stack>
          <Stack direction="column" gap="20px" width="200px">
            <Select
              placeholder={t('projects', { ns: ['labels'] })}
              value={projectId || ''}
              onChange={handleProjectIdChange}
            >
              {[...userAdminProjects]?.sort(
                (a, b) => a.title.localeCompare(b.title)
              ).map((project: any) => (
                <option key={project.id} value={project.id}>
                  {project.title}
                </option>
              ))}
            </Select>
          </Stack>
          {/* <Stack direction="column" gap="20px" ml={2} justifyContent={'center'}>
            <Checkbox
              isChecked={activeProjectsOnly}
              onChange={(event: any) => {
                handleActiveProjectsToggle(event.target.checked);
              }}
            >
              {t("onlyActiveProjects", { ns: ["labels"] })}
            </Checkbox>
          </Stack> */}
        </SimpleGrid>
        {/* Only display Spacer on larger screens */}
        <Spacer display={{ sm: 'none', lg: 'block' }} />

        {loadingState ? (
          <Spinner />
        ) : (
          <IconButton
            aria-label="Reload"
            variant="brand"
            icon={<RepeatIcon />}
            onClick={refreshData}
          >
            Reload
          </IconButton>
        )}
      </Flex>
      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Flex>);
}
