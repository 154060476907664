import { Badge } from '@chakra-ui/react';
import { t } from 'i18next';
import { useMemo } from 'react';

export default function BookingStatusBadge({ status }: { status: string }) {
  const colorScheme = useMemo(() => {
    switch (status) {
      case 'ACCEPTED':
        return 'green';
      case 'DECLINED':
        return 'red';
      case 'DRAFT':
        return 'blue';
      case 'COMMIT':
        return 'yellow';
      case 'INVOICED':
        return 'green';
      case 'PAID':
        return 'green';
      case 'OBSOLETE':
        return 'gray';
      default:
        return 'gray';
    }
  }, [status]);

  return (
    <Badge colorScheme={colorScheme} fontSize="sm" fontWeight="500" size="sm">
      {t(status, { ns: ['status'] })}
    </Badge>
  );
}
