import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, useColorModeValue } from "@chakra-ui/react";
import { t } from "i18next";
import { matchPath, useLocation } from "react-router-dom";
import { routesParams } from "router";
import { capitalizeFirstLetter } from "utils/stringHelpers";



export default function Breadcrumbs() {
  const secondaryText = useColorModeValue('gray.700', 'white');

  const location = useLocation();
  const matchedRoute = routesParams.find(route => matchPath(location.pathname, route.path));
  const crumbs = matchedRoute?.crumbs || [];

  return (
    crumbs?.length ? (
      <Breadcrumb>
        {crumbs?.map((crumb: Crumb) => {
          const defaultName: string = capitalizeFirstLetter(crumb.name);
          return (
            <BreadcrumbItem key={crumb.name} color={secondaryText} fontSize="sm" mb="5px">
              {!crumb.isCurrent ? (
                <BreadcrumbLink key={crumb.name} href={crumb.path} color={secondaryText}>
                  {t(crumb.name, { ns: ['routes'], defaultValue: defaultName })}
                </BreadcrumbLink>
              ) : <BreadcrumbLink key={crumb.name} color={secondaryText}>
                {t(crumb.name, { ns: ['routes'], defaultValue: defaultName })}
              </BreadcrumbLink>}
            </BreadcrumbItem>
          )
        })}
      </Breadcrumb>
    ) : null
  )
}