// Chakra Imports
import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { FaEthereum } from 'react-icons/fa';
import Configurator from 'components/navbar/Configurator';
import auth from '../../auth/index';
import useUserDataRolesStore from 'contexts/authStore';
import { t } from 'i18next';
export default function HeaderLinks(props: {
  [x: string]: any;
}) {
  const { theme, setTheme } = props;
  // Chakra Color Mode
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const { userData } = useUserDataRolesStore(
    (state: any) => ({ userData: state.userData }),
  );

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={'unset'}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
    >
      <Flex
        bg={ethBg}
        display={'none'}
        borderRadius="20px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="20px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive />
      <Configurator
        mini={props.mini}
        setMini={props.setMini}
        theme={theme}
        setTheme={setTheme}
      />

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color={textColor}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          pr={'15px'}
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          borderBottom="1px solid"
          borderColor={borderColor}
        >
          <Flex w="100%" mb="0px">
            <Flex
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
              flexDirection={'column'}
            >
              <Text>👋 &nbsp; {t('hello', { ns: ['labels'] })}, {userData?.name || t('user', { ns: ['labels'] })}!</Text>
              <Text>User ID: {userData?.id}</Text>
              <Text>{userData?.email || t('no', { ns: ['labels'] })}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* <MenuItem
              _hover={{ bg: borderColor }}
              _focus={{ bg: borderColor }}
              borderRadius="12px"
              bg={menuBg}
              px="14px"
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: borderColor }}
              _focus={{ bg: borderColor }}
              borderRadius="12px"
              bg={menuBg}
              px="14px"
            >
              <Text fontSize="sm">Newsletter Settings</Text>
            </MenuItem> */}
            <MenuItem
              _hover={{ bg: borderColor }}
              _focus={{ bg: borderColor }}
              color="red.400"
              borderRadius="12px"
              bg={menuBg}
              px="14px"
              onClick={() => auth.logout()}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
