import { endOfMonth, format, startOfMonth, addMinutes } from "date-fns";
import { DateTime } from 'luxon';

export const getMonthRangeFromDateValue = (dateValue: string) => {
    const selectedDate = new Date(dateValue);
    const startDay = startOfMonth(selectedDate);
    const endDay = endOfMonth(selectedDate);
    const formattedStartDay = format(startDay, 'yyyy-MM-dd');
    const formattedEndDay = format(endDay, 'yyyy-MM-dd');
    return {
        startDay: formattedStartDay,
        endDay: formattedEndDay
    }
}

export const getSimpleDateForInputFromFullDateString = (dateValue: string) => {
    const selectedDate = new Date(dateValue);
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    return formattedDate
}

export const getDateForInputFromFullDateString = (dateValue: string) => {
    const selectedDate = new Date(dateValue);
    const adjustedDate = addMinutes(selectedDate, selectedDate.getTimezoneOffset());
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd\'T\'HH:mm');
    return formattedDate;
}

export const getDateTillFromDateAndDuration = (dateFrom: string, durationInMinutes: number) => {
    const selectedDate = new Date(dateFrom);
    const adjustedDate = addMinutes(selectedDate, durationInMinutes);
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd\'T\'HH:mm');
    return formattedDate;
}

export const getDefaultDateFromSimpleDateString = (dateValue: string) => {
    const selectedDate = new Date(dateValue);
    const formattedDate = format(selectedDate, 'yyyy-MM-dd\'T\'12:00');
    return formattedDate
}

export const getDurationFromDates = (dateFrom: string, dateTill: string) => {
    const selectedDateFrom = new Date(dateFrom);
    const selectedDateTo = new Date(dateTill);
    const duration = selectedDateTo.getTime() - selectedDateFrom.getTime();
    const durationInMinutes = Math.floor(duration / 1000 / 60);
    
    return durationInMinutes
}

export const convertMinutesToHHmmForInput = (minutes: number) => {
    if (isNaN(minutes)) return '';
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
  };

export const zonedEndOfMonth = (date: Date) => {
    return DateTime
        .fromMillis(new Date(date).getTime())
        .setZone('UTC')
        .endOf('month')
};

export const zonedEndOfDay = (date: Date) => {
    return DateTime
        .fromMillis(new Date(date).getTime())
        .setZone('UTC')
        .endOf('day')
};
