import { Flex, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { t } from 'i18next';
import {
	MdConstruction
  } from 'react-icons/md';

function UnderConstruction() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brandColor = useColorModeValue('brand.500', 'brand.400');

	return (
		<Flex direction='column' align='center' pt={{ sm: '125px', lg: '75px' }}>
			<Icon as={MdConstruction} h='200px' w='400px' maxW='90%' mt={{ base: '4vh', lg: '20vh' }} mb='10px' />
			<Text
				color={textColor}
				fontSize={{ base: '30px', lg: '36px' }}
				fontWeight='700'
				mb='30px'
				textAlign={{ base: 'center', md: 'start' }}>
				{ t('underConstruction', { ns: ['global'] }) }
			</Text>
			<Flex align='center' direction={{ base: 'column', md: 'row' }}>
				<Text color={textColor} fontWeight='500' fontSize={{ base: 'md', md: 'lg' }} me='4px'>
					{ t('maybeBackHome', { ns: ['global'] }) }
				</Text>
				<Link color={brandColor} fontSize={{ base: 'md', md: 'lg' }} fontWeight='500' href='/'>
					{ t('returnHome', { ns: ['global'] }) }
				</Link>
			</Flex>
		</Flex>
	);
}

export default UnderConstruction;
