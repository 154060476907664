import { RepeatIcon } from '@chakra-ui/icons';
import {
  FormLabel,
  Select,
  SimpleGrid,
  Flex,
  Text,
  Button,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import useFormErrorsStore from 'contexts/formErrorsStore';
import { CostCenter, useCostCenterStore } from 'contexts/globalStoreCostCenters';
import { useProjectStore } from 'contexts/globalStoreProjects';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  costCenterBillable: yup
    .number()
    .typeError('costCenterBillableRequired')
    .min(1, 'costCenterBillableRequired')
    .required('costCenterBillableRequired')
    .nonNullable(),
});

export const ProjectCostCenters = ({ viewOnly }: { viewOnly: boolean }) => {
  const singleProjectData = useProjectStore((state: any) => state.project);
  const setTrue = useProjectStore((state) => state.setToTrue);

  const setProjectCreateObj = useProjectStore((state) => state.setProjectData);

  const [isRefreshButtonLoading, setIsRefreshButtonLoading] = useState(false);

  const { costCenters, fetchCostCenters } = useCostCenterStore(
    (state: any) => ({ costCenters: state.costCenters, fetchCostCenters: state.fetchCostCenters })
  );

  const costCentersBillable = useMemo(() => {
    return costCenters
      .filter((costCenter: CostCenter) => costCenter.isBillable)
  }, [costCenters]);

  const costCentersNonBillable = useMemo(() => {
    return costCenters
      .filter((costCenter: CostCenter) => !costCenter.isBillable)
  }, [costCenters]);

  const handleChange = (field: any, value: any) => {
    setProjectCreateObj({
      [field]: value,
    });
  };
  const validateFormData = useCallback(async (formData: any) => {
    try {
      await validationSchema.validate(formData);
      setTrue('costCenterValidation', true);
      return true
    } catch (error) {
      setTrue('costCenterValidation', false);
      return false
    }
  }, [setTrue]);

  const [eachFieldStateValidation, setEachFieldStateValidation] = useState({
    vatRate: { valid: true, error: '' },
    language: { valid: true, error: '' },
    currency: { valid: true, error: '' },
    termsOfPayment: { valid: true, error: '' },
    rounding: { valid: true, error: '' },
    roundingType: { valid: true, error: '' },
    costCenterBillable: { valid: true, error: '' },
  });

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteError = useFormErrorsStore((state) => state.deleteError);

  const billableCenterError = useMemo(() =>
    eachFieldStateValidation?.['costCenterBillable']?.error || 'costCenterBillableRequired',
    [eachFieldStateValidation]);

  const validationError = useMemo(() => billableCenterError
    ? `${t('costCenterError', { ns: ['errors'] })}: ${t(t('costCenterBillableRequired', { ns: ['hints'] }), { ns: ['hints'] })}`
    : null, [billableCenterError]);

  useEffect(() => {
    validateFormData({ costCenterBillable: singleProjectData.costCenterBillable })
      .then((result) => {
        if (!result) {
          setErrors([validationError]);
        } else {
          deleteError(validationError);
        }
      });
  }, [deleteError, eachFieldStateValidation, setErrors, singleProjectData, validateFormData, validationError]);

  const validateSingleField = (path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchema, path);

    field
      .validate(input)
      .then(() =>
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: true, error: '' },
        }),
      ) // if valid set true
      .catch((e: any) => {
        // console.error(e);
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: false, error: e.errors.join(', ') },
        });
      }); // if invalid set false
  };

  return (
    <>
      <SimpleGrid gap="20px" mb="5">
        <Flex basis={'100%'} alignItems={'end'} direction={{ base: 'column', md: 'row' }}>
          <Flex basis={'30%'} direction="column">
            <FormLabel
              ms="10px"
              htmlFor="costCenterBillable"
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              {t('costCenterBillable', { ns: ['labels'] }) + '*'}
            </FormLabel>
            <Select
              value={singleProjectData.costCenterBillable || ''}
              placeholder={t('selectCostCenter', { ns: ['labels'] })}
              onChange={(event) => {
                handleChange('costCenterBillable', Number(event.target.value))
                validateSingleField('costCenterBillable', Number(event.target.value));
              }}
              disabled={viewOnly}
            >
              {costCentersBillable.map(
                (center: any) => (
                  <option key={center.id} value={center.id}>
                    {center.name}
                  </option>
                ),
              )}
            </Select>
            {billableCenterError && showErrors ?
              (<Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
                {eachFieldStateValidation?.['costCenterBillable']?.error
                  ? t(eachFieldStateValidation?.['costCenterBillable']?.error, { ns: ['hints'] })
                  : null}
                &nbsp;
              </Text>) : null}
          </Flex>
          <Button
            alignSelf={billableCenterError && showErrors ? 'center' : 'end'}
            isDisabled={!singleProjectData.costCenterBillable}
            onClick={() => window.open(`/cost-centers/edit/${singleProjectData?.costCenterBillable}`, '_blank')}
            width={'100px'}
            ml={2}
          >
            {t('open', { ns: ['labels'] })}
          </Button>
          <IconButton
            alignSelf={billableCenterError && showErrors ? 'center' : 'end'}
            aria-label="Reload"
            variant="brand"
            ml={2}
            icon={isRefreshButtonLoading ? (<Spinner />) : (<RepeatIcon />)}
            onClick={async () => {
              setIsRefreshButtonLoading(true);
              await fetchCostCenters();
              setIsRefreshButtonLoading(false);
            }}
            isDisabled={viewOnly}
          />
        </Flex>
      </SimpleGrid >
      <SimpleGrid gap="20px" mb="5">
        <Flex basis={'100%'} alignItems={'end'} direction={{ base: 'column', md: 'row' }}>
          <Flex basis={'30%'} direction="column">
            <FormLabel
              ms="10px"
              htmlFor="costCenterNonBillable"
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              {t('costCenterNonBillable', { ns: ['labels'] })}
            </FormLabel>
            <Select
              value={singleProjectData.costCenterNonBillable || ''}
              placeholder={t('no', { ns: ['labels'] })}
              onChange={(event) => handleChange('costCenterNonBillable', Number(event.target.value))}
              disabled={viewOnly}
            >
              {costCentersNonBillable.map(
                (center: any) => (
                  <option key={center.id} value={center.id}>
                    {center.name}
                  </option>
                ),
              )}
            </Select>
          </Flex>

          <Button
            alignSelf={'end'}
            isDisabled={!singleProjectData?.costCenterNonBillable}
            onClick={() => window.open(`/cost-centers/edit/${singleProjectData?.costCenterNonBillable}`, '_blank')}
            width={'100px'}
            ml={2}
          >
            {t('open', { ns: ['labels'] })}
          </Button>
          <IconButton
            aria-label="Reload"
            variant="brand"
            icon={isRefreshButtonLoading ? (<Spinner />) : (<RepeatIcon />)}
            ml={2}
            onClick={async () => {
              setIsRefreshButtonLoading(true);
              await fetchCostCenters();
              setIsRefreshButtonLoading(false);
            }}
            isDisabled={viewOnly}
          />
        </Flex>
      </SimpleGrid>
    </>
  );
};
