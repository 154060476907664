import { Api } from 'utils/postos-api';
import { create } from 'zustand';
import { produce } from 'immer';
import { PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { PaginationMeta, paginationMetaInitialState } from './globalStore';

const api = new Api();

interface ReportsStore {
  reports: Report[];
  reportsMeta: PaginationMeta;
  reportsByResource: ReportByResource[];
  reportsByResourceMeta: PaginationMeta;
  reportsByMonth: ReportByMonth[];
  reportsByMonthMeta: PaginationMeta;
  reportsByYear: ReportByYear[];
  reportsByYearMeta: PaginationMeta;
  projectReports: ProjectReport[];
  projectReportsMeta: PaginationMeta;
  fetchMonthlyReports: (
    from: any,
    till: any,
    active?: boolean,
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<void>;
  fetchYearlyReports: (
    from: any,
    till: any,
    id: number,
    active?: boolean,
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<void>;
  fetchReports: (
    from: any,
    till: any,
    projectId: number | null,
    active?: boolean,
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<void>;
  fetchProjectReports: (
    activeOnly?: boolean,
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<void>;  
  resetReportList: () => void;
  resetMonthlyReportList: () => void;
  resetYearlyReportList: () => void;
  isInitDone: boolean;
  set: (fn: any) => void;
}

interface Report {
  id: number;
  role: string;
  status: string;
  taskId: number;
  userId: number;
  duration: number;
  isPaid: boolean;
  dateFrom: string;
  dateTill: string;
  projectId: number;
  clientCost: number;
  clientRate: number;
  description: string;
  isInvoiced: boolean;
  resourceCost: number;
  resourceRate: number;
  hoursPerMonth: number;
  roundedDuration: number;
  isEntryByTimeFrame: boolean;
}

interface ReportByResource {
  userId: number;
  totalDuration: number;
  totalClientCost: number;
  totalResourceCost: number;
}

interface ReportByMonth {
  userId: number;
  dateFrom: string;
  totalClientCost: number;
  totalDuration: number;
  totalResourceCost: number;
}

interface ReportByYear {
  month: number;
  projectId: number;
  totalClientCost: number;
  totalDuration: number;
  totalResourceCost: number;
  userId: number;
  year: number;
}

export interface ProjectReport {
  id: number;
  title: string;
  reference: string;
  totalProjectAmount: number;
  totalProjectInvoicedAmount: number;
}

export interface ProjectReportBE {
  id: number;
  title: string;
  reference: string;
  amount: number;
  rounded_amount: number;
  invoiced_amount: number;
}

export const useReportsStore = create<ReportsStore>((set) => ({
  set: (fn: any) => set(produce(fn)),
  isInitDone: false,
  reports: [],
  reportsMeta: paginationMetaInitialState,
  reportsByResource: [],
  reportsByResourceMeta: paginationMetaInitialState,
  reportsByMonth: [],
  reportsByMonthMeta: paginationMetaInitialState,
  reportsByYear: [],
  reportsByYearMeta: paginationMetaInitialState,
  projectReports: [],
  projectReportsMeta: paginationMetaInitialState,
  resetYearlyReportList: () => {
    set({
      reportsByYear: [],
      reportsByYearMeta: paginationMetaInitialState
    });
  },
  fetchYearlyReports: async (
    from: any,
    till: any,
    id: number,
    active?: boolean,
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc'
  ) => {
    try {
      const params: any = {
        dateFrom: new Date(from).toISOString(),
        dateTill: new Date(till).toISOString(),
        userId: id,
        page,
        size,
        orderField,
        orderDirection,
      };
      if (active) params.active = active;
      
      const response: any = await api.postos.postosReportsGetYearly(params);

      const localReportByYear = response.data?.data?.body?.reportYearly || [];
      const mappedReportsByYear: ReportByYear[] = localReportByYear.map(
        (reports: any) => ({
          month: reports.month,
          year: reports.year,
          userId: reports.user_id,
          projectId: reports.project_id,
          totalClientCost: reports.total_client_cost,
          totalDuration: reports.total_duration,
          totalResourceCost: reports.total_resource_cost,
        }),
      );

      set(
        produce((state) => {
          state.reportsByYear = mappedReportsByYear;
          state.reportsByYearMeta = response.data?.data?.body?.reportYearly?.meta 
          || { totalItems: mappedReportsByYear.length, totalPages: 1 };
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  resetMonthlyReportList: () => {
    set({
      reportsByMonth: [],
      reportsByMonthMeta: paginationMetaInitialState
    });
  },
  fetchMonthlyReports: async (
    from: any,
    till: any,
    active: boolean,
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc'
  ) => {
    try {
      const params: any = {
        dateFrom: new Date(from).toISOString(),
        dateTill: new Date(till).toISOString(),
        page,
        size,
        orderField,
        orderDirection,
      };
      if (active) params.active = active;

      const response: any = await api.postos.postosReportsGetMonthly(params);
      const localReportByMonth = response.data?.data?.body?.reportMonthly?.items || [];
      const mappedReportsByMonth: ReportByMonth[] = localReportByMonth.map(
        (reports: any) => ({
          userId: reports.user_id,
          dateFrom: reports.date_from,
          totalClientCost: reports.total_client_cost,
          totalDuration: reports.total_duration,
          totalResourceCost: reports.total_resource_cost,
        }),
      );

      set(
        produce((state) => {
          state.reportsByMonth = mappedReportsByMonth;
          state.reportsByMonthMeta = response.data?.data?.body?.reportMonthly?.meta 
          || { totalItems: mappedReportsByMonth.length, totalPages: 1 };
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  resetReportList: () => {
    set({
      reports: [],
      reportsByResource: [],
      reportsMeta: paginationMetaInitialState,
      reportsByResourceMeta: paginationMetaInitialState
    });
  },
  fetchReports: async (
    from: any,
    till: any,
    projectId: number | null,
    active?: boolean,
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc'
  ) => {
    try {
      const params: any = {
        dateFrom: new Date(from).toISOString(),
        dateTill: new Date(till).toISOString(),
        page,
        size,
        orderField,
        orderDirection,
      };

      if (projectId) params.projectId = projectId;
      if (active) params.active = active;

      const response: any = await api.postos.postosReportsGetByTimeframe(params);

      const localReport = response.data?.data?.body?.report?.items || [];
      const localReportByResource =
        response.data?.data?.body.reportByResource?.items || [];
      const mappedReports: Report[] = localReport.map((reports: any) => ({
        id: reports.id,
        status: reports.status,
        taskId: reports.task_id,
        userId: reports.user_id,
        duration: reports.duration,
        dateFrom: reports.date_from,
        dateTill: reports.date_till,
        projectId: reports.project_id,
        clientCost: reports.client_cost,
        clientRate: reports.client_rate,
        description: reports.description,
        resourceCost: reports.resource_cost,
        resourceRate: reports.resource_rate,
        roundedDuration: reports.rounded_duration,
        isEntryByTimeFrame: reports.is_entry_by_time_frame,
      }));

      const mappedReportsByResource: ReportByResource[] =
        localReportByResource.map((reports: any) => ({
          userId: reports.user_id,
          totalDuration: reports.total_duration,
          totalClientCost: reports.total_client_cost,
          totalResourceCost: reports.total_resource_cost,
        }));
      set(
        produce((state) => {
          state.reports = mappedReports;
          state.reportsMeta = response.data?.data?.body?.report?.meta;
        }),
      );

      set(
        produce((state) => {
          state.reportsByResource = mappedReportsByResource;
          state.reportsByResourceMeta = response.data?.data?.body?.reportByResource?.meta 
          || { totalItems: mappedReportsByResource.length, totalPages: 1 };
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  fetchProjectReports: async (
    activeOnly: boolean = false,
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc'
  ) => {
    try {
      const params: any = {
        active: activeOnly,
        page,
        size,
        orderField,
        orderDirection,
      };
      
      const res: any = await api.postos.postosReportsGetByProject(params);

      const localReport: ProjectReportBE[] = res.data?.data?.body?.report || [];
      const mappedProjectReports: ProjectReport[] = localReport.map((report: ProjectReportBE) => ({
        id: report.id,
        title: report.title,
        reference: report.reference,
        totalProjectAmount: report.amount,
        totalProjectRoundedAmount: report.rounded_amount,
        totalProjectInvoicedAmount: report.invoiced_amount
      }));

      set(
        produce((state) => {
          state.projectReports = mappedProjectReports;
          state.projectReportsMeta = res.data?.data?.body?.meta;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  resetProjectsReportList: () => {
    set({
      projectReports: [],
      projectReportsMeta: paginationMetaInitialState
    });
  },
}));
