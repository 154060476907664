import {
  Box,
  Flex,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import LanguageSelector from 'components/languageSelector';
import { SidebarContext } from 'contexts/SidebarContext';
import { useMemo } from 'react';

export default function AuthNavbar(props: {
  logo?: JSX.Element | string;
  logoText?: string;
  secondary?: boolean;
  sidebarWidth?: any;
}) {
  const { sidebarWidth } = props;
  let logoColor = useColorModeValue('white', 'white');
  let mainText = '#fff';
  let navbarBg = 'none';
  let navbarShadow = 'initial';
  let navbarPosition = 'absolute' as 'absolute';

  const Brand = useMemo(() => (
    <Link
      href={`${process.env.PUBLIC_URL}/#/`}
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Stack
        direction="row"
        spacing="12px"
        alignItems="center"
        justify="center"
      >
        <Text
          fontSize={'30px'}
          fontWeight="800"
          color={logoColor}
          pr={"20px"}
        >
          Faktura
        </Text>
      </Stack>
    </Link>
  ), [logoColor, mainText]);

  return (
    <SidebarContext.Provider value={{ sidebarWidth }}>
      <Flex
        position={navbarPosition}
        top="16px"
        left="50%"
        transform="translate(-50%, 0px)"
        background={navbarBg}
        boxShadow={navbarShadow}
        borderRadius="15px"
        px="16px"
        py="22px"
        mx="auto"
        width="1044px"
        maxW="90%"
        alignItems="center"
        zIndex="3"
      >
        <Flex w="100%" justifyContent={{ sm: 'start', lg: 'space-between' }}>
          {Brand}
          <Box
            ms={{ base: 'auto', lg: '0px' }}
            display={{ base: 'flex', lg: 'none' }}
            justifyContent="center"
            alignItems="center"
          >
          </Box>
          <LanguageSelector />
        </Flex>
      </Flex>
    </SidebarContext.Provider>
  );
}
