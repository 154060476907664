import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import useUserDataRolesStore from 'contexts/authStore';
import { t } from 'i18next';
import {
	MdWavingHand
} from 'react-icons/md';

function UserHome() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const { userData } = useUserDataRolesStore(
		(state: any) => ({ userData: state.userData }),
	);

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Text
				color={textColor}
				fontSize={{ base: '20px', lg: '30px' }}
				fontWeight='700'
				mb='30px'
			>
				<Icon as={MdWavingHand} h='50px' w='80px' maxW='90%' mt='10px' />

				{t('hello', { ns: ['labels'] })}, {userData?.name || t('user', { ns: ['labels'] })}!
			</Text>
			<Text
				color={textColor}
				fontSize={{ base: '18px', lg: '20px' }}
				mb='30px'
			>
				{t('ID', { ns: ['labels'] })}: {userData?.id}
			</Text>
			<Text
				color={textColor}
				fontSize={{ base: '18px', lg: '20px' }}
				mb='30px'
			>
				{t('email', { ns: ['labels'] })}: {userData?.email || t('no', { ns: ['labels'] })}
			</Text>
		</Flex>
	);
}

export default UserHome;
