import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Stack,
  FormControl,
  Text,
  Flex,
  IconButton,
  Container,
  SimpleGrid,
  InputGroup,
  Input,
  Card,
  useColorModeValue,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Address, useCompanyStore } from 'contexts/globalStoreCompanies';
import { t } from 'i18next';
import * as yup from 'yup';
import useFormErrorsStore from 'contexts/formErrorsStore';

const addressValidationObj = {
  address_line_1: yup
    .string()
    .required('addressRequired')
    .min(3, 'addressMinLength3'),
  zip_code: yup
    .string()
    .required('zipCodeRequired')
    .min(3, 'zipCodeMinLength3'),
  city: yup
    .string()
    .required('cityRequired')
    .min(2, 'cityMinLength2'),
  country: yup
    .string()
    .required('countryRequired')
    .min(2, 'countryMinLength2'),
  is_billing_address: yup.boolean().required(),
  isNew: yup.boolean().optional(),
}

const validationSchema = yup.object().shape({
  addresses: yup
    .array()
    .of(
      yup.object().shape(addressValidationObj),
    )
    .min(1)
    .test(
      'oneBilling',
      null,
      (addresses) => {
        const billingAddresses = addresses.filter((address) => address.is_billing_address);

        if (billingAddresses.length === 1) {
          return true;
        }

        return new yup.ValidationError(
          'mustBeOneBilling',
          null,
          'is_billing_address'
        );
      }
    )
});

const validationSchemaSingleAddress = yup.object().shape(addressValidationObj);

export const CompanyAddresses = ({ viewOnly }: { viewOnly: boolean }) => {
  const toast = useToast();

  const companyId = useCompanyStore((state) => state.company.id);
  const companyAddresses = useCompanyStore((state) => state.addresses);
  const companyPeople = useCompanyStore((state) => state.people);

  const addNewAddressToCompany = useCompanyStore(
    (state) => state.addAddressToCompany,
  );
  const deleteAddressFromCompanyList = useCompanyStore(
    (state) => state.deleteAddressFromCompany,
  );

  const handleAddressDataChange = useCompanyStore(
    (state) => state.updateAddressAtIndex,
  );

  const [eachFieldCompanyAddressValidation, setEachFieldCompanyAddressValidation] =
    useState([]);

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteError = useFormErrorsStore((state) => state.deleteError);
  const deleteErrorsContainingString = useFormErrorsStore((state) => state.deleteErrorsContainingString);

  const handleAdd = useCallback(() => {
    deleteErrorsContainingString(t('addressError', { ns: ['errors'] }));
    addNewAddressToCompany({
      id: null,
      address_line_1: '',
      address_line_2: '',
      zip_code: '',
      city: '',
      country: '',
      company_id: companyId || null,
      is_billing_address: !companyAddresses?.length,
    });
  }, [addNewAddressToCompany, companyAddresses?.length, companyId, deleteErrorsContainingString]);

  const handleDeleteAddress = (indexToDelete: number) => {
    const peopleWithAddressToDelete = companyPeople.filter((person) => {
      return person.address_id === companyAddresses[indexToDelete].id;
    })

    if (peopleWithAddressToDelete?.length) {
      toast({
        title: t('error', { ns: ['labels'] }),
        description: t('cantDeleteAddress', { ns: ['labels'] }),
      });
      return;
    }

    deleteAddressFromCompanyList(indexToDelete);
  };

  const handleAddressData = useCallback((index: number, field: any, value: any) => {
    handleAddressDataChange(index, field, value);
  }, [handleAddressDataChange]);

  const setTrue = useCompanyStore((state) => state.setToTrue);

  const validateFormData = useCallback(async (formData: any) => {
    try {
      await validationSchema.validate(formData);
      setTrue('addressValidation', true);
    } catch (error) {
      setTrue('addressValidation', false);
    }
  }, [setTrue]);

  useEffect(
    () => {
      companyAddresses?.length > 0 &&
        validateFormData({ addresses: companyAddresses });
    },
    [validateFormData, companyAddresses],
  );

  const validateSingleField = async (path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchemaSingleAddress, path);

    try {
      await field.validate(input);
      return { valid: true, error: '' };
    } catch (e: any) {
      return { valid: false, error: e.errors.join(', ') };
    }
  };

  useEffect(() => {
    const mappedResult = companyAddresses?.length ? companyAddresses.map(async (address: Address) => {
      return {
        address_line_1: await validateSingleField('address_line_1', address.address_line_1),
        zip_code: await validateSingleField('zip_code', address.zip_code),
        city: await validateSingleField('city', address.city),
        country: await validateSingleField('country', address.country),
        is_billing_address: await validateSingleField('is_billing_address', address.is_billing_address),
      }
    }) : [];

    Promise.all(mappedResult).then((res) =>
      setEachFieldCompanyAddressValidation(res),
    );
  }, [companyAddresses]);

  useEffect(() => {
    const addressError = t('addressError', { ns: ['errors'] });
    const addressRequiredError = `${addressError}: ${t('addressRequired', { ns: ['hints'] })}`;

    //TODO DRY company people, project permissions
    if (companyAddresses?.length) {
      deleteError(addressRequiredError);
      const addressesWithErrors = eachFieldCompanyAddressValidation.filter(
        (address: Address) => Object.values(address).some(
          (field: { valid: boolean, error: string }) => !field.valid
        ),
      );

      if (addressesWithErrors.length > 0) {
        const errorsFields = addressesWithErrors.map(
          (address: Address) => Object.values(address)
        )

        const errors = errorsFields
          .filter((fieldsArr: any) => fieldsArr.some((field: any) => !field.valid))
          .map((fieldsArr: any) => fieldsArr.filter((field: any) => !field.valid))
          .flat();

        const errorsForShow: string[] = errors.map(
          (field: any) => `${addressError}: ${t(field.error, { ns: ['hints'] })}`
        )

        setErrors(errorsForShow);
      } else {
        deleteErrorsContainingString(addressError);
      }
    } else {
      setErrors([addressRequiredError]);
    }
  }, [deleteError, deleteErrorsContainingString, eachFieldCompanyAddressValidation, companyAddresses?.length, setErrors]);

  const changeBillingAddress = useCallback((val: boolean, index: number) => {
    const billingAddressesCount = companyAddresses.filter((address) => address.is_billing_address).length;
    if (billingAddressesCount === 1) {
      toast({
        title: t('error', { ns: ['labels'] }),
        description: t('mustBeOneBilling', { ns: ['hints'] }),
      });
      setErrors([t('mustBeOneBilling', { ns: ['hints'] })]);
    } else {
      deleteErrorsContainingString(t('mustBeOneBilling', { ns: ['hints'] }));
    }
    handleAddressData(
      index,
      'is_billing_address',
      val,
    )
  }, [companyAddresses, deleteErrorsContainingString, handleAddressData, setErrors, toast]);

  const textColor = useColorModeValue('navy.700', 'white');

  return (
    <>
      <Flex my={4} pr={4}>
        {!viewOnly ? <Button variant="outline" onClick={handleAdd}>
          {t('addAddress', { ns: ['labels'] })}
          <AddIcon ml={2} />
        </Button> : null}
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="4">
        {companyAddresses?.length ? companyAddresses.map((address: Address, index: number) => (
          <Container key={`address-${index}`}>
            <Card p={4}>
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px">
                <InputGroup size="md">
                  <Input
                    value={address.address_line_1 || ''}
                    color={textColor}
                    placeholder={`${t('addressLine', { ns: ['labels'] })} 1`}
                    onChange={(event) =>
                      handleAddressData(index, 'address_line_1', event.target.value)
                    }
                    disabled={viewOnly}
                  />
                </InputGroup>
                <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
                  {showErrors && eachFieldCompanyAddressValidation?.[index]?.['address_line_1']?.error
                    ? t(eachFieldCompanyAddressValidation[index]['address_line_1'].error, { ns: ['hints'] })
                    : null}
                  &nbsp;
                </Text>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px">
                <InputGroup size="md">
                  <Input
                    value={address.address_line_2 || ''}
                    color={textColor}
                    placeholder={`${t('addressLine', { ns: ['labels'] })} 2`}
                    onChange={(event) =>
                      handleAddressData(index, 'address_line_2', event.target.value)
                    }
                    disabled={viewOnly}
                  />
                </InputGroup>
                <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
                  {showErrors && eachFieldCompanyAddressValidation?.[index]?.['address_line_2']?.error
                    ? t(eachFieldCompanyAddressValidation[index]['address_line_2'].error, { ns: ['hints'] })
                    : null}
                  &nbsp;
                </Text>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px">
                <InputGroup size="md">
                  <Input
                    value={address.zip_code || ''}
                    color={textColor}
                    placeholder={'Zip'}
                    onChange={(event) =>
                      handleAddressData(index, 'zip_code', event.target.value)
                    }
                    disabled={viewOnly}
                  />
                </InputGroup>
                <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
                  {showErrors && eachFieldCompanyAddressValidation?.[index]?.['zip_code']?.error
                    ? t(eachFieldCompanyAddressValidation[index]['zip_code'].error, { ns: ['hints'] })
                    : null}
                  &nbsp;
                </Text>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px">
                <InputGroup size="md">
                  <Input
                    value={address.city || ''}
                    color={textColor}
                    placeholder={t('city', { ns: ['labels'] })}
                    onChange={(event) =>
                      handleAddressData(index, 'city', event.target.value)
                    }
                    disabled={viewOnly}
                  />
                </InputGroup>
                <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
                  {showErrors && eachFieldCompanyAddressValidation?.[index]?.['city']?.error
                    ? t(eachFieldCompanyAddressValidation[index]['city'].error, { ns: ['hints'] })
                    : null}
                  &nbsp;
                </Text>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px">
                <InputGroup size="md">
                  <Input
                    value={address.country || ''}
                    color={textColor}
                    placeholder={t('country', { ns: ['labels'] })}
                    onChange={(event) =>
                      handleAddressData(index, 'country', event.target.value)
                    }
                    disabled={viewOnly}
                  />
                </InputGroup>
                <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
                  {showErrors && eachFieldCompanyAddressValidation?.[index]?.['country']?.error
                    ? t(eachFieldCompanyAddressValidation[index]['country'].error, { ns: ['hints'] })
                    : null}
                  &nbsp;
                </Text>
              </SimpleGrid>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                alignItems="center"
              >
                <InputGroup>
                  <FormControl>
                    <Stack direction="column">
                      <Checkbox
                        isChecked={address.is_billing_address || false}
                        onChange={(event) =>
                          changeBillingAddress(event.target.checked, index)
                        }
                        disabled={viewOnly}
                      >
                        {t('isBillingAddress', { ns: ['labels'] })}
                      </Checkbox>
                    </Stack>
                  </FormControl>
                </InputGroup>
                <IconButton
                  colorScheme="red"
                  aria-label="Delete Select"
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteAddress(index)}
                  isDisabled={viewOnly}
                />
              </Stack>
            </Card>
          </Container>
        )) : t('companyAddressRequired', { ns: ['labels'] })}
      </SimpleGrid>
    </>
  );
};

export default CompanyAddresses;
