export const headerEn= {
  order: 'Order',
  restaurant: 'Tables',
  tables: 'Tables',
  orders: 'Orders',
  invoices: 'Documents',
  wallet: 'Wallet',
  reports: 'Reports',
  contacts: 'Contacts',
  settings: 'Settings',
  login: 'Login',
  logout: 'Logout',
  profile: 'Profile',
  chat: 'Chat with Support',
  postalCode: 'Postal code',
  totalAmountForPayments: 'Total amount for payments',
  totalAmountForUnpaid: 'Total amount for unpaid',
  totalTipAmount: 'Total tip amount',
  tpi: 'TPI',
  method: 'Method',
  paymentType: 'Payment Type',
  payment: 'Payment',
  paymentMethod: 'Payment method',
  paymentReport: 'Payment report',
  quantity: 'Quantity',
  price: 'Price',
  time: 'Time',
  result: 'Result',
  totalRefoundsCount: 'Total Refounds Count',
  vatRate: 'VAT %',
  totalAmount: 'Total Amount',
  totalSalesAmount: 'Total Sales Amount',
  totalSalesCount: 'Total Sales Count',
  totalRefundsAmount: 'Total Refunds Amount',
  currency: 'Currency',
  amount: 'Amount',
  orderCount: 'Order count',
  callerId: 'Caller id',
  phoneNo: 'Phone no',
  on: 'On',
  vat: 'VAT',
  state: 'State',
  requestID: 'Request ID',
  printer: 'Printer',
  template: 'Template',
  created: 'Created',
  errorCounter: 'Error counter',
  noOfPrints: 'No of Prints',
  amountWOTax: 'Amount w/o Tax',
  totalVAT: 'Total VAT',
  type: 'Type',
  taxHead: 'Tax Head',
  massOrder: 'Mass Order',
  date: 'Date',
  count: 'Count',
  extendedContacts: 'Extended contacts',
  showCardName: 'Galaxy card: show name field',
  unpaidItems: 'Unpaid items',
  selectedForPayment: 'Selected for payment',
  documents: 'Documents',
  categoryTable: 'Category - Table',
  attendees: 'Attendees',
  guest: 'Guest',
  guestDetails: 'Guest details',
  notes: 'Notes',
  reservationTime: 'Reservation time',
  reservationStart: 'Reservation start',
  reservationEnd: '/ end',
  status: 'Status',
  actions: 'Actions',
  inventory: 'Inventory',
  allItems: 'All items',
  selectedForMove: 'Selected for move',
  original: 'Original',
  card: 'Card',
  id: 'ID',
  types: 'Types',
  credentialDetails: 'Credential Details',
  values: 'Values',
  triggersCron: 'Triggers - Cron',
  triggerCron: 'Trigger - Cron Details',
  triggerRest: 'Trigger - Rest Details',
  processDetails: 'Process Details',
  stepsDetails: 'Steps Details',
  cache: 'Cache',
  input: 'Input',
  output: 'Output',
  key: 'Key',
  value: 'Value',
  act: 'Act',
  email: 'Email',
  roleDetails: 'Role Details',
  userDetails: 'User Details',
  translation: 'Translation',
  settingDetails: 'Setting Details',
  sureDeleteStep: 'Are you sure you want to delete this step?',
  sureDeleteStepChildren: 'Are you sure you want to delete this step and it\'s children?',
  sureDeleteTranslation: 'Are you sure you want to delete this translation?',
  templateHelp: 'Help how to include field or translation',
  textTemplateHelp: 'Plain text messages (example):',
  htmlTemplateHelp: 'Html messages (example):',
  addSetting: 'Add Setting',
  editSetting: 'Edit Setting',
  urlHelp: 'Help how different types of URLs should be formatted',
  postgreSQL: 'PostgreSQL',
  mysql: 'MySql',
  oracle: 'Oracle',
  sqlServer: 'Sql Server',
  modules: 'Modules',
  moduleDetails: 'Module Details',
  revision: 'Revision',
  restHelp: 'Process variables help',
  restHelpVars: 'Within the process, you can use the following variables to access the data of the request',
  restHelpResponseVar: 'If you want to return data, please set the following variable accordingly',
  restHelpSpecificCode: 'If order to set a specific http code, use',
  validationDetails: 'Validation Details',
  frontend: 'Frontend',
  instance: 'Instance',
  process: 'Process',
  sureDeleteDesc: 'Are you sure you want to delete this description?',
  mongoDb: 'MongoDb',
  apiKey: 'Api Key',
  gdpr: 'GDPR',
  gdprCronTrigger: 'GDPR Cron Trigger',
  gdprRestTrigger: 'GDPR Rest Trigger',
  gdprModules: 'GDPR Modules',
  gdprProcessCredentials: 'GDPR Process Credentials',
  gdprProcesses: 'GDPR Processes',
  details: 'Details',
  exception: 'Exception',
  event: 'Event',
  exceptionMessage: 'Message',
  exceptionStackTrace: 'Stack Trace',
  plugin: 'Plugin Details',
  addStep: 'Add Step',
  cookie_1: 'This website uses cookies to personalise your experience. Details are described in our',
  cookie_2: 'By using our website and agreeing to this policy, you consent to our use of cookies',
  cookiePrivacy: 'Privacy and Cookies Policy',
  tearmsConditions: 'Terms & Conditions',
  accept: 'I Accept',
  register: 'Register',
  history: 'History',
  notActive: 'Dear customer, this webshop is not currently active.',
  failLogOut: 'Failed to log out',
  logOut: 'You are now logged out',
  iAgree1: 'I hereby agree to the',
  iAgree2: '',
  and: 'and',
  variable: 'Variable',
  adminOptions: 'Admin Options',
  timeSpan: 'Timespan',
  eventsByStates: 'Event states',
  eventDurations: 'Event durations in min',
  eventInstances: 'Event per instance ID',
  eventStateTotals: 'Event state totals',
  addConnectedResources: 'Add connected resources?',
  lastEdited: 'Last edited',
  createProject: 'Create Project',
  updateProject: 'Update Project',
  updateBooking: 'Update Booking',
  createBooking: 'Create Booking',
  updateCompany: 'Update Company',
  createCompany: 'Create Company',
}
