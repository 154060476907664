import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableProjects from './components/SearchTableProjects';

export default function Projects() {
	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<SearchTableProjects/>
			</Card>
		</Flex>
	);
}
