import { Fragment, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Checkbox, Select, SimpleGrid, Spacer } from '@chakra-ui/react';
import { Progress } from '@chakra-ui/react';
import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
} from '@chakra-ui/react';
import {
  Table as TableType,
  flexRender,
} from '@tanstack/react-table';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { PAGE_SIZE_OPTIONS } from 'variables/pagination';

type Pagination = { pageIndex: number, pageSize: number }

export default function FakturaTable<T>(
  {
    table,
    additionalBottomRows,
    pagination,
    setPagination,
    loadingState
  }:
    {
      table: TableType<T>,
      additionalBottomRows?: React.ReactNode,
      pagination: Pagination,
      setPagination: (pagination: Pagination) => void,
      loadingState: boolean
    }
) {
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const gray200white = useColorModeValue('gray.200', 'white')

  const pageSizes = useMemo(() => PAGE_SIZE_OPTIONS, []);

  const { pageIndex, pageSize } = pagination

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1199);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (<>
    {loadingState && <Progress size="xs" isIndeterminate width={'100%'} />}
    {isSmallScreen ? (
      <Table variant="unstyled" color="gray.500" mb="24px">
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} borderBottom="2px solid black">
              {row.getVisibleCells().map((cell) => (
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor={borderColor}
                  display="flex"
                  alignItems="center"
                  key={cell.id}
                  color={textColor}
                  fontWeight="500"
                >
                  <Flex justifyContent={cell.column.id === 'action' ? 'end' : 'space-between'}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext(),
                    )}
                  </Flex>
                  <Spacer />
                  <SimpleGrid
                    columns={{ base: 1, md: 1 }}
                    gap="20px"
                    mt="5px"
                  >
                    <Stack direction="column" gap="20px">
                      <span style={{ flex: 1 }}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </span>
                    </Stack>
                  </SimpleGrid>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    ) : (
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            {table.getHeaderGroups().map((headerGroup) => (
              <Fragment key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    pe="10px"
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null :
                      header.column.columnDef.id === 'select' ? (
                        <Checkbox
                          isChecked={table.getIsAllRowsSelected()}
                          onChange={() => {
                            table.toggleAllRowsSelected();
                          }}
                          colorScheme="brandScheme"
                        />
                      ) : (
                        <Flex
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                          justify={header.column.columnDef.id === 'action' ? 'end' : 'space-between'}
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          style={{ cursor: 'pointer' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.columnDef.id === 'select' ? null : {
                            asc: '▽',
                            desc: '△',
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      )}
                  </Th>
                ))}
              </Fragment>
            ))}
          </Tr>
        </Thead>

        {loadingState ? (
          <Tbody />
        ) : (
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr px="20px" key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        <Flex justifyContent={cell.column.id === 'action' ? 'end' : 'space-between'}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Flex>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
            {additionalBottomRows ? additionalBottomRows : null}
          </Tbody>
        )}
      </Table>
    )}

    <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
      <Text
        fontSize="sm"
        color="gray.500"
        fontWeight="normal"
        mb={{ sm: '24px', md: '0px' }}
      >
        {t('showing', { ns: ['labels'] })} {pageSize * pageIndex + 1}{' '}
        {t('toEntry', { ns: ['labels'] })}{' '}
        {pageSize * (pageIndex + 1) <= table.getRowCount()
          ? pageSize * (pageIndex + 1)
          : table.getRowCount()}{' '}
        {t('ofEntry', { ns: ['labels'] })} {table.getRowCount()}{' '}
        {t('entries', { ns: ['labels'] })}
      </Text>
      <div className="flex items-center gap-2">
        <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
          <Select
            value={pageSize}
            mr={2}
            onChange={e => {
              const newPageSize = Number(e.target.value)
              setPagination({ pageIndex: 0, pageSize: newPageSize })
            }}
          >
            {pageSizes.map(pageSizeOption => (
              <option key={`pagesize-${pageSizeOption}`} value={pageSizeOption}>
                {pageSizeOption}
              </option>
            ))}
          </Select>
          <Button
            variant="no-effects"
            onClick={() => setPagination({ ...pagination, pageIndex: pageIndex - 1 })}
            disabled={!table.getCanPreviousPage()}
            transition="all .5s ease"
            w="40px"
            h="40px"
            borderRadius="50%"
            bg="transparent"
            border="1px solid"
            borderColor={gray200white}
            display={
              table.getCanPreviousPage()
                ? 'flex'
                : 'none'
            }
            _hover={{
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
          >
            <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
          </Button>
          {table.getPageOptions().map((pageNumber) => {
            return (
              <Button
                variant="no-effects"
                transition="all .5s ease"
                onClick={() => setPagination({ ...pagination, pageIndex: pageNumber })}
                w="40px"
                h="40px"
                borderRadius="50%"
                bg={pageNumber === pageIndex ? brandColor : 'transparent'}
                border={
                  pageNumber === pageIndex
                    ? 'none'
                    : '1px solid lightgray'
                }
                _hover={
                  pageNumber === pageIndex
                    ? {
                      opacity: '0.7',
                    }
                    : {
                      bg: 'whiteAlpha.100',
                    }
                }
                key={`pageNumber-${pageNumber}`}
              >
                <Text
                  fontSize="sm"
                  color={pageNumber === pageIndex ? '#fff' : textColor}
                >
                  {pageNumber + 1}
                </Text>
              </Button>
            );
          })}
          <Button
            variant="no-effects"
            onClick={() => setPagination({ ...pagination, pageIndex: pageIndex + 1 })}
            disabled={!table.getCanNextPage()}
            transition="all .5s ease"
            w="40px"
            h="40px"
            borderRadius="50%"
            bg="transparent"
            border="1px solid"
            borderColor={gray200white}
            display={
              table.getCanNextPage()
                ? 'flex'
                : 'none'
            }
            _hover={{
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
          >
            <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
          </Button>
        </Stack>
      </div>
    </Flex>
  </>);
}
