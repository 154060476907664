import { PostosProject } from "contexts/globalStoreProjects";

export const makeArrOfObjectsUniqueByKey = 
  <T extends { [key: string]: any }>(arr: T[], uniqueKey: string): T[] => {
    const uniqueObjects = arr.reduce((acc, current) => {
      if (!acc.has(current[uniqueKey])) {
        acc.set(current[uniqueKey], current);
      }
      return acc;
    }, new Map<any, T>());

    return Array.from(uniqueObjects.values());
  }

export const checkIfInvoiceDataIsInProject = (project: PostosProject) => 
  project.vatRate !== null
&& Boolean(project.language)
&& Boolean(project.currency)
&& Boolean(project.termsOfPayment)
&& project.rounding !== null;