import { Navigate, Route, BrowserRouter } from 'react-router-dom';

// Admin Imports
// import DashboardsDefault from 'views/admin/dashboards/default';
import Login from 'views/auth/signIn/Login';
import Projects from 'views/general/projects';
import AddEditProject from 'views/general/projects/components/AddEditProjects';
import AddEditCompanies from 'views/general/companies/components/AddEditCompanies';
import Bookings from 'views/faktura/bookings';
import AddEditBooking from 'views/faktura/bookings/components/AddEditBooking';
import Reports from 'views/faktura/reports';
import Invoices from 'views/faktura/invoices';
import GeneralLayout from './layouts/general';
import UnderConstruction from 'views/admin/main/others/UnderConstruction';
import Companies from 'views/general/companies';
import CostCenters from 'views/faktura/cost-centers';
import AddEditCostCenter from 'views/faktura/cost-centers/components/AddEditCostCenter';
import UserHome from 'views/admin/main/others/UserHome';
import useUserDataRolesStore from 'contexts/authStore';
import { Routes } from 'react-router-dom';
import { useMemo } from 'react';
import {
  checkIfUserProjectCreator,
  checkIfUserFakturaAdmin,
  checkIsUserAdminInAnyProject,
  checkIsUserFinancialAdminInAnyProject,
  checkIsUserInAnyProject,
  checkIfUserCostCenterCreator,
  checkIfUserCompanyCreator,
  checkIsUserInvoiceApproverInAnyProject
} from 'utils/roleHelpers';
import { useProjectStore } from 'contexts/globalStoreProjects';
import NoAccess from 'views/admin/main/others/NoAccess';
import NotFound from 'views/admin/main/others/404';

export const routesParams: { path: string; pageTitle: string; crumbs: Crumb[] }[] = [
  {
    path: '/auth/*',
    pageTitle: 'login',
    crumbs: []
  },
  {
    path: '/',
    pageTitle: 'home',
    crumbs: []
  },
  {
    path: '/companies',
    pageTitle: 'companies',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'companies', path: '/companies', isCurrent: true },
    ]
  },
  {
    path: '/companies/create',
    pageTitle: 'createCompany',
    crumbs: [
      { name: 'companies', path: '/companies', isCurrent: false },
      { name: 'create', path: '/companies/create', isCurrent: true },
    ]
  },
  {
    path: '/companies/edit/*',
    pageTitle: 'editCompany',
    crumbs: [
      { name: 'companies', path: '/companies', isCurrent: false },
      { name: 'edit', path: '/companies/edit/:id', isCurrent: true },
    ],
  },
  {
    path: '/faktura/invoices',
    pageTitle: 'invoices',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'invoices', path: '/faktura/invoices', isCurrent: true }
    ]
  },
  {
    path: '/faktura/reports',
    pageTitle: 'reports',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'reports', path: '/faktura/reports', isCurrent: true }
    ]
  },
  {
    path: '/projects',
    pageTitle: 'projects',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'projects', path: '/projects', isCurrent: true },
    ],
  },
  {
    path: '/projects/create',
    pageTitle: 'createProject',
    crumbs: [
      { name: 'projects', path: '/projects', isCurrent: false },
      { name: 'create', path: '/projects/create', isCurrent: true },
    ],
  },
  {
    path: '/projects/edit/*',
    pageTitle: 'editProject',
    crumbs: [
      { name: 'projects', path: '/projects', isCurrent: false },
      { name: 'edit', path: '/projects/edit/:id', isCurrent: true },
    ],
  },
  {
    path: '/faktura/bookings',
    pageTitle: 'bookings',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'bookings', path: '/faktura/bookings', isCurrent: true }
    ]
  },
  {
    path: '/faktura/bookings/create',
    pageTitle: 'createBooking',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'bookings', path: '/faktura/bookings', isCurrent: false },
      { name: 'create', path: '/faktura/bookings/create', isCurrent: true }
    ]
  },
  {
    path: '/faktura/bookings/edit/*',
    pageTitle: 'editBooking',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'bookings', path: '/faktura/bookings', isCurrent: false },
      { name: 'edit', path: '/faktura/bookings/edit/:id', isCurrent: true }
    ]
  },
  {
    path: '/cost-centers',
    pageTitle: 'costCenters',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'cost-centers', path: '/cost-centers', isCurrent: false },
    ]
  },
  {
    path: '/cost-centers/create',
    pageTitle: 'createCostCenter',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'cost-centers', path: '/cost-centers', isCurrent: false },
      { name: 'create', path: '/cost-centers/create', isCurrent: true }
    ]
  },
  {
    path: '/cost-centers/edit/*',
    pageTitle: 'editCostCenter',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'cost-centers', path: '/cost-centers', isCurrent: false },
      { name: 'edit', path: '/cost-centers/edit/:id', isCurrent: true }
    ]
  },
  {
    path: '/configuration',
    pageTitle: 'configuration',
    crumbs: [
      { name: 'home', path: '/', isCurrent: false },
      { name: 'configuration', path: '/configuration', isCurrent: true }
    ]
  },
];

const AppRouter = () => {
  const allProjectUsers = useProjectStore((state) => state.allProjectUsers);
  const userData = useUserDataRolesStore((state) => state.userData);
  const userRoles = useUserDataRolesStore((state) => state.userRoles);

  const isUserFakturaAdmin = useMemo(() => checkIfUserFakturaAdmin(userRoles), [userRoles]);
  const isUserProjectCreator = useMemo(() => checkIfUserProjectCreator(userRoles), [userRoles]);
  const isUserCostCenterCreator = useMemo(() => checkIfUserCostCenterCreator(userRoles), [userRoles]);
  const isUserCompanyCreator = useMemo(() => checkIfUserCompanyCreator(userRoles), [userRoles]);

  const isUserInAnyProject = useMemo(() =>
    checkIsUserInAnyProject(allProjectUsers, userData.id), [allProjectUsers, userData]);

  const isUserAdminInAnyProject = useMemo(() =>
    checkIsUserAdminInAnyProject(allProjectUsers, userData.id), [allProjectUsers, userData]);

  const isUserFinancialAdminInAnyProject = useMemo(() =>
    checkIsUserFinancialAdminInAnyProject(allProjectUsers, userData.id), [allProjectUsers, userData]);

  const isUserInvoiceApproverOnAnyProject = useMemo(() =>
    checkIsUserInvoiceApproverInAnyProject(allProjectUsers, userData.id), [allProjectUsers, userData]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" element={<Login />} />
        <Route
          path="/"
          element={<GeneralLayout />}
        >
          <Route index element={<UserHome />} />
          <Route path="general/*" element={<UnderConstruction />} />

          <Route path="companies" element={
            isUserCompanyCreator
              || isUserAdminInAnyProject
              || isUserFinancialAdminInAnyProject
              || isUserInvoiceApproverOnAnyProject ? <Companies /> : <NoAccess />}
          />

          <Route path="companies/create" element={
            isUserCompanyCreator ? <AddEditCompanies /> : <NoAccess />} />
          <Route path="companies/edit/:id" element={
            isUserCompanyCreator
              || isUserProjectCreator
              || isUserAdminInAnyProject
              || isUserFinancialAdminInAnyProject ? <AddEditCompanies /> : <NoAccess />} />

          <Route path="general/projects/*" element={<Navigate to="/projects" />} />

          <Route path="projects" element={isUserFakturaAdmin
            || isUserProjectCreator
            || isUserAdminInAnyProject
            || isUserFinancialAdminInAnyProject
            || isUserInvoiceApproverOnAnyProject ? <Projects /> : <NoAccess />} />

          <Route path="projects/create" element={isUserProjectCreator ? <AddEditProject /> : <NoAccess />} />
          <Route path="projects/edit/:id" element={
            isUserFakturaAdmin
              || isUserAdminInAnyProject
              || isUserFinancialAdminInAnyProject ? <AddEditProject /> : <NoAccess />} />

          <Route path="postos" element={<UnderConstruction />} />
          <Route path="postos/dashboards/default" element={<UnderConstruction />} />

          <Route path="faktura" element={<UnderConstruction />} />
          <Route path="faktura/bookings" element={
            isUserInAnyProject
              || isUserAdminInAnyProject
              || isUserFinancialAdminInAnyProject
              || isUserInvoiceApproverOnAnyProject ?
              <Bookings /> : <NoAccess />} />
          <Route path="faktura/bookings/create" element={
            isUserInAnyProject
              || isUserAdminInAnyProject ?
              <AddEditBooking /> : <NoAccess />} />
          <Route path="faktura/bookings/edit/:id" element={
            isUserInAnyProject
              || isUserAdminInAnyProject ?
              <AddEditBooking /> : <NoAccess />} />
          <Route path="faktura/bookings/duplicate/:id" element={
            isUserInAnyProject
              || isUserAdminInAnyProject ?
              <AddEditBooking /> : <NoAccess />} />

          <Route path="cost-centers" element={
            isUserCostCenterCreator
              || isUserAdminInAnyProject
              || isUserFinancialAdminInAnyProject
              || isUserInvoiceApproverOnAnyProject ?
              <CostCenters /> : <NoAccess />} />
          <Route path="cost-centers/create" element={
            isUserCostCenterCreator ?
              <AddEditCostCenter /> : <NoAccess />} />
          <Route path="cost-centers/edit/:id" element={
            isUserCostCenterCreator
              || isUserProjectCreator
              || isUserAdminInAnyProject
              || isUserFinancialAdminInAnyProject ?
              <AddEditCostCenter /> : <NoAccess />} />

          <Route path="faktura/reports" element={
            isUserAdminInAnyProject
              || isUserFinancialAdminInAnyProject
              || isUserInvoiceApproverOnAnyProject ?
              <Reports /> : <NoAccess />} />
          <Route path="faktura/invoices" element={
            isUserFinancialAdminInAnyProject || isUserInvoiceApproverOnAnyProject ?
              <Invoices /> : <NoAccess />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes></BrowserRouter >)
};

export default AppRouter;
