export const routesDe = {
  home: 'Home',
  credentials: 'Credentials',
  triggers: 'Triggers',
  cron: 'Cron',
  rest: 'Rest',
  logs: 'Logs',
  logsProcesses: 'Processes',
  logsSteps: 'Steps',
  logsEvents: 'Events',
  pages: 'Pages',
  processes: 'Processes',
  permissions: 'Permissions',
  users: 'Users',
  roles: 'Roles',
  templates: 'Templates',
  translations: 'Translations',
  settings: 'Settings',
  frontend: 'Frontend',
  instance: 'Instance',
  smtp: 'Smtp',
  modules: 'Modules',
  validations: 'Validations',
  process: 'Process',
  local: 'Local',
  hub: 'Hub',
  gdpr: 'GDPR',
  gdprCronTrigger: 'Cron Trigger',
  gdprRestTrigger: 'Rest Trigger',
  gdprModules: 'Modules',
  gdprProcessCredentials: 'Process Credentials',
  gdprProcesses: 'Processes',
  plugins: 'Plugins',
  instances: 'Instances',
  projectBookings: 'Projekt Buchungen',
  reports: 'Meldungen',
  bookings: 'Buchungen',
  invoices: 'Rechnungen',
  configuration: 'Konfiguration',
  login: 'Login',
  createBooking: 'Buchung Erstellen',
  editBooking: 'Buchung Bearbeiten',
  faktura: 'Faktura',
  postos: 'Postos',
  createProject: 'Projekt Erstellen',
  editProject: 'Projekt Bearbeiten',
  createCompany: 'Firma Erstellen',
  editCompany: 'Firma Bearbeiten',
  projects: 'Projekte',
  companies: 'Firmen',
  costCenters: 'Cost centers',
  createCostCenter: 'Cost center erstellen',
  editCostCenter: 'Cost center bearbeiten',
}
