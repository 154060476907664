import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableCostCenters from './components/SearchTableCostCenters';

export default function Bookings() {
	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<SearchTableCostCenters />
			</Card>
		</Flex>
	);
}
