/* eslint-disable */
/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import * as process from "process";

/** FakturaCompanyCreate */
export interface FakturaCompanyCreate {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  id?: number;
  /**
   * @minLength 2
   * @maxLength 128
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 16
   */
  industry: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  address_line_1: string;
  /**
   * @minLength 1
   * @maxLength 32
   */
  zip_code: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  city: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  country: string;
  /**
   * @minLength 1
   * @maxLength 16
   */
  registration_number?: string;
  /**
   * @minLength 1
   * @maxLength 16
   */
  uid?: string;
  reverse_charge?: boolean;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  vat_rate?: number;
}

/** FakturaPersonCreate */
export interface FakturaPersonCreate {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  id?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  company_id: number;
  /**
   * @minLength 2
   * @maxLength 50
   */
  first_name: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  last_name: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  gender?: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  email: string;
  /**
   * @minLength 2
   * @maxLength 64
   */
  phone?: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  address_line_1: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  address_line_2?: string;
  /**
   * @minLength 2
   * @maxLength 32
   */
  zip_code: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  city: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  country: string;
  /**
   * @minLength 2
   * @maxLength 64
   */
  languge: string;
}

/** FakturaBookingStatusChange */
export interface FakturaBookingStatusChange {
  ids: number[];
  status: string;
}

/** FakturaCompanyUpdate */
export interface FakturaCompanyUpdate {
  /**
   * @minLength 2
   * @maxLength 128
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  line1: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  line2?: string;
  /**
   * @minLength 3
   * @maxLength 4
   */
  postalcode: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  city: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  country: string;
  /**
   * @maxItems 8
   * @minItems 1
   */
  industry: string[];
  /** @format int32 */
  id: number;
}

/** FakturaPersonUpdate */
export interface FakturaPersonUpdate {
  /**
   * @minLength 2
   * @maxLength 128
   */
  first: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  last: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  title?: string;
  /**
   * @minLength 2
   * @maxLength 64
   */
  'phone#'?: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  email: string;
  /**
   * @minLength 2
   * @maxLength 128
   */
  role: string;
  /**
   * @format int32
   * @min 1
   */
  companyId: number;
  /**
   * @format int32
   * @min 1
   */
  id: number;
}

/** FakturaBookingsCreate */
export interface FakturaBookingsCreate {
  /**
   * @format int32
   * @min 1
   */
  projectId: number;
  /** @minLength 1 */
  description: string;
  /** @format date-time */
  dateFrom: string;
  /**
   * @format int32
   * @min 1
   */
  duration: number;
  status: string;
  /** @format int32 */
  userId: number;
  /**
   * @format int32
   * @min 1
   */
  taskId: number;
  /** @format date-time */
  dateTill: string;
  isEntryByTimeframe: boolean;
}

/** FakturaProjectUpdate */
export interface FakturaProjectUpdate {
  /**
   * @format int32
   * @min 1
   */
  id: number;
}

/** FakturaProjectCreate */
export interface FakturaProjectCreate {
  /**
   * @minLength 3
   * @maxLength 32
   */
  title: string;
  /** @maxLength 10240 */
  description?: string;
  /** @maxLength 32 */
  type: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /**
   * @format int32
   * @min 0
   * @max 4096
   */
  hours: number;
  /**
   * @format int32
   * @min 0
   * @max 240
   */
  hoursPerMonth?: number;
  users: object[];
  companyName: string;
  contactPerson?: string;
  contactEmail?: string;
  addressLine1: string;
  addressLine2?: string;
  zipCode: string;
  city: string;
  country: string;
  registrationNumber?: string;
  uid?: string;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  vatRate?: number;
  reverseCharge?: boolean;
  language: string;
  currency: string;
  tasks: object[];
  timesheetOnInvoice: boolean;
  costCenter?: string;
  termsOfPayment: string;
  /** @format int32 */
  rounding: number;
}

/** LoginByEmailAndPasswordValidation */
export interface LoginByEmailAndPasswordValidation {
  email: string;
  password: string;
}

import type {AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType} from 'axios';
import axios from 'axios';
import auth from '../auth/index'

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({securityWorker, secure, format, ...axiosConfig}: ApiConfig<SecurityDataType> = {}) {
    const xAuthToken = !auth.isLoggedIn() ? 'guest' : auth.getToken()
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: window.location.hostname === 'localhost' ? '' : '/api',
      headers: {...axiosConfig.headers, 'x-auth-token': xAuthToken}
    });
    this.instance.interceptors.response.use(
      (response) => {
        if (response?.status === 401) auth.logout()

        return response
      },
      (error) => {
        console.log(error?.response)
        if (error?.response?.status === 401) auth.logout()

        return Promise.reject(error)
      })
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
                                               secure,
                                               path,
                                               type,
                                               query,
                                               format,
                                               body,
                                               ...params
                                             }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? {'Content-Type': type} : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Faktura
 * @version 2021.09
 * @baseUrl //rest.qa.m8y.ioapi
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  faktura = {
    /**
     * No description
     *
     * @tags faktura:person:fetch all persons by company id
     * @name FakturaPersonFetchAllPersonsByCompanyId
     * @summary faktura:person:fetch all persons by company id
     * @request GET:/faktura/person
     */
    fakturaPersonFetchAllPersonsByCompanyId: (
      query: {
        /**
         * companyId
         * @format int32
         */
        companyId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/person`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:person:create
     * @name FakturaPersonCreate
     * @summary faktura:person:create
     * @request POST:/faktura/person
     */
    fakturaPersonCreate: (FakturaPersonCreate: FakturaPersonCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/person`,
        method: 'POST',
        body: FakturaPersonCreate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:person:update by id
     * @name FakturaPersonUpdateById
     * @summary faktura:person:update by id
     * @request PUT:/faktura/person
     */
    fakturaPersonUpdateById: (FakturaPersonUpdate: FakturaPersonUpdate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/person`,
        method: 'PUT',
        body: FakturaPersonUpdate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:whoAmI
     * @name FakturaWhoAmI
     * @summary faktura:whoAmI
     * @request GET:/faktura/whoami
     */
    fakturaWhoAmI: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/whoami`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:fetch user role by projectId
     * @name FakturaFetchUserRoleByProjectId
     * @summary faktura:fetch user role by projectId
     * @request GET:/faktura/project-user
     */
    fakturaFetchUserRoleByProjectId: (
      query: {
        /**
         * projectId
         * @format int32
         */
        projectId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/project-user`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:get user detail by id
     * @name FakturaGetUserDetailById
     * @summary faktura:get user detail by id
     * @request GET:/faktura/user
     */
    fakturaGetUserDetailById: (
      query: {
        /**
         * userId
         * @format int32
         */
        userId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/user`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura: fetch users for project by id
     * @name FakturaFetchUsersForProjectById
     * @summary faktura: fetch users for project by id
     * @request GET:/faktura/users-project
     */
    fakturaFetchUsersForProjectById: (
      query: {
        /**
         * projectId
         * @format int32
         */
        projectId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/users-project`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura: get report by timeframe
     * @name FakturaGetReportByTimeframe
     * @summary faktura: get report by timeframe
     * @request GET:/faktura/reports/by-timeframe
     */
    fakturaGetReportByTimeframe: (
      query: {
        /**
         * dateFrom
         * @format date-time
         */
        dateFrom: string;
        /**
         * dateTill
         * @format date-time
         */
        dateTill: string;
        /**
         * projectId
         * @format int32
         */
        projectId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/reports/by-timeframe`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:get project time booking by id
     * @name FakturaGetProjectTimeBookingById
     * @summary faktura:get project time booking by id
     * @request GET:/faktura/booking
     */
    fakturaGetProjectTimeBookingById: (
      query: {
        /**
         * bookingId
         * @format int32
         */
        bookingId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/booking`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:booking:create
     * @name FakturaBookingCreate
     * @summary faktura:booking:create
     * @request POST:/faktura/booking
     */
    fakturaBookingCreate: (FakturaBookingsCreate: FakturaBookingsCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/booking`,
        method: 'POST',
        body: FakturaBookingsCreate,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:booking:update by id
     * @name FakturaBookingUpdateById
     * @summary faktura:booking:update by id
     * @request PUT:/faktura/booking
     */
    fakturaBookingUpdateById: (FakturaBookingsCreate: FakturaBookingsCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/booking`,
        method: 'PUT',
        body: FakturaBookingsCreate,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:booking:delete
     * @name FakturaBookingDelete
     * @summary faktura:booking:delete
     * @request DELETE:/faktura/booking
     */
    fakturaBookingDelete: (
      query: {
        /**
         * id
         * @format int32
         */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/booking`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:creator booking
     * @name FakturaCreatorBooking
     * @summary faktura:creator booking
     * @request GET:/faktura/createbooking
     */
    fakturaCreatorBooking: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/createbooking`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:get bookings for project by id
     * @name FakturaGetBookingsForProjectById
     * @summary faktura:get bookings for project by id
     * @request GET:/faktura/bookings-project
     */
    fakturaGetBookingsForProjectById: (
      query: {
        /**
         * projectId
         * @format int32
         */
        projectId: number;
        /** from */
        from?: string;
        /** till */
        till?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/bookings-project`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:invoices:list
     * @name FakturaInvoicesList
     * @summary faktura:invoices:list
     * @request GET:/faktura/invoices
     */
    fakturaInvoicesList: (
      query: {
        /**
         * dateFrom
         * @format date-time
         */
        dateFrom: string;
        /**
         * dateTill
         * @format date-time
         */
        dateTill: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/invoices`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:invoice:pdf
     * @name FakturaInvoicePdf
     * @summary faktura:invoice:pdf
     * @request GET:/faktura/invoice-pdf
     */
    fakturaInvoicePdf: (
      query: {
        /**
         * invoiceId
         * @format int32
         */
        invoiceId: number;
        /** invoiceType */
        invoiceType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/invoice-pdf`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:questions:user_test
     * @name FakturaQuestionsUserTest
     * @summary faktura:questions:user_test
     * @request GET:/faktura/tests
     */
    fakturaQuestionsUserTest: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/tests`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:fetch all project tasks
     * @name FakturaFetchAllProjectTasks
     * @summary faktura:fetch all project tasks
     * @request GET:/faktura/project-tasks
     */
    fakturaFetchAllProjectTasks: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/project-tasks`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:bookings:getAllByUser
     * @name FakturaBookingsGetAllByUser
     * @summary faktura:bookings:getAllByUser
     * @request GET:/faktura/bookings
     */
    fakturaBookingsGetAllByUser: (
      query: {
        /**
         * projectId
         * @format int32
         */
        projectId?: number;
        /** status */
        status?: string;
        /** from */
        from: string;
        /** till */
        till: string;
        /**
         * taskId
         * @format int32
         */
        taskId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/bookings`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:fetch all projects
     * @name FakturaFetchAllProjects
     * @summary faktura:fetch all projects
     * @request GET:/faktura/projects-all
     */
    fakturaFetchAllProjects: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/projects-all`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:invoices:timesheets
     * @name FakturaInvoicesTimesheets
     * @summary faktura:invoices:timesheets
     * @request GET:/faktura/invoice-timesheets
     */
    fakturaInvoicesTimesheets: (
      query: {
        /**
         * invoiceId
         * @format int32
         */
        invoiceId: number;
        /** invoiceType */
        invoiceType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/invoice-timesheets`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura: get monthly report
     * @name FakturaGetMonthlyReport
     * @summary faktura: get monthly report
     * @request GET:/faktura/reports/monthly
     */
    fakturaGetMonthlyReport: (
      query: {
        /**
         * dateFrom
         * @format date-time
         */
        dateFrom: string;
        /**
         * dateTill
         * @format date-time
         */
        dateTill: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/reports/monthly`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:fetch projects
     * @name FakturaFetchProjects
     * @summary faktura:fetch projects
     * @request GET:/faktura/projects
     */
    fakturaFetchProjects: (
      query?: {
        /** role */
        role?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/projects`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:fetch project by id
     * @name FakturaFetchProjectById
     * @summary faktura:fetch project by id
     * @request GET:/faktura/project
     */
    fakturaFetchProjectById: (
      query: {
        /**
         * projectId
         * @format int32
         */
        projectId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/project`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:create project
     * @name FakturaCreateProject
     * @summary faktura:create project
     * @request POST:/faktura/project
     */
    fakturaCreateProject: (FakturaProjectCreate: FakturaProjectCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/project`,
        method: 'POST',
        body: FakturaProjectCreate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:update project by id
     * @name FakturaUpdateProjectById
     * @summary faktura:update project by id
     * @request PUT:/faktura/project
     */
    fakturaUpdateProjectById: (FakturaProjectUpdate: FakturaProjectUpdate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/project`,
        method: 'PUT',
        body: FakturaProjectUpdate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:fetch all users
     * @name FakturaFetchAllUsers
     * @summary faktura:fetch all users
     * @request GET:/faktura/users-all
     */
    fakturaFetchAllUsers: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/users-all`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:fetch project tasks by project id
     * @name FakturaFetchProjectTasksByProjectId
     * @summary faktura:fetch project tasks by project id
     * @request GET:/faktura/project-tasks-by-project-id
     */
    fakturaFetchProjectTasksByProjectId: (
      query: {
        /**
         * projectId
         * @format int32
         */
        projectId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/project-tasks-by-project-id`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:invoice:cancel
     * @name FakturaInvoiceCancel
     * @summary faktura:invoice:cancel
     * @request GET:/faktura/invoice-cancel
     */
    fakturaInvoiceCancel: (
      query: {
        /**
         * invoiceId
         * @format int32
         */
        invoiceId: number;
        /** invoiceType */
        invoiceType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/invoice-cancel`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura: get yearly report
     * @name FakturaGetYearlyReport
     * @summary faktura: get yearly report
     * @request GET:/faktura/reports/yearly
     */
    fakturaGetYearlyReport: (
      query: {
        /**
         * dateFrom
         * @format date-time
         */
        dateFrom: string;
        /**
         * dateTill
         * @format date-time
         */
        dateTill: string;
        /**
         * userId
         * @format int32
         */
        userId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/reports/yearly`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:company:fetch all companies
     * @name FakturaCompanyFetchAllCompanies
     * @summary faktura:company:fetch all companies
     * @request GET:/faktura/company
     */
    fakturaCompanyFetchAllCompanies: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/company`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:company:create
     * @name FakturaCompanyCreate
     * @summary faktura:company:create
     * @request POST:/faktura/company
     */
    fakturaCompanyCreate: (FakturaCompanyCreate: FakturaCompanyCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/company`,
        method: 'POST',
        body: FakturaCompanyCreate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:company:update by id
     * @name FakturaCompanyUpdateById
     * @summary faktura:company:update by id
     * @request PUT:/faktura/company
     */
    fakturaCompanyUpdateById: (FakturaCompanyUpdate: FakturaCompanyUpdate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/company`,
        method: 'PUT',
        body: FakturaCompanyUpdate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:booking:status-change
     * @name FakturaBookingStatusChange
     * @summary faktura:booking:status-change
     * @request POST:/faktura/booking/status-change
     */
    fakturaBookingStatusChange: (FakturaBookingStatusChange: FakturaBookingStatusChange, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/booking/status-change`,
        method: 'POST',
        body: FakturaBookingStatusChange,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:timesheets:data
     * @name FakturaTimesheetsData
     * @summary faktura:timesheets:data
     * @request GET:/faktura/timesheet-data
     */
    fakturaTimesheetsData: (
      query: {
        /**
         * projectId
         * @format int32
         */
        projectId: number;
        /**
         * dateFrom
         * @format date-time
         */
        dateFrom: string;
        /**
         * dateTill
         * @format date-time
         */
        dateTill: string;
        /** invoiceType */
        invoiceType: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/timesheet-data`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags faktura:invoice:data
     * @name FakturaInvoiceData
     * @summary faktura:invoice:data
     * @request GET:/faktura/invoice-data
     */
    fakturaInvoiceData: (
      query: {
        /**
         * projectId
         * @format int32
         */
        projectId: number;
        /**
         * dateFrom
         * @format date-time
         */
        dateFrom: string;
        /**
         * dateTill
         * @format date-time
         */
        dateTill: string;
        /** invoiceType */
        invoiceType: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/faktura/invoice-data`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  loginByPassword = {
    /**
     * No description
     *
     * @tags login by password
     * @name LoginByPassword
     * @summary login by password
     * @request POST:/login-by-password
     */
    loginByPassword: (
      LoginByEmailAndPasswordValidation: LoginByEmailAndPasswordValidation,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/login-by-password`,
        method: 'POST',
        body: LoginByEmailAndPasswordValidation,
        type: ContentType.Json,
        ...params,
      }),
  };
}
