import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { addYears, format, startOfYear } from 'date-fns';
import { convertMinutesToHHmmForInput, zonedEndOfMonth } from 'utils/dateHelpers';
import {
  Checkbox,
  IconButton,
  SimpleGrid,
  Spacer,
  Spinner,
  Flex,
  Text,
  useColorModeValue,
  Icon,
  Stack,
  Select,
  Input,
} from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';
import { RepeatIcon } from '@chakra-ui/icons';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import FakturaTable from 'components/tables/FakturaTable';
import { tableStorage } from 'contexts/tableStorage';
import useLoaderStore from 'contexts/globalStore';
import { useReportsStore } from 'contexts/globalStoreReports';
import { User, useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';
import { ProjectUser, useProjectStore } from 'contexts/globalStoreProjects';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = {
  user: string;
  project: string;
  month: number;
  year: number;
  currency: string;
  totalDuration: number;
  unit: string;
  totalResourceCost: number;
  totalClientCost: number;
};
export default function SearchTableByResource() {
  const textColor = useColorModeValue('navy.700', 'white');

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const isInitDone = useReportsStore((state) => state.isInitDone);
  const set = useReportsStore((state) => state.set);

  const { fakturaUsers } = useFakturaUsersStore(
    (state: any) => ({ fakturaUsers: state.users }),
  );

  const allProjectUsers = useProjectStore((state) => state.allProjectUsers);
  const getProjectByProjectUserId = useProjectStore((state) => state.getProjectByProjectUserId);

  const { fakturaProjects } = useProjectStore(
    (state: any) => ({ fakturaProjects: state.projects }),
  );
  const { fakturaReportsByYear } = useReportsStore(
    (state: any) => ({ fakturaReportsByYear: state.reportsByYear }),
  );
  const { reportsByYearMeta } = useReportsStore(
    (state: any) => ({ reportsByYearMeta: state.reportsByYearMeta }),
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);

  const fetchYearsReports = useReportsStore(
    (state) => state.fetchYearlyReports,
  );

  const [isYearlyChecked, setIsYearlyChecked] = useState(false);
  const currentDate = new Date();

  const startDayOfCurrentYear = startOfYear(currentDate);
  const endDayOfCurrentYear = startOfYear(addYears(currentDate, 1));
  const newStartDayOfCurrentYear: string = format(
    startDayOfCurrentYear,
    'yyyy-MM-dd',
  );

  const newEndDayOfCurrentYear = format(endDayOfCurrentYear, 'yyyy-MM-dd');

  const [startDate, setStartDate] = useState<string | undefined>(
    newStartDayOfCurrentYear,
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    newEndDayOfCurrentYear,
  );

  const [activeProjectsOnly, setActiveProjectsOnly] = useState<boolean>(true);

  const [userId, setUserId] = useState<number | null>(null);

  const refreshData = useCallback(() => {
    setLoadingState(true);
    fetchYearsReports(
      startDate,
      zonedEndOfMonth(new Date(endDate)),
      userId,
      activeProjectsOnly,
      pagination.pageIndex + 1,
      pagination.pageSize,
      sorting[0]?.id || null,
      sorting[0]?.desc ? 'desc' : 'asc',
    )
      .then(() => {
        setLoadingState(false);
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Faktura Reports:', error);
      });
  }, [setLoadingState,
    fetchYearsReports,
    startDate,
    endDate,
    userId,
    activeProjectsOnly,
    pagination.pageIndex,
    pagination.pageSize,
    sorting]);

  const resetReportYearList = useReportsStore(
    (state) => state.resetYearlyReportList,
  );

  const handleStartDateChange = (event: any) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (event: any) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
  };
  const handleUserIdChange = (event: any) => {
    const newUserId = event.target.value;
    setUserId(newUserId);
  };

  // const handleActiveProjectsToggle = (value: boolean) => {
  //   const newActiveProjectsOnlyValue = value;
  //   setActiveProjectsOnly(newActiveProjectsOnlyValue);
  // };

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && isInitDone) {
      if (startDate && endDate && userId !== null && userId !== 0) {
        resetReportYearList();

        refreshData();
      } else {
        resetReportYearList();
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [endDate,
    isInitDone,
    refreshData,
    resetReportYearList,
    startDate,
    userId]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaReportsByYear);
      setProjectData(fakturaProjects);
    }
    return () => {
      isCurrent = false;
    };
  }, [fakturaReportsByYear, fakturaProjects]);

  const [globalFilter, setGlobalFilter] = useState('');

  const tableName = useMemo(() => 'reportsByResource', []);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      startDate: newStartDate,
      endDate: newEndDate,
      userId: newUserId,
      isYearlyChecked: newIsYearlyChecked,
      activeProjectsOnly: newActiveProjectsOnly,
      pageSize: newPageSize,
      sorting: newSorting
    } = tableStorage.fetch(tableName)

    if (newGlobalFilter) setGlobalFilter(newGlobalFilter);
    if (newStartDate) setStartDate(newStartDate);
    if (newEndDate) setEndDate(newEndDate);
    if (newUserId) setUserId(newUserId);
    if (newIsYearlyChecked) setIsYearlyChecked(newIsYearlyChecked);
    if (newActiveProjectsOnly) setActiveProjectsOnly(newActiveProjectsOnly);
    if (newPageSize) setPagination({ ...pagination, pageSize: newPageSize });
    if (newSorting) setSorting(newSorting)
    set((state: any) => {
      state.isInitDone = true;
    });
    // Don't add 'pagination' below to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [set, tableName])

  useEffect(() => {
    tableStorage.save(tableName, {
      globalFilter,
      startDate,
      endDate,
      userId,
      isYearlyChecked,
      activeProjectsOnly
    })
  }, [globalFilter, startDate, endDate, userId, isYearlyChecked, tableName, activeProjectsOnly])

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('month', {
      id: 'month',

      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('month', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const year = info.row.original.year;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {info.getValue()}-{year}
          </Text>
        );
      },
    }),

    columnHelper.accessor('user', {
      id: 'user',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const userId = info.row.original.userId;
        const projectUser = allProjectUsers.find(
          (projectUser: ProjectUser) => projectUser.id === userId
        )
        const user = fakturaUsers.find((user: User) => user.userId === projectUser.userId);
        if (user) {
          const userDisplay = `${user.name} (${user.email})`;
          return (
            <Text color={textColor} fontSize="md" fontWeight="500">
              {userDisplay}
            </Text>
          );
        } else {
          return null;
        }
      },
    }),
    columnHelper.accessor('project', {
      id: 'project',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('project', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const projectId = info.row.original.projectId;
        const project = projectData.find((project) => project.id === projectId);
        if (project) {
          return (
            <Flex alignItems={'center'}>
              <Text
                color={textColor}
                key={project.id}
                fontSize="md"
                fontWeight="500"
              >
                {project.title}
              </Text>
              <Icon
                ml="5px"
                color={project.isActive ? 'green.500' : 'gray.500'}
                as={MdCircle}
                w="10px"
                h={'10px'}
              />
            </Flex>
          );
        } else {
          return null;
        }
      },
    }),

    columnHelper.accessor('totalDuration', {
      id: 'totalDuration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('totalAmountHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
          }}
        >
          {convertMinutesToHHmmForInput(Number(info.getValue()))}
        </Text>
      ),
    }),

    columnHelper.accessor('unit', {
      id: 'unit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('unit', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {t('hours', { ns: ['labels'] })}
        </Text>
      ),
    }),

    columnHelper.accessor('totalResourceCost', {
      id: 'totalResourceCost',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('totalResourceCostHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
          }}>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('totalClientCost', {
      id: 'totalClientCost',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('totalResourceClientCostHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
          }}
        >
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('currency', {
      id: 'currency',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('currency', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const userId = info.row.original.userId;
        const project = getProjectByProjectUserId(userId)

        if (project) {
          return (
            <Text color={textColor} fontSize="md" fontWeight="500">
              {project.currency}
            </Text>
          );
        } else {
          return null;
        }
      },
    }),
  ];
  const [data, setData] = useState(() => []);
  const [projectData, setProjectData] = useState(() => []);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting
    },
    pageCount: reportsByYearMeta.totalPages,
    rowCount: reportsByYearMeta.totalItems,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'auto', lg: 'auto' }}>
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify={{ sm: 'flex-start', lg: 'space-between' }}
        w="100%"
        px="22px"
        mb="36px"
        gap="4"
      >
        <SimpleGrid columns={{ base: 1, xl: 5 }} gap="20px" mt="5px">
          <Stack direction="column" gap="20px" mt="8px" ml="2px">
            <Checkbox isChecked={isYearlyChecked} onChange={(e) => setIsYearlyChecked(e.target.checked)}>
              {t('yearly', { ns: ['labels'] })}
            </Checkbox>
          </Stack>
          <Stack direction="column" justifyContent={'center'}>
            {/* <Checkbox
              isChecked={activeProjectsOnly}
              onChange={(event: any) => {
                handleActiveProjectsToggle(event.target.checked);
              }}
            >
              {t("onlyActiveProjects", { ns: ["labels"] })}
            </Checkbox> */}
          </Stack>
          <Stack direction="column" gap="20px">
            <Input
              type="date"
              color={textColor}
              value={startDate || ''}
              size="md"
              onChange={handleStartDateChange}
            />
          </Stack>
          {isYearlyChecked ? null : (
            <Stack direction="column" gap="20px">
              <Input
                type="date"
                color={textColor}
                value={endDate || ''}
                size="md"
                onChange={handleEndDateChange}
              />
            </Stack>
          )}
          <Stack direction="column" gap="20px">
            <Select value={userId || ''} onChange={handleUserIdChange}>
              <option value="" disabled>
                {t('selectUser', { ns: ['labels'] })}
              </option>

              {fakturaUsers.map((user: any) => (
                <option key={user.userId} value={user.userId}>
                  {user.name} ({user.email}){user.status === 'ACTIVE' ? '' : ` (${t('inactive', { ns: ['labels'] })})`}
                </option>
              ))}
            </Select>
          </Stack>
        </SimpleGrid>
        {/* Only display Spacer on larger screens */}
        <Spacer display={{ sm: 'none', lg: 'block' }} />

        {loadingState ? (
          <Spinner />
        ) : (
          <IconButton
            aria-label="Reload"
            variant="brand"
            onClick={refreshData}
            icon={<RepeatIcon />}
          >
            Reload
          </IconButton>
        )}
      </Flex>
      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Flex>
  );
}
