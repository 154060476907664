import './assets/css/App.css';
import ReactDOM from 'react-dom/client';

import App from './App';

//Import i18n.tsx
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
