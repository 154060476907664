export const labelsDe = {
  username: 'Benutzername',
  password: 'Passwort',
  forgotPassword: 'Passwort vergessen?',
  stayLoggedIn: 'Angemeldet bleiben',
  name: 'Name',
  status: 'Status',
  actions: 'Aktionen',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  phone: 'Telefon',
  email: 'E-Mail',
  search: 'Suche',
  export: 'Exportieren',
  from: 'Von',
  to: 'Bis',
  action: 'Aktion',
  allNone: 'Alle/Keine',
  first: 'Erste',
  last: 'Letzte',
  model: 'Modell',
  default: 'Standard',
  registeredOn: 'Registriert am',
  lastModifiedOn: 'Zuletzt geändert am',
  createdOn: 'Erstellt am',
  modifiedOn: 'Geändert am',
  hour: 'Stunde',
  minute: 'Minute',
  mandatory: 'Pflichtfeld',
  response: 'Antwort',
  notifications: 'Benachrichtigungen',
  text: 'Text',
  themes: 'Themen',
  details: 'Details',
  people: 'Personen',
  addresses: 'Adressen',
  download: 'Herunterladen',
  instance: 'Instanz',
  backendversion: 'Backend-Version',
  ipAddress: 'IP-Adresse',
  portNumber: 'Portnummer',
  changePassword: 'Passwort ändern',
  system: 'System',
  created: 'Erstellt',
  created2: 'erstellt',
  updated: 'Aktualisiert',
  error: 'Fehler',

  hello: 'Hallo',

  updated2: 'aktualisiert',
  cancellation: 'Abbrechen',
  print: 'Drucken',
  register: 'Registrieren',
  number: 'Nr.',
  mode: 'Modus',
  dark: 'Dunkel',
  fontSizes: 'Schriftgrößen',
  theme: 'Thema',
  none: 'Keine',
  successful: 'Erfolgreich',
  open: 'Öffnen',
  failed: 'Fehlgeschlagen',
  activeOnly: 'Nur Aktiv',
  keyType: 'Schlüsseltyp',
  payload: 'Payload',
  privateKey: 'Privater Schlüssel',
  signatureAlgorithm: 'Signaturalgorithmus',
  url: 'URL',
  maxPoolSize: 'Maximale Poolgröße',
  host: 'Host',
  port: 'Port',
  sid: 'SID',
  token: 'Token',
  apiVersion: 'API-Version',
  appId: 'App-ID',
  aud: 'AUD',
  id: 'ID',
  maxProcessingDurationInMSec: 'Maximale Verarbeitungsdauer in MSec',
  maxProcessingDuration: 'Maximale Verarbeitungsdauer',
  process: 'Prozess',
  triggerCondition: 'Auslösebedingung',
  seconds: 'Sekunden',
  minutes: 'Minuten',
  minutesInterval: 'Minutenintervall',
  hours: 'Stunden',
  hoursInterval: 'Stundenintervall',
  dayOfMonth: 'Tag des Monats',
  month: 'Monat',
  chooseMonth: 'Bitte Monat auswählen',
  dayOfWeek: 'Wochentag',
  method: 'Methode',
  maxRequestSize: 'Maximale Anforderungsgröße',
  frontEnd: 'Front-End',
  searchByName: 'Nach Namen suchen',
  finishedOn: 'Beendet am',
  startedOn: 'Gestartet am',
  instanceId: 'Instanz-ID',
  eventId: 'Ereignis-ID',
  threadId: 'Thread-ID',
  date: 'Datum',
  clientId: 'Client-ID',
  clientSecret: 'Client-Geheimnis',
  selectProcessFirst: 'Wählen Sie zuerst den Prozess aus',
  event: 'Ereignis',
  exceptionMessage: 'Ausnahme-Nachricht',
  exceptionStackTrace: 'Ausnahme-Stack-Trace',
  stepId: 'Schritt-ID',
  maxSimultaneousExecutions: 'Maximale gleichzeitige Ausführungen',
  saveProcessToEditSteps: 'Prozess speichern, um Schritte zu bearbeiten',
  defaultStepsName: 'Schrittname',
  properties: 'Eigenschaften',
  stepProperties: 'Schritteigenschaften',
  enabled: 'Schritt aktiviert',
  query: 'Abfrage',
  targetObject: 'Zielobjekt',
  credential: 'Anmeldeinformationen',
  recordName: 'Datensatzname',
  triggerId: 'Auslöser-ID',
  type: 'Typ',
  processingStartOn: 'Verarbeitung gestartet am',
  showCache: 'Cache anzeigen',
  list: 'Liste',
  json: 'JSON',
  async: 'Asynchrone Ausführung',
  input: 'Eingabe',
  output: 'Ausgabe',
  js: 'JavaScript',
  switch: 'Umschalten',
  collectionName: 'Sammlungsname',
  queryUpdate: 'Abfrage aktualisieren',
  mailTemplate: 'E-Mail-Vorlage',
  variables: 'Variablen',
  subject: 'Betreff',
  restResponseCode: 'REST-Antwortcode Zielobjekt',
  targetUrl: 'Ziel-URL',
  timeout: 'Timeout in MSec',
  body: 'Inhalt',
  headers: 'Header',
  queryParams: 'Abfrageparameter',
  channelId: 'Kanal-ID',
  message: 'Nachricht',
  logsTtlInMSec: 'Lebensdauer der Protokolle in MSec',
  stepName: 'Schrittname',
  copy: 'Kopieren',
  steps: 'Schritte',
  filter: 'Filter',
  role: 'Rolle',
  roles: 'Rollen',
  step: 'Schritt',
  childStep: 'Unterschritt',
  permissions: 'Berechtigungen',
  code: 'Code',
  assignable: 'Zuweisbar',
  yes: 'Ja',
  no: 'Nein',
  textTemplate: 'Textvorlage',
  en: 'Englisch',
  de: 'Deutsch',
  language: 'Sprache',
  connectionTimeout: 'Verbindungszeitlimit',
  writeTimeout: 'Schreibzeitlimit',
  template: 'Vorlage',
  grant_type: 'Zugriffstoken-Typ',
  refresh_token: 'Aktualisierungs-Token',
  setPasswordUrl: 'Passwort-URL festlegen',
  maxTriggerHttpThreads: 'Maximale Trigger-HTTP-Threads',
  maxTriggerHttpProcessingInMSec: 'Maximale Trigger-HTTP-Verarbeitung',
  maxProcessingThreads: 'Maximale Verarbeitungs-Threads',
  permissionType: 'Berechtigungstyp',
  unit: 'Einheit',
  changeableTimeout: 'Timeout',
  secondsInterval: 'Sekundenintervall',
  account: 'Konto',
  yourAccount: 'Ihr Konto',
  setPassword: 'Passwort festlegen',
  confirmPassword: 'Passwort bestätigen',
  baseUrlRest: 'Basis-URL Rest',
  stepType: 'Schritttyp',
  gdpr: 'DSGVO-relevant',
  history: 'Verlauf',
  notSet: 'Nicht festgelegt',
  defaultLocale: 'Standardsprache',
  global: 'Global',
  local: 'Lokal',
  est: 'Trace',
  addProcessName: 'Prozessnamen hinzufügen',
  visualId: 'Visuelle ID',
  bg: 'Bulgarisch',
  zh: 'Chinesisch (vereinfacht)',
  hr: 'Kroatisch',
  cs: 'Tschechisch',
  da: 'Dänisch',
  nl: 'Niederländisch',
  et: 'Estnisch',
  fi: 'Finnisch',
  fr: 'Französisch',
  el: 'Griechisch',
  hu: 'Ungarisch',
  ga: 'Irisch',
  it: 'Italienisch',
  lv: 'Lettisch',
  lt: 'Litauisch',
  mt: 'Maltesisch',
  pl: 'Polnisch',
  pt: 'Portugiesisch',
  ro: 'Rumänisch',
  ru: 'Russisch',
  sr: 'Serbisch',
  sk: 'Slowakisch',
  sl: 'Slowenisch',
  es: 'Spanisch',
  sv: 'Schwedisch',
  tr: 'Türkisch',
  uk: 'Ukrainisch',
  validation: 'Validierung',
  permission: 'Berechtigung',
  exist: 'Existieren',
  missingParams: 'Fehlende erforderliche Ressourcen',
  fileLoaded: 'Datei geladen',
  scriptEngine: 'Skript-Engine',
  dataType: 'Datentyp',
  ruleType: 'Regeltyp',
  fields: 'Felder',
  fieldName: 'Feldname',
  required: 'Erforderlich',
  rules: 'Regeln',
  field: 'Feld',
  rule: 'Regel',
  validationRules: 'Validierungsregeln',
  logServerUrl: 'Protokollserver-URL',
  logs: 'Protokolle',
  apiKey: 'API-Schlüssel',
  listOfCodes: 'Liste der HTTP-Codes',
  logsServer: 'Protokollserver',
  debug: 'Debuggen',
  processSettingName: 'Einstellung',
  UPLOAD: 'Hochladen',
  DOWNLOAD: 'Herunterladen',
  descriptions: 'Beschreibungen',
  loadDirection: 'Laderichtung',
  encrypt: 'Verschlüsseln',
  restResponseContentTargetObject: 'REST-Antwortinhalt Zielobjekt',
  enableStepLog: 'Protokollierung',
  urlForAdmin: 'Gesundheitscheck-URL für Admin',
  urlForProcessingService: 'Gesundheitscheck-URL für den Verarbeitungsdienst',
  searchByModuleName: 'Nach Modulnamen suchen',
  description: 'Beschreibung',
  internalNote: 'Interne Notizen',
  basicAccessAuthentication: 'Grundlegende Authentifizierung zulassen',
  upDownload: 'Hochladen/Herunterladen',
  remoteId: 'Remote-ID',
  exception: 'Ausnahme',
  className: 'Klassenname',
  checksum: 'Prüfsumme',
  stepTypes: 'Schritttypen',
  plugin: 'Plugin',
  selectPlugin: 'Wählen Sie zuerst das Plugin aus',
  processLogs: 'Prozessprotokolle',
  openProcessLogs: 'Prozessprotokolle öffnen',
  accessKeyId: 'Zugriffsschlüssel-ID',
  region: 'Region',
  s3Bucket: 'S3-Bucket',
  secretKey: 'Geheimer Schlüssel',
  content: 'Inhalt',
  key: 'Schlüssel',
  path: 'Pfad',
  events: 'Ereignisse',
  backEnd: 'Backend',
  isCacheStepLogsEnabled: 'Cache-Speicher',
  isStepLogsEnabled: 'Protokollierung',
  enableCacheLog: 'Cache-Speicher',
  tos: 'Nutzungsbedingungen',
  privacyPolicy: 'Datenschutzrichtlinie',
  passphrase: 'Passphrase',
  queryConsole: 'Abfragekonsole',
  console: 'Konsole',
  affectedRows: 'Betroffene Zeilen',
  success: 'Erfolg',
  respondSent: 'Antwort gesendet',
  resultDetails: 'Ergebnisdetails',
  engine: 'Engine',
  isBodyScript: 'Body-Skript',
  groovy: 'Groovy',
  last15MinutesCount: 'Letzte 15 Minuten',
  last24HoursCount: 'Letzte 24 Stunden',
  last60MinutesCount: 'Letzte 60 Minuten',
  todayCount: 'Heute',
  executions: 'Ausführungen',
  eventStates: 'Ereignisstatus',
  childEventIdPath: 'Pfad der untergeordneten Ereignis-ID zum Zielobjekt',
  folder: 'Ordner',
  sizeInBytes: 'Größe in Bytes',
  readType: 'Lestyp',
  maxResults: 'Maximale Ergebnisse',
  recipient: 'Empfänger',
  sender: 'Absender',
  emailId: 'E-Mail-ID',
  locale: 'Gebietsschema',
  heartbeat: 'Herzschlag',
  user: 'Benutzer',
  selectUser: 'Benutzer auswählen*',
  overview: 'Übersicht',
  review: 'Bewertung',
  title: 'Titel',
  rating: 'Bewertung',
  reviews: 'Bewertungen',
  submitReview: 'Bewertung abgeben',
  metricServerUrl: 'Metrikserver-URL',
  visualisationServerUrl: 'Visualisierungsserver-URL',
  metricServer: 'Metrikserver',
  visualisationServer: 'Visualisierungsserver',
  internalJobs: 'Interne Aufgaben',
  started: 'Gestartet',
  hideChildren: 'Untergeordnete ausblenden',
  showChildren: 'Untergeordnete anzeigen',
  diagrams: 'Diagramme',
  genericSubType: 'Mitgliedstyp',
  userId: 'Benutzer-ID',
  filters: 'Filter',
  source: 'Quellobjekt',
  startDate: 'Start',
  endDate: 'Ende',
  startTime: 'Startzeit',
  hoursPerMonth: 'Maximale Stunden pro Monat',
  amountOfHours: 'Stundenmenge',
  hourFormat: 'Stundenformat',
  duration: 'Dauer',
  amount: 'Menge',
  decimals: 'Dezimalstellen',

  project: 'Projekt',
  selectProject: 'Projekt auswählen',
  createProject: 'Projekt erstellen',
  editProject: 'Projekt bearbeiten',
  noUsersInProject: 'Das Projekt hat keine Benutzer',
  noTasksInProject: 'Das Projekt hat keine Aufgaben',
  youHaveNoAdminProjects: 'Es gibt keine Projekte, bei denen Sie Administratorrechte haben.',
  noProjectsNoBookings: 'Du bist nicht einer der Benutzer eines Projekts und kannst keine Buchungen sehen.',
  isActive: 'Aktiv',
  onlyActiveProjects: 'Nur aktive Projekte',
  P14D: 'Zahlbar in 14 Tagen',
  P14D_2P_30D: 'Zahlbar innerhalb von 14 Kalendertagen (2% Skonto) oder 30 Kalendertagen ohne Abzug',
  P30D: 'Zahlbar innerhalb von 30 Tagen ohne Abzug',
  P90D: 'Zahlbar innerhalb von 90 Tagen ohne Abzug',
  DOR: 'Zahlbar bei Erhalt',

  company: 'Firma',
  createCompany: 'Firma Erstellen',
  editCompany: 'Firma Bearbeiten',
  selectCompany: 'Firma auswählen',
  pleaseSelectCompany: 'Bitte eine Firma auswählen',
  dontHaveCompanies: 'Es gibt noch keine Firmen. Bitte eine Firma erstellen auf der Firmenseite.',
  noContactsInCompany: 'Es gibt noch keine Kontakte. Bitte eine Kontaktperson erstellen auf der Firmaseite.',
  worklog: 'Arbeitsprotokoll',
  newEntry: 'Neuer Eintrag',
  comment: 'Kommentar',
  cantWorklogBeforeCompanyCreate: 'Sie müssen zuerst die Firma speichern bevor Arbeitsprotokoll hinzufügen.',

  costCenters: 'Cost Centers',
  costCenterBillable: 'Abrechnungsfähig Cost Center',
  costCenterNonBillable: 'Nicht abrechnungsfähig Cost Center',
  isBillable: 'Abrechnungsfähig',
  selectCostCenter: 'Cost Center auswählen',

  person: 'Person',
  addPerson: 'Person hinzufügen',
  fullname: 'Vollname',
  selectContacts: 'Kontakte auswählen',
  selectContact: 'Kontakt auswählen',
  cantChange: 'Sie können nicht das ändern',

  address: 'Adresse',
  addAddress: 'Adresse hinzufügen',
  noAddressesForPerson: `Die Firma hat keine Adressen.
  Sie müssen mindestens eine Adresse hinzufügen und das Formular speichern, um eine Person hinzuzufügen`,
  companyAddressRequired: 'Mindestends eine Firmenadresse erforderlich',
  cantDeleteAddress: 'Sie können diese Adresse nicht löschen. Es gibt Personen mit dieser Adresse.',
  addressLine: 'Adresszeile',
  isBillingAddress: 'Rechnungsadresse',

  endTime: 'Endzeit',
  addUsers: 'Benutzer hinzufügen',
  till: 'Bis',
  reject: 'Ablehnen',
  statusChange: 'Statusänderung',
  statusChanged: 'Status geändert',
  contact: 'Kontakt',
  companyName: 'Firmenname',
  contactPerson: 'Kontaktperson',
  contactEmail: 'E-Mail-Kontakt',
  addressLine1: 'Adresszeile 1',
  addressLine2: 'Adresszeile 2',
  zipCode: 'Postleitzahl',
  city: 'Stadt',
  country: 'Land',
  registrationNumber: 'Registriernummer',
  uid: 'UID',
  vatRate: 'Mehrwertsteuersatz',
  reverseCharge: 'Umkehr der Steuerschuld',
  currency: 'Währung',
  userRate: 'Nutzersatz',
  clientRate: 'Kundensatz',
  report: 'Bericht',
  reports: 'Berichte',
  monthlyReport: 'Monatsbericht',
  total_duration: 'Gesamtdauer',
  total_resource_cost: 'Gesamtkosten für Ressourcen',
  total_client_cost: 'Gesamtkosten für Kunden',
  totalsByResource: 'Gesamtsummen nach Ressource',
  projectTypeHelp: 'Hilfe zum Projekttyp',
  byResource: 'Nach Ressource',
  yearly: 'Jährlich',
  projectsReport: 'Projektbericht',
  totalProjectAmount: 'Projektgesamtmengen',
  totalProjectRoundedAmount: 'Projektgesamtmengen gerundet',
  totalProjectInvoicedAmount: 'Projektgesamtkosten',
  entryByTimeframe: 'Eintrag nach Zeitrahmen',
  profit: 'Gewinn',
  client_rate: 'Kundensatz',
  rates: 'Satz',
  create: 'Erstellen',
  invoice_number: 'Rechnungsnummer',
  is_cancelation: 'Ist Stornierung',
  addTasks: 'Hinzufügen',
  task: 'Aufgabe',
  tasks: 'Aufgaben',
  costCenter: 'Kostenzentrum',
  timesheetOnInvoice: 'Zeiterfassung auf Rechnung',
  terms_of_payment: 'Zahlungsbedingungen',
  dateFrom: 'Datum von',
  dateTill: 'Datum bis',
  invoice: 'Rechnung',
  invoices: 'Rechnungen',
  noInvoicesData: 'Keine Rechnungen verfügbar',
  areYouSureCancelInvoice:
    'Sind Sie sicher, dass Sie diese Rechnung stornieren möchten?',
  cancelInvoice: 'Rechnung stornieren',
  rounding: 'Rundung',
  roundingType: 'Rundungtyp',
  is_canceled: 'Ist storniert',
  reference: 'Referenz',
  signIn: 'Einloggen',
  loading: 'Wird geladen...',
  next: 'Weiter',
  prev: 'Zurück',
  selectRole: 'Rolle auswählen*',
  selectAddress: 'Adresse auswählen',
  submit: 'Senden',
  titlePlaceHolder: 'Titelname',
  hourPlaceHolder: 'Maximal 4096 Stunden',
  addressPlaceHolder: 'Straße',
  zipCodePlaceHolder: 'PLZ',
  precise: 'Präzise',
  select: 'Auswählen',
  home: 'Start',
  projects: 'Projekte',

  bookings: 'Buchungen',
  booking: 'Buchung',
  createBooking: 'Buchung erstellen',
  editBooking: 'Buchung bearbeiten',

  configuration: 'Konfiguration',
  showing: 'Anzeige von',
  toEntry: 'bis',
  ofEntry: 'von',
  entries: 'Einträgen',
  selectHeader: 'AUSWÄHLEN',
  nameHeader: 'NAME',
  userHeader: 'BENUTZER',
  projectHeader: 'PROJEKT',
  dateFromHeader: 'DATUM VON',
  dateTillHeader: 'DATUM BIS',
  durationHeader: 'DAUER',
  taskHeader: 'AUFGABE',
  descriptionHeader: 'BESCHREIBUNG',
  totalDurationHeader: 'GESAMTDAUER',
  totalAmountHeader: 'GESAMTMENGE',
  totalResourceCostHeader: 'RESSOURCENKOSTEN',
  totalResourceClientCostHeader: 'KUNDENKOSTEN',
  monthHeader: 'MONAT',
  invoiceNumberHeader: 'RECHNUNGSNUMMER',
  isCancelationHeader: 'IST STORNIERUNG',
  isCanceledHeader: 'IST STORNIERT',
  clientRateHeader: 'KUNDENSATZ',
  totalClientCostHeader: 'GESAMTKOSTEN FÜR DEN KUNDEN',
  profitHeader: 'GEWINN',
  totalsProvisionals: 'Gesamtsummen (Vorläufige)',
  noUsersWithRates: 'Keine Benutzer mit Satz. Sie können sie hinzufügen in Berechtigungen.'
};
