import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Stack,
  Text,
  Container,
  SimpleGrid,
  Card,
  Spinner,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { WorklogEntry, useCompanyStore } from 'contexts/globalStoreCompanies';
import CompanyWorklogForm from './CompanyWorklogForm';
import useUserDataRolesStore from 'contexts/authStore';
import { checkIfUserCompanyCreator } from 'utils/roleHelpers';
import { useLocation } from 'react-router-dom';

const CompanyWorklog = ({ viewOnly }: { viewOnly: boolean }) => {
  const location = useLocation();
  const isCreatePage = useMemo(() => location.pathname.includes('create'), [location]);

  const companyId = useCompanyStore((state) => state.company.id);
  const fetchCompanyWorklog = useCompanyStore((state) => state.fetchCompanyWorklog);
  const userRoles = useUserDataRolesStore((state) => state.userRoles);
  const isUserCompanyCreator = useMemo(() => checkIfUserCompanyCreator(userRoles), [userRoles]);
  const [isLoading, setIsLoading] = useState(false);

  const [companyWorklog, setCompanyWorklog] = useState<WorklogEntry[]>([]);

  const handleWorklogLoading = useCallback(() => {
    if (isUserCompanyCreator) {
      if (isCreatePage) {
        setCompanyWorklog([]);
      } else {
        setIsLoading(true);
        fetchCompanyWorklog(companyId).then((worklog) => {
          setCompanyWorklog(worklog);
        });
        setIsLoading(false);
      }
    }
  }, [companyId, fetchCompanyWorklog, isCreatePage, isUserCompanyCreator]);

  useEffect(() => {
    handleWorklogLoading();
    return () => { setCompanyWorklog([]) };
  }, [setCompanyWorklog, handleWorklogLoading]);

  const getEntry = useCallback((entry: WorklogEntry) => (
    <>
      {entry?.created_at ? (
        <Text fontSize={'16px'} pb="8px" fontWeight="bold">
          {new Date(entry?.created_at).toLocaleString('de-AT') || ''} {entry?.status ? ' - ' : ''} {t(entry?.status, { ns: 'status' })}
        </Text>
      ) : ''}
      {entry?.comment ? <Text>{entry.comment}</Text > : ''}
    </>
  ), []);

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }}>
      {isUserCompanyCreator ? <CompanyWorklogForm viewOnly={viewOnly} handleWorklogLoading={handleWorklogLoading} /> : null}
      <Stack direction="column" gap="3px" gridColumn={{ base: '1', md: '2/2' }}>
        {isLoading ? <Spinner /> :
          companyWorklog?.length && !isLoading ? companyWorklog.map((entry: WorklogEntry, index: number) => (
            <Container key={index + 5000} maxW={'100%'} p={0}>
              <Card p={4} w={'100%'} whiteSpace={'pre-line'}>
                {getEntry(entry)}
              </Card>
            </Container>
          )) : null}
      </Stack>
    </SimpleGrid >
  );
};

export default CompanyWorklog;
