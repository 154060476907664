export const errorsDe = {
  credentialStatusUpdate: 'Failed to update credential status',
  credentialUpdate: 'Failed to update credential',
  credentialCreate: 'Failed to create credential',
  cronStatusUpdate: 'Failed to update cron status',
  cronUpdate: 'Failed to update cron',
  cronCreate: 'Failed to create cron',
  restStatusUpdate: 'Failed to update rest status',
  restUpdate: 'Failed to update rest',
  restCreate: 'Failed to create rest',
  dateWrong: 'Date From cannot be after date to',
  processStatusUpdate: 'Failed to update process status',
  processUpdate: 'Failed to update process',
  processCreate: 'Failed to create process',
  missingUsername: 'Please enter your username.',
  missingPassword: 'Please enter your password.',
  somethingWentWrong: 'Etwas ist schief gelaufen! :(',
  userStatusUpdate: 'Failed to update user status',
  userUpdate: 'Failed to update user',
  userCreate: 'Failed to create user',
  roleUpdate: 'Failed to update role',
  roleCreate: 'Failed to create role',
  test: 'Test Unsuccessful',
  templateUpdate: 'Failed to update template',
  templateCreate: 'Failed to create template',
  translationUpdate: 'Failed to update translation',
  translationCreate: 'Failed to create translation',
  translationDelete: 'Failed to delete translation',
  resendPassword: 'Failed to send password reset instructions',
  settingStatusUpdate: 'Failed to update setting status',
  settingUpdate: 'Failed to update setting',
  settingCreate: 'Failed to create setting',
  zeroSteps: 'Please add at least one step in order to be able to save a process',
  noPaste: 'No step item in memory',
  wrongPaste: 'Item in memory is of the wrong type',
  tokenMissing: 'Token Missing',
  passwordNotSame: 'Passwords does not match',
  formatEmail: 'Not valid Email format',
  required: 'Required',
  cronExecute: 'Cron execute error',
  check: 'Check Error',
  checkIsExists: 'Combination of Method and Url already exists with trigger ID:',
  duplicatedKeys: 'Duplicated key detected',
  moduleUpdate: 'Failed to update module',
  moduleCreate: 'Failed to create module',
  readOnly: 'You are accessing this resource in read only mode',
  editRoleCreate: 'Resource should have at least one Edit Role assigned',
  moduleExport: 'Failed to export module',
  moduleImport: 'Failed to import module',
  moduleValidate: 'Failed to validate module',
  noFeSettings: 'No active frontend settings found',
  multipleFeSettings: 'Multiple frontend settings found, please fix configuration',
  ttlToBig: 'TTL is more than 10 years into the future',
  validationUpdate: 'Failed to update validation rule',
  validationCreate: 'Failed to create validation rule',
  noLogs: 'No step logs found for Event ID',
  variableJavaWrong: 'Name is not by Java Variable Naming Convention',
  valueNotAllowed: 'Value Not Allowed',
  apiKeyGeneration: 'Error generating ApiKey',
  moduleUpload: 'Failed to upload module',
  moduleDownload: 'Failed to download module',
  moduleInstall: 'Failed to install module',
  pluginStatusUpdate: 'Failed to update plugin status',
  pluginUpdate: 'Failed to update plugin',
  pluginCreate: 'Failed to create plugin',
  pluginDelete: 'Failed to delete plugin',
  pluginImport: 'Failed to import plugin',
  nameInUse: 'Process name already in use',
  noCache: 'No cache for this step log',
  duplicatedVars: 'Duplicated variable detected',
  execute: 'Script execution Unsuccessful',
  multipleCredentials: 'Multiple matching credentials found!',
  noEvents: 'No events available',
  triggerRestUrlValidation: 'The following field(s) are not defined in the path variable rule',
  triggerRestPathVarValidation: 'The following field(s) are defined in the path variable rule, but not in the URL',
  mustBeTwoLong: 'Must be two characters',
  errorSwagger: 'Error downloading Swagger',
  multipleSettings: 'Multiple matching settings found!',
  wrongSearchParam: 'Incorrect value',
  slackMessage: 'Message too long',
  userCannotChangeStatus: 'User cannot change status of some selected bookings',
  fetchErrorProject: 'Fehler beim Abrufen des Projekts',
  fetchErrorCompany: 'Fehler beim Abrufen der Firma',
  deleteBookingFailed: 'Fehler beim Löschen der Buchung',
  errorRejectingBooking: 'Fehler beim Ablehnen der Buchung',
  errorChangingStatus: 'Fehler beim Ändern des Status',
  deleteCostCenterFailed: 'Fehler beim Ändern des Cost Centers',
  startDateBeforeDuration: 'Sie müssen einen Startdatum vor der Duration eintragen',
  userError: 'Fehler beim Ändern des Users',
  taskError: 'Fehler beim Hinzufügen der Aufgabe',
  costCenterError: 'Fehler beim Cost Center',
  invoiceError: 'Fehler beim Rechnung',
  detailsError: 'Fehler beim Details',
  addressError: 'Fehler beim Adresse',
  peopleError: 'Fehler beim Ändern der Person',
}
