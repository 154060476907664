import { Api } from 'utils/api';
import { create } from 'zustand';
import { produce } from 'immer';

const api = new Api();

export interface UserDataStore {
  userRoles: UserRoles[];
  userData: UserData;
  fakturaFetchUserData: () => Promise<void>;
  checkStaticHash: () => void;
}

export interface UserData {
  createdOn: string;
  email: string;
  id: number;
  modifiedOn: string;
  name: string;
  status: string;
}

export interface UserRoles {
  createdOn: string;
  id: number;
  isAssignableToUser: boolean;
  isSystem: boolean;
  modifiedOn: string;
  name: string;
}

const useUserDataRolesStore = create<UserDataStore>()(
    (set, get) => ({
      userRoles: [],
      userData: {} as UserData,
      checkStaticHash() {
        // fetch(process.env.NODE_ENV === 'development' ? '/version.txt' : '/static/version.txt')
        //   .then((r) => r.json())
        //   .then((staticHash) => {
        //
        //     if (staticHash) {
        //       if (!localStorage.postosFrontendVersion) {
        //         localStorage.postosFrontendVersion = JSON.stringify(staticHash)
        //         // @ts-ignore
        //         window.location.reload(true)
        //       } else if (Number(localStorage.postosFrontendVersion) !== staticHash) {
        //         localStorage.postosFrontendVersion = JSON.stringify(staticHash)
        //         // @ts-ignore
        //         window.location.reload(true)
        //       }
        //     }
        //   })
        //   .catch(() => {
        //     if (localStorage.postosFrontendVersion !== 'null') {
        //       localStorage.postosFrontendVersion = 'null'
        //       // @ts-ignore
        //       window.location.reload(true)
        //     } else {
        //       localStorage.postosFrontendVersion = 'null'
        //     }
        //   })
        fetch('/api/actuator/info')
          .then((r) => r.json())
          .then((result) => {
            if (result && result.git) {
              if (!localStorage.postosBackendVersion) {
                localStorage.postosBackendVersion = JSON.stringify(result.git)
                // @ts-ignore
                window.location.reload(true)
              } else if (localStorage.postosBackendVersion !== JSON.stringify(result.git)) {
                localStorage.postosBackendVersion = JSON.stringify(result.git)
                // @ts-ignore
                window.location.reload(true)
              }
            }
          })
          .catch(() => {
            console.log('Err')
          })
      },
      fakturaFetchUserData: async () => {
        try {
          const response: any = await api.faktura.fakturaWhoAmI();
          const userDataObj = response.data.data.body.userdata;

          set(
            produce((state) => {
              state.userData = userDataObj;
            }),
          );

          const userRoles = response.data.data.body.roles;
          if (userRoles && Array.isArray(userRoles)) {
            const mappedUserRoles: UserRoles[] = userRoles.map(
              (userRole: any) => ({
                createdOn: userRole.createdOn,
                id: userRole.id,
                isAssignableToUser: userRole.isAssignableToUser,
                isSystem: userRole.isSystem,
                modifiedOn: userRole.modifiedOn,
                name: userRole.name,
              }),
            );

            set(
              produce((state) => {
                state.userRoles = mappedUserRoles;
              }),
            );
          } else {
            set(
              produce((state) => {
                state.userRoles = [];
              }),
            );
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    }),
);

export default useUserDataRolesStore;
