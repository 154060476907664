import { Api, PostosInvoicesBODYValidationRule } from 'utils/postos-api';
import { create } from 'zustand';
import { produce } from 'immer';
import { UseToastOptions } from '@chakra-ui/react';
import { t } from 'i18next';
import { PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { PaginationMeta, paginationMetaInitialState } from './globalStore';

const api = new Api();

interface ToastObj {
  type: UseToastOptions['status'];
  message: string;
  navigateId?: number | null;
}


interface InvoicesStore {
  invoiceList: InvoiceList[];
  invoiceListMeta: PaginationMeta;
  invoiceCreate: InvoiceCreate[];
  invoiceCreateMeta: PaginationMeta;
  invoiceCurrency: Currency[];
  totalClientCost: number;
  totalDurationSum: number;
  totalResourceCostSum: number;
  totalProfitSum: number;
  fetchInvoiceList: (
    from: any,
    till: any,
    active?: boolean,
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<ToastObj>;
  fetchInvoiceCreate: (
    from: any,
    till: any,
    projectId: any,
    invoiceType: any,
    chosenBookingIds?: number[],
    active?: boolean,
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<ToastObj>;
  getTimesheet: (
    from: any,
    till: any,
    projectId: any,
    invoiceType: any,
    active?: boolean,
    chosenBookingIds?: number[],
  ) => Promise<ToastObj>;
  resetInvoiceList: () => void;
  resetInvoiceCreate: () => void;
  resetTotals: () => void;
  downloadPDF: (pdf: any) => void;
  invoiceListTimesheetPDF: (invoiceId: number) => Promise<ToastObj>;
  invoiceListInvoicePDF: (invoiceId: number) => Promise<ToastObj>;
  invoiceCancel: (
    invoiceId: number,
    invoiceType: any,
    from: any,
    till: any,
  ) => Promise<ToastObj>;
  changeInvoiceStatus: (invoiceId: number, status: string) => Promise<ToastObj>;
  isInitDone: boolean;
  set: (fn: any) => void;
}

interface InvoiceList {
  id: number;
  date: string;
  projectId: number;
  status: string;
  dateCreated: string;
  invoiceNumber: number;
  isCancelation: number;
}

interface InvoiceCreate {
  ids: string;
  profit: number;
  taskId: number;
  userId: number;
  clientRate: number;
  totalClientCost: number;
  totalDuration: number;
  totalResourceCost: number;
}

interface Currency {
  id: number;
  currency: string;
}

export const useInvoicesStore = create<InvoicesStore>((set, get) => ({
  set: (fn: any) => set(produce(fn)),
  totalClientCost: 0,
  totalDurationSum: 0,
  totalResourceCostSum: 0,
  totalProfitSum: 0,
  isInitDone: false,
  invoiceCurrency: [],
  invoiceList: [],
  invoiceListMeta: paginationMetaInitialState,
  invoiceCreate: [],
  invoiceCreateMeta: paginationMetaInitialState,
  downloadPDF: (pdf: any) => {
    const binaryString = window.atob(pdf);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob object from the Uint8Array buffer
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Use the URL to download the PDF file
    const link = document.createElement('a');

    link.href = url;
    link.download = 'example.pdf';
    link.click();

    // Clean up by revoking the URL object
    URL.revokeObjectURL(url);
  },
  resetTotals: () => {
    set({
      totalClientCost: 0,
      totalDurationSum: 0,
      totalResourceCostSum: 0,
      totalProfitSum: 0,
    });
  },
  resetInvoiceCreate: () => {
    set({
      invoiceCreate: [],
    });
  },
  resetInvoiceList: () => {
    set({
      invoiceList: [],
    });
  },
  invoiceListTimesheetPDF: async (invoiceId: number) => {
    try {
      const response: any = await api.postos.postosInvoicesTimesheets({
        invoiceId: invoiceId,
      });
      if (response.data.data.body.pdf) {
        set((state) => {
          state.downloadPDF(response.data.data.body.pdf);

          return state;
        });
      }
      return {
        type: 'success',
        message: t("downloadTimesheet", {ns: ["success"]}),
      };
    } catch (error) {
      console.log(error);
      return {
        type: 'error',
        // @ts-ignore
        message: t("errorDownloadTimesheet", {ns: ["success"]}) || error.response?.data?.data?.body?.message,
      };
    }
  },
  invoiceListInvoicePDF: async (invoiceId: number) => {
    try {
      const response: any = await api.postos.postosInvoicePdf({
        invoiceId: invoiceId,
      });
      if (response.data.data.body.pdf) {
        set((state) => {
          state.downloadPDF(response.data.data.body.pdf);

          return state;
        });
      }
      return {
        type: 'success',
        message: t("downloadInvoice", {ns: ["success"]}),
      };
    } catch (error) {
      console.log(error);
      return {
        type: 'error',
        // @ts-ignore
        message: t("errorDownloadInvoice", {ns: ["success"]}) || error.response?.data?.data?.body?.message,
      };
    }
  },
  invoiceCancel: async (
    invoiceId: number,
    invoiceType: any,
    from?: any,
    till?: any,
  ) => {
    try {
      const params: any = {
        invoiceId: invoiceId,
        invoiceType: invoiceType,
      };

      const response: any = await api.postos.postosInvoiceCancel(params);
      if (response.data.data.body.pdf) {
        set((state) => {
          state.downloadPDF(response.data.data.body.pdf);

          if(invoiceType === "create"){
            get().fetchInvoiceList(from, till);
          }

          return state;
        });


        if(invoiceType === "create"){
          return {
            type: 'success',
            message: t("cancelSuccess", {ns: ["success"]}),
          };
        } else {
          return {
            type: 'success',
            message:  t("downloadCancellationPreview", {ns: ["success"]}),
          };
        }


      }
    } catch (error) {
      console.log(error);
      return {
        type: 'error',
        // @ts-ignore
        message:  t("invoiceCancellation", {ns: ["success"]}) || error.response?.data?.data?.body?.message,
      };
    }
  },
  fetchInvoiceList: async (
    from: any,
    till: any,
    active?: boolean,
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc'
  ) => {
    try {
      if (orderField === 'invoiceNumber') orderField = 'invoice_number'; // Quick fix, needs some scalable solution
      if (orderField === 'isCancelation') orderField = 'is_cancelation';

      const params: any = {
        dateFrom: new Date(from).toISOString(),
        dateTill: new Date(till).toISOString(),
        page,
        size,
        orderField,
        orderDirection,
      };
      if (active) params.active = active;

      const response: any = await api.postos.postosInvoicesList(params);
      const invoiceList = response.data?.data?.body?.invoices?.items || [];
      const mappedInvoiceList: InvoiceList[] = invoiceList.map(
        (invoice: {id: string} & PostosInvoicesBODYValidationRule) => ({
          id: invoice.id,
          date: invoice.date,
          projectId: invoice.project_id,
          status: invoice.status,
          dateCreated: invoice.date_created,
          invoiceNumber: invoice.invoice_number,
          isCancelation: invoice.is_cancelation,
        }),
      );

      set(
        produce((state) => {
          state.invoiceList = mappedInvoiceList;
          state.invoiceListMeta = response.data?.data?.body?.invoices?.meta 
          || { totalItems: mappedInvoiceList.length, totalPages: 1 };
        }),
      );
      return {
        type: 'success',
        message:  t("invoiceListFetched", {ns: ["success"]}),
      };
    } catch (error) {
      console.log(error);
      return {
        type: 'error',
        // @ts-ignore
        message:  t("errorFetchingInvoiceList", {ns: ["success"]})|| error.response?.data?.data?.body?.message,
      };
    }
  },
  getTimesheet: async (
    from: any,
    till: any,
    projectId: number,
    invoiceType: any,
    active?: boolean,
    chosenBookingIds?: number[],
  ) => {
    try {
      const params: any = {
        dateFrom: new Date(from).toISOString(),
        dateTill: new Date(till).toISOString(),
        projectId: Number(projectId),
        invoiceType,
        chosenBookingIds,
      };
      if (active) params.active = active;

      const response: any = await api.postos.postosTimesheetsData(params);
      if (response.data.data.body.pdf) {
        set((state) => {
          state.downloadPDF(response.data.data.body.pdf);

          return state;
        });
      }
      return {
        type: 'success',
        message: t("downloadTimesheet", {ns: ["success"]}),
      };
    } catch (error: any) {
      console.log(error);
      return {
        type: 'error',
        message: t("errorDownloadTimesheet", {ns: ["success"]}) || error.response?.data?.data?.body?.message,
      };
    }
  },
  fetchInvoiceCreate: async (
    from: any,
    till: any,
    projectId: number,
    invoiceType: any,
    chosenBookingIds?: number[],
    active?: boolean,
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc'
  ) => {
    try {
      const params: any = {
        dateFrom: new Date(from).toISOString(),
        dateTill: new Date(till).toISOString(),
        projectId: Number(projectId),
        invoiceType,
        chosenBookingIds,
        page,
        size,
        orderField,
        orderDirection,
      };
      if (active) params.active = active;

      const response: any = await api.postos.postosInvoiceData(params);
      const invoiceCreate = response.data?.data?.body?.invoiceItems || [];
      const project = response.data?.data?.body?.project || {};
      if (response.data.data.body.pdf) {
        set((state) => {
          state.downloadPDF(response.data.data.body.pdf);

          return state;
        });
      }

      if (invoiceType !== 'preview') {
      const mappedInvoiceCreate: InvoiceCreate[] = invoiceCreate.map(
        (invoices: any) => ({
          ids: invoices.ids,
          profit: invoices.profit,
          taskId: invoices.task_id,
          userId: invoices.user_id,
          clientRate: invoices.client_rate,
          totalDuration: invoices.total_duration,
          totalClientCost: invoices.total_client_cost,
          totalResourceCost: invoices.total_resource_cost,
        }),
      );
      const totalClientCost: number = mappedInvoiceCreate.reduce(
        (accumulator, currentInvoice) =>
          accumulator + currentInvoice.totalClientCost,
        0,
      );

      const totalDurationSum: number = mappedInvoiceCreate.reduce(
        (accumulator, currentInvoice) =>
          accumulator + currentInvoice.totalDuration,
        0,
      );

      const totalResourceCostSum: number = mappedInvoiceCreate.reduce(
        (accumulator, currentInvoice) =>
          accumulator + currentInvoice.totalResourceCost,
        0,
      );

      const totalProfitSum: number = mappedInvoiceCreate.reduce(
        (accumulator, currentInvoice) => accumulator + currentInvoice.profit,
        0,
      );

      set(
        produce((state) => {
          state.totalClientCost = totalClientCost;
          state.totalDurationSum = totalDurationSum;
          state.totalResourceCostSum = totalResourceCostSum;
          state.totalProfitSum = totalProfitSum;
          state.invoiceCreate = mappedInvoiceCreate;
          state.invoiceCurrency = [
            {
              id: project.id,
              currency: project.currency,
            },
          ];
          state.invoiceCreateMeta = response.data?.data?.body?.meta 
        }),
      );
    }
      return {
        type: 'success',
        message:   t("invoiceListFetched", {ns: ["success"]}),
      };
    } catch (error: any) {
      console.log(error);
      return {
        type: 'error',
        message: t("errorFetchingInvoiceList", {ns: ["success"]}) || error.response?.data?.data?.body?.message,
      };
    }
  },
  changeInvoiceStatus: async (invoiceId: number, status: string) => {
    try {
      await api.postos.postosInvoiceStatusChange({id: invoiceId, status});
      
      return {
        type: 'success',
        message: t("statusChanged", {ns: ["labels"]}),
      };
    } catch (error: any) {
      console.log(error);
      return {
        type: 'error',
        message: t("somethingWentWrong", {ns: ["errors"]}) || error.response?.data?.data?.body?.message,
      };
    }
  },
}));
