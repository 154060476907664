const auth = {
  userDataPostos: {
    loggedInStatus: false,
    token: '',
  },

  logout() {
    localStorage.removeItem('userDataPostos');
    localStorage.removeItem('preFillDataPostos');
    localStorage.removeItem('allUsersPostos');
    localStorage.removeItem('userDataStore');
    localStorage.removeItem('tables');
    this.userDataPostos.loggedInStatus = false;
    this.userDataPostos.token = '';
    const currentRoute = window.location.pathname;
    if (currentRoute !== '/auth/login')
      window.open(`/auth/login?redirect=${currentRoute}`, '_self'); // Redirect directly to the login page
  },

  // Check if userDataPostos exists
  isLoggedIn() {
    const id = localStorage.userDataPostos
      ? JSON.parse(localStorage.userDataPostos).token
      : false;

    this.userDataPostos.loggedInStatus = !!id;

    return this.userDataPostos.loggedInStatus;
  },

  getToken() {
    if (this.userDataPostos.token) return this.userDataPostos.token;

    const id = localStorage.userDataPostos
      ? JSON.parse(localStorage.userDataPostos).token
      : false;

    if (id) return id;

    return false;
  },
};
export default auth;
