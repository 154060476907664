import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { format } from 'date-fns';
import {
  IconButton,
  SimpleGrid,
  Spacer,
  Spinner,
  Tooltip,
  useToast,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Icon,
  Stack,
  Input,
} from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import FakturaTable from 'components/tables/FakturaTable';
import { CheckCircleIcon, CheckIcon, CloseIcon, DeleteIcon, EditIcon, EmailIcon } from '@chakra-ui/icons';
import { RepeatIcon } from '@chakra-ui/icons';
import InvoiceStatusBadge from './InvoiceStatusBadge';

import { useProjectStore } from 'contexts/globalStoreProjects';
import useLoaderStore from 'contexts/globalStore';
import { useBookingStore } from 'contexts/globalStoreTimeBookings';
import { useInvoicesStore } from 'contexts/globalStoreInvoices';
import { tableStorage } from 'contexts/tableStorage';
import { getMonthRangeFromDateValue, zonedEndOfMonth } from 'utils/dateHelpers';
import { checkIsUserAdminOnProject, checkIsUserInvoiceApproverOnProject } from 'utils/roleHelpers';
import useUserDataRolesStore from 'contexts/authStore';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = {
  select: string;
  id: number;
  date: string;
  invoiceNumber: number;
  status: string;
  isCancelation: boolean;
  isPayed: boolean;
  projectId: number;
  action: string;
};

export default function SearchTableListInvoice({
  newInvoiceCreatedTrigger, setNewInvoiceCreatedTrigger
}: {
  newInvoiceCreatedTrigger: boolean,
  setNewInvoiceCreatedTrigger: (value: boolean) => void
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const isFirefox = useMemo(() => navigator.userAgent.toLowerCase().includes('firefox'), []);

  const toast = useToast();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const formatDate = useBookingStore((state) => state.formatDate);

  const isInitDone = useInvoicesStore((state) => state.isInitDone);

  const { fakturaInvoiceList } = useInvoicesStore(
    (state: any) => ({ fakturaInvoiceList: state.invoiceList }),
  );

  const { invoiceListMeta } = useInvoicesStore(
    (state: any) => ({ invoiceListMeta: state.invoiceListMeta }),
  );

  const { fakturaProjects } = useProjectStore(
    (state: any) => ({ fakturaProjects: state.projects }),
  );

  const allProjectUsers = useProjectStore((state) => state.allProjectUsers);

  const userData = useUserDataRolesStore((state) => state.userData);

  const [activeProjectsOnly, setActiveProjectsOnly] = useState<boolean>(true);

  // const handleActiveProjectsToggle = (value: boolean) => {
  //   const newActiveProjectsOnlyValue = value;
  //   setActiveProjectsOnly(newActiveProjectsOnlyValue);
  // };

  const handleStartDateChange = (e: any) => {
    const { startDay, endDay } = getMonthRangeFromDateValue(e.target.value);
    setStartDate(startDay);
    setEndDate(endDay);
    fetchInvoices(startDay, zonedEndOfMonth(new Date(endDay)), activeProjectsOnly);
    setData(fakturaInvoiceList);
  };

  const toISOWithoutTimezone = (d: any) => {
    let pad = (number: number) => (number < 10 ? '0' + number : number);

    return (
      d.getFullYear() +
      '-' +
      pad(d.getMonth() + 1) +
      '-' +
      pad(d.getDate()) +
      'T' +
      pad(d.getHours()) +
      ':' +
      pad(d.getMinutes()) +
      ':' +
      pad(d.getSeconds()) +
      '.' +
      (d.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
      'Z'
    );
  };

  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);
  const resetInvoiceList = useInvoicesStore((state) => state.resetInvoiceList);
  const fetchInvoices = useInvoicesStore((state) => state.fetchInvoiceList);
  const changeInvoiceStatus = useInvoicesStore((state) => state.changeInvoiceStatus);

  const createTimesheetPDF = useInvoicesStore(
    (state) => state.invoiceListTimesheetPDF,
  );
  const createInvoicePDF = useInvoicesStore(
    (state) => state.invoiceListInvoicePDF,
  );
  const createInvoiceCancelPDF = useInvoicesStore(
    (state) => state.invoiceCancel,
  );

  const beginningOfCurrentMonth = () => {
    let date = new Date(); // Current date
    date.setDate(1); // Set to the 1st of the current month
    date.setHours(0, 0, 0, 0); // Set time to the start of the day
    return toISOWithoutTimezone(date);
  };

  const endOfCurrentMonth = () => {
    let date = new Date(); // Current date
    date.setMonth(date.getMonth() + 1); // Add one month
    date.setDate(0); // Set to the last day of the previous month
    date.setHours(23, 59, 59, 999); // Set time to the end of the day
    return date.toISOString();
  };

  const [startDate, setStartDate] = useState(
    formatDate(beginningOfCurrentMonth(), 'date'),
  );

  const [endDate, setEndDate] = useState(
    formatDate(endOfCurrentMonth(), 'date'),
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const refreshData = useCallback(() => {
    setLoadingState(true);
    fetchInvoices(
      startDate,
      zonedEndOfMonth(new Date(endDate)),
      activeProjectsOnly,
      pagination.pageIndex + 1,
      pagination.pageSize,
      sorting[0]?.id || null,
      sorting[0]?.desc ? 'desc' : 'asc'
    )
      .then((res) => {
        setLoadingState(false);
        if (!res || res.type !== 'success') {
          toast({
            title: t('error', { ns: ['labels'] }),
            description: res.message,
            status: res.type,
          });
        }
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Faktura Invoices:', error);
        toast({
          title: t('error', { ns: ['labels'] }),
          description: error.message,
          status: error.type,
        });
      });
  }, [setLoadingState,
    fetchInvoices,
    startDate,
    endDate,
    activeProjectsOnly,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    toast]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && isInitDone) {
      if (startDate && endDate) {
        refreshData();
      } else {
        resetInvoiceList();
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [startDate, endDate, isInitDone, refreshData, resetInvoiceList]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaInvoiceList);
      setProjectData(fakturaProjects);
    }
    return () => {
      isCurrent = false;
    };
  }, [fakturaInvoiceList, fakturaProjects]);

  useEffect(() => {
    if (newInvoiceCreatedTrigger) {
      refreshData();
      setNewInvoiceCreatedTrigger(false);
    }
  }, [newInvoiceCreatedTrigger, refreshData, setNewInvoiceCreatedTrigger]);

  const [globalFilter, setGlobalFilter] = useState('');

  const columnHelper = createColumnHelper<RowObj>();

  const [data, setData] = useState(() => []);
  const [projectData, setProjectData] = useState(() => []);

  const columns = [

    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          id
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          #{info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('dateFrom', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const dateValue = new Date(info.getValue()).toLocaleDateString();
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {dateValue}
          </Text>
        );
      },
    }),
    columnHelper.accessor('invoiceNumber', {
      id: 'invoiceNumber',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('invoice_number', { ns: ['labels'] })}{' '}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} align="center" fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('projectId', {
      id: 'projectId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('project', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const projectId = info.row.original.projectId;
        const project = projectData.find((project) => project.id === projectId);
        if (project) {
          return (
            <Flex alignItems={'center'}>
              <Text
                color={textColor}
                key={projectId}
                fontSize="md"
                fontWeight="500"
              >
                {project.title}
              </Text>
              <Icon
                ml="5px"
                color={project.isActive ? 'green.500' : 'gray.500'}
                as={MdCircle}
                w="10px"
                h={'10px'}
              />
            </Flex>
          );
        } else {
          return null;
        }
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          status
        </Text>
      ),
      cell: (info) => (<InvoiceStatusBadge status={info.getValue()} />),
    }),
    columnHelper.accessor('isCancelation', {
      id: 'isCancelation',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('cancellation', { ns: ['actions'] })}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} align="center" fontSize="md" fontWeight="500">
          {info.getValue()
            ? t('yes', { ns: ['hints'] })
            : t('no', { ns: ['hints'] })}
        </Text>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="end"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          paddingEnd={4}
        >
          {t('actions', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => (
        <Stack direction="column" spacing="8px" align={'end'}>
          <Flex gap={1}>
            <Button
              size="sm"
              leftIcon={<CheckIcon />}
              cursor="pointer"
              colorScheme="teal"
              fontSize="xs"
              w={130}
              onClick={() => {
                createInvoicePDF(info.row.original.id)
                  .then((res) => {
                    setLoadingState(false);
                    toast({
                      title:
                        res.type === 'success'
                          ? t('success', { ns: ['labels'] })
                          : t('error', { ns: ['labels'] }),
                      description: res.message,
                      status: res.type,
                    });
                  })
                  .catch((error) => {
                    setLoadingState(false);
                    toast({
                      title: t('error', { ns: ['labels'] }),
                      description: error.message,
                      status: error.type,
                    });
                    console.error('Error creating invoice:', error);
                  });
              }}
            >
              {t('pdf', { ns: ['actions'] })}
            </Button>
            <Button
              size="sm"
              isDisabled={
                info.row.original.isCancelation === true ||
                info.row.original.status === 'DECLINED' ||
                !checkIsUserAdminOnProject(allProjectUsers, userData.id, info.row.original.projectId, true)
              }
              leftIcon={<CloseIcon />}
              cursor="pointer"
              colorScheme="yellow"
              w={130}
              fontSize="xs"
              onClick={() => {
                createInvoiceCancelPDF(
                  info.row.original.id,
                  'preview',
                  null,
                  null,
                )
                  .then((res) => {
                    setLoadingState(false);
                    toast({
                      title:
                        res.type === 'success'
                          ? t('success', { ns: ['labels'] })
                          : t('error', { ns: ['labels'] }),
                      description: res.message,
                      status: res.type,
                    });
                  })
                  .catch((error) => {
                    setLoadingState(false);
                    toast({
                      title: t('error', { ns: ['labels'] }),
                      description: error.message,
                      status: error.type,
                    });
                    console.error('Error creating invoice:', error);
                  });
              }}
            >
              {t('cancelPreview', { ns: ['actions'] })}
            </Button>
          </Flex>
          <Flex gap={1}>
            <Button
              size="sm"
              leftIcon={<EditIcon />}
              cursor="pointer"
              colorScheme="cyan"
              w={130}
              fontSize="xs"
              onClick={() => {
                createTimesheetPDF(info.row.original.id)
                  .then((res) => {
                    setLoadingState(false);
                    toast({
                      title:
                        res.type === 'success'
                          ? t('success', { ns: ['labels'] })
                          : t('error', { ns: ['labels'] }),
                      description: res.message,
                      status: res.type,
                    });
                  })
                  .catch((error) => {
                    setLoadingState(false);
                    toast({
                      title: t('error', { ns: ['labels'] }),
                      description: error.message,
                      status: error.type,
                    });
                    console.error('Error creating invoice:', error);
                  });
              }}
            >
              {t('timesheetPdf', { ns: ['actions'] })}
            </Button>
            <Button
              size="sm"
              isDisabled={
                info.row.original.isCancelation === true ||
                info.row.original.status === 'DECLINED' ||
                !checkIsUserAdminOnProject(allProjectUsers, userData.id, info.row.original.projectId, true)
              }
              leftIcon={<DeleteIcon />}
              cursor="pointer"
              colorScheme="red"
              w={130}
              fontSize="xs"
              onClick={() => {
                setLoadingState(true);
                createInvoiceCancelPDF(
                  info.row.original.id,
                  'create',
                  startDate,
                  endDate,
                )
                  .then((res) => {
                    setLoadingState(false);
                    toast({
                      title:
                        res.type === 'success'
                          ? t('success', { ns: ['labels'] })
                          : t('error', { ns: ['labels'] }),
                      description: res.message,
                      status: res.type,
                    });
                  })
                  .catch((error) => {
                    setLoadingState(false);
                    toast({
                      title: t('error', { ns: ['labels'] }),
                      description: error.message,
                      status: error.type,
                    });
                    console.error('Error creating invoice:', error);
                  });
              }}
            >
              {t('cancel', { ns: ['actions'] })}
            </Button>
          </Flex>
          <Flex gap={1}>
            <Button
              size="sm"
              leftIcon={<CheckCircleIcon />}
              cursor="pointer"
              colorScheme={'green'}
              fontSize="xs"
              w={130}
              onClick={async () => {
                await changeInvoiceStatus(info.row.original.id, 'APPROVED')
                refreshData()
              }}
              isDisabled={info.row.original.status !== 'CREATED'
                || info.row.original.isCancelation === true
                || info.row.original.status === 'DECLINED'
                || !checkIsUserInvoiceApproverOnProject(allProjectUsers, userData.id, info.row.original.projectId)
              }
            >
              {t('approve', { ns: ['actions'] })}
            </Button>
            <Button
              size="sm"
              leftIcon={<CloseIcon />}
              cursor="pointer"
              colorScheme={'green'}
              fontSize="xs"
              w={130}
              onClick={async () => {
                await changeInvoiceStatus(info.row.original.id, 'DECLINED')
                refreshData()
              }}
              isDisabled={(info.row.original.status !== 'CREATED'
                && info.row.original.status !== 'SENT')
                || info.row.original.isCancelation === true
                || !checkIsUserInvoiceApproverOnProject(allProjectUsers, userData.id, info.row.original.projectId)
              }
            >
              {t('decline', { ns: ['actions'] })}
            </Button>
          </Flex>
          <Flex gap={1}>
            <Button
              w={130}
              size="sm"
              leftIcon={<EmailIcon />}
              cursor="pointer"
              colorScheme={'green'}
              fontSize="xs"
              onClick={async () => {
                await changeInvoiceStatus(info.row.original.id, 'SENT')
                refreshData()
              }}
              isDisabled={info.row.original.status !== 'APPROVED'
                || !checkIsUserAdminOnProject(allProjectUsers, userData.id, info.row.original.projectId, true)
              }
            >
              {t('markAsSent', { ns: ['actions'] })}
            </Button>
            <Button
              w={130}
              size="sm"
              leftIcon={<CheckCircleIcon />}
              cursor="pointer"
              colorScheme={'green'}
              fontSize="xs"
              onClick={async () => {
                await changeInvoiceStatus(info.row.original.id, 'PAYED')
                refreshData()
              }}
              isDisabled={info.row.original.status !== 'SENT'
                || !checkIsUserAdminOnProject(allProjectUsers, userData.id, info.row.original.projectId, true)
              }
            >
              {t('markAsPayed', { ns: ['actions'] })}
            </Button>
          </Flex>
          <Flex gap={1}>
            <Button
              w={130}
              size="sm"
              leftIcon={<DeleteIcon />}
              cursor="pointer"
              colorScheme={'gray'}
              color={textColor}
              fontSize="xs"
              onClick={async () => {
                await changeInvoiceStatus(info.row.original.id, 'OBSOLETE')
                refreshData()
              }}
              isDisabled={info.row.original.status !== 'DECLINED'
                || !checkIsUserAdminOnProject(allProjectUsers, userData.id, info.row.original.projectId, true)
              }
            >
              {t('markAsObsolete', { ns: ['actions'] })}
            </Button>
          </Flex>
        </Stack>
      ),
    }),
  ];

  const tableName = useMemo(() => 'invoicesList', []);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      startDate: newStartDate,
      activeProjectsOnly: newActiveProjectsOnly,
      pageSize: newPageSize,
      sorting: newSorting
    } = tableStorage.fetch(tableName);

    if (newGlobalFilter) setGlobalFilter(newGlobalFilter);
    if (newActiveProjectsOnly) setActiveProjectsOnly(newActiveProjectsOnly);
    if (newPageSize) setPagination({ ...pagination, pageSize: newPageSize });
    if (newSorting) setSorting(newSorting)

    const selectedDateValue = newStartDate ?? format(new Date(), 'yyyy-MM-dd');
    const { startDay, endDay } = getMonthRangeFromDateValue(selectedDateValue);
    setStartDate(startDay);
    setEndDate(endDay);
    // Don't add 'pagination' below to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, {
      globalFilter,
      startDate,
      activeProjectsOnly,
      pageSize: pagination.pageSize,
      sorting
    })
  }, [activeProjectsOnly, globalFilter, pagination.pageSize, sorting, startDate, tableName])

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
    pageCount: invoiceListMeta.totalPages,
    rowCount: invoiceListMeta.totalItems,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'auto', lg: 'auto' }}>
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify={{ sm: 'flex-start', lg: 'space-between' }}
        w="100%"
        px="22px"
        mb="10px"
        minWidth="max-content"
        gap="4"
      >
        <SimpleGrid columns={{ base: 1, xl: 3 }} gap="5px">
          <Stack direction="column" gap="20px" width="200px">
            <Tooltip label={t('chooseMonth', { ns: ['labels'] })}>
              {/* Input with type "month" is not supported by FF so we use "date" */}
              <Input
                type={isFirefox ? 'date' : 'month'}
                size="md"
                color={textColor}
                value={(isFirefox ? startDate : formatDate(startDate, 'month')) || ''}
                onChange={handleStartDateChange}
                onKeyDown={(e) => e.preventDefault()}
              />
            </Tooltip>
          </Stack>
          {/* <Stack direction="column" ml={2} gap="20px" justifyContent={'center'}>
            <Checkbox
            isChecked={activeProjectsOnly}
            onChange={(event: any) => {
              handleActiveProjectsToggle(event.target.checked);
            }}
            >
              {t("onlyActiveProjects", { ns: ["labels"] })}
            </Checkbox>
          </Stack> */}
        </SimpleGrid>
        {/* Only display Spacer on larger screens */}
        <Spacer display={{ sm: 'none', lg: 'block' }} />

        {loadingState ? (
          <Spinner />
        ) : (
          <IconButton
            aria-label="Reload"
            variant="brand"
            icon={<RepeatIcon />}
            onClick={refreshData}
          >
            Reload
          </IconButton>
        )}
      </Flex>

      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Flex>
  );
}
