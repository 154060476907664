interface TableSettings {
  [x: string]: any;
}

export const TABLE_STORAGE_KEY = 'tables'

export const tableStorage = {
  fetch(tableName: string) {
    const tableSettings = this.fetchAll()

    if (!tableSettings[tableName]) tableSettings[tableName] = {}

    return tableSettings[tableName]
  },

  fetchAll: () => JSON.parse(localStorage.getItem(TABLE_STORAGE_KEY) || '[]'),

  save(tableName: string, tableSettings: TableSettings) {
    const previousValues = this.fetchAll()
    const tableSettingsWithoutPage = { ...tableSettings }
    const newFilter = {
      [tableName]: { ...previousValues[tableName], ...tableSettingsWithoutPage },
    }
    localStorage.setItem(TABLE_STORAGE_KEY, JSON.stringify({ ...previousValues, ...newFilter }))
  },
}
