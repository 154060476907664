import { create } from 'zustand';
import { produce } from 'immer';

export interface FormErrorsStore {
  errors: string[];
  resetErrors: () => void;
  setErrors: (errors: string[]) => void;
  deleteError: (error: string) => void;
  deleteErrorsContainingString: (string: string) => void;
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
}

const useFormErrorsStore = create<FormErrorsStore>()(
    (set, get) => ({
      errors: [],
      setErrors: (errors: string[]) => 
        set(
          produce((state) => {
            state.errors = Array.from(new Set([...state.errors, ...errors].filter(err => err)));
          })
        ),
        resetErrors: () => set({ errors: [] }),
        deleteError: (error: string) =>
        set(
            produce((state) => {
              state.errors = state.errors.filter((err: string) => err !== error);
            })
          ),
      deleteErrorsContainingString: (string: string) =>
        set(
          produce((state) => {
            if (get().errors?.length) 
              state.errors = state.errors.filter((err: string) => !err.includes(string));
          })
        ),
      showErrors: false,
      setShowErrors: (value: boolean) => set({ showErrors: value }),
    }),
);

export default useFormErrorsStore;
