import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableBookings from './components/SearchTableBookings';

export default function Bookings() {
	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<SearchTableBookings/>
			</Card>
		</Flex>
	);
}
