export const globalDe = {
  projectName: 'Moon',
  chef: 'Chef',
  eng: 'English',
  de: 'Deutsch',
  error404: 'The page you are looking for does not exist!',
  underConstruction: 'Diese Seite ist noch nicht fertig. Bitte besuchen Sie uns erneut!',
  maybeBackHome: 'Hier können Sie Ihre Homepage neu laden, um fortzufahren.',
  youDontHaveAccess: 'Du hast keine Zugriffsrechte auf diese Seite :(',
  returnHome: 'Startseite',
  invalid: 'Invalid',
  abr: 'Abbreviation',
  name: 'Name',
  phone: 'Phone',
  persons: 'Persons',
  call: 'Call',
  total: 'Total',
  status: 'Status',
  type: 'Type',
  zReport: 'Z Report',
  xReport: 'X Report',
  restaurant: 'Restaurant',
  all: 'All',
  none: 'None',
  allNone: 'All/None',
  date: 'Date',
  paymentReports: 'Payment Reports',
  openPayments: 'Open payments',
  card: 'Card',
  cash: 'Cash',
  amazon: 'Amazon',
  cardmanual: 'Card Manual',
  deliveroo: 'Deliveroo',
  hungryhouse: 'Hungry House',
  invoice: 'Invoice',
  justeat: 'Justeat',
  ubereats: 'Ubereats',
  voucher: 'Voucher',
  galaxycard: 'Galaxy Card',
  website: 'Website',
  taxReport: 'Tax Report',
  itemUsage: 'Item Usage',
  cancellationReason: 'Cancellation reason',
  cancellationReasonFalseEntry: 'False entry',
  cancellationReasonGoodWill: 'Goodwill',
  cancellationReasonComplaint: 'Complaint',
  cancellationReasonReturn: 'Return',
  vattotal: 'VAT total',
  label: 'Label',
  driver: 'Driver',
  collectAll: 'Collect All',
  open: 'Open',
  sale: 'Sale',
  registerCard: 'Register Card',
  cardDetails: 'Card Details',
  walletDetails: 'Wallet Details',
  confirmed: 'Confirmed',
  pending: 'Pending',
  canceled: 'Canceled',
  shoppingBasket: 'Shopping basket',
  zNonTraining: 'Non-Training',
  zTraining: 'Training',
  orderDetails: 'Order summary',
  massOrder: 'Mass Order',
  massOrderPayment: 'Mass Payment',
  cancelOrderTitle: 'Cancel order',
  cancelOrder: 'Do you want want to cancel the selected order(s)? This can not be undone.',
  lastReceipt: 'Last receipt',
  list: 'Display',
  modify: 'Modify',
  success: 'Success',
  paymentCommands: 'Payment Commands',
  weDidntFindThatPage: 'Wir konnten diese Seite nicht finden',
}
