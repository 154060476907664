import { t } from "i18next";

type Item = { id?: number, [key: string]: any };

export const bulkChangeItemsInDBTable = async<T extends Item>(
  dataInDB: T[],
  newData: T[],
  createTrigger: (item: T) => void,
  updateTrigger: (itemId: number, item: T) => void,
  deleteTrigger: (itemId: number) => void
  ) => {
    let response = {
      type: 'success',
      message: t('success', { ns: ['success'] }),
    };

  if (dataInDB?.length) {
    // Create item if not in DB
    const itemsToCreate = newData.filter((itemNew: T) => {
      const itemFoundInDB = dataInDB.find((itemInDB: T) => {
        return itemNew.id && itemInDB.id === itemNew.id;
      })
      return !itemFoundInDB;
    });

    const itemIdsToDelete: number[] = [];
    const itemsToUpdate: T[] = [];

    dataInDB.forEach((itemInDB: T) => {
      const itemFromDBInNewData: T = newData.find((itemNew: T) => {
        return itemNew.id && itemNew.id === itemInDB.id
      });
      // Delete item if not in new data
      if (!itemFromDBInNewData) {
        itemIdsToDelete.push(itemInDB.id);

      } else if (itemFromDBInNewData?.id) {
        const fields = Object.keys(itemFromDBInNewData) as (keyof T)[];
        // Update item if some fields are different
        const isDifferent = fields.some(key => !itemInDB[key] || (itemInDB[key] !== itemFromDBInNewData[key]));

        if (isDifferent) {
          itemsToUpdate.push(itemFromDBInNewData);
        }
      }
    });

    if (itemsToCreate.length) {
      for (const item of itemsToCreate) {
        try {
          await createTrigger(item);
        } catch (err: any) {
          response = {
            type: 'error',
            message: err?.response?.data?.data?.body?.error?.detail || t('somethingWentWrong', { ns: ['errors'] }),
          };
        }
      }
    }
    if(itemIdsToDelete.length) {
      for (const itemId of itemIdsToDelete) {
        try {
          await deleteTrigger(itemId);
        } catch (err: any) {
          return {
            type: 'error',
            message: err?.response?.data?.data?.body?.error?.detail || t('somethingWentWrong', { ns: ['errors'] }),
          };
        }
      }
    }
    if(itemsToUpdate.length) {
      for (const item of itemsToUpdate) {
        try {
          await updateTrigger(item.id!, item);
        } catch (err: any) {
          response = {
            type: 'error',
            message: err?.response?.data?.data?.body?.error?.detail || t('somethingWentWrong', { ns: ['errors'] }),
          };
        }
      }
    }
  } else {
    // Create every item if there was nothing in DB items
    for (const item of newData) {
      try {
        await createTrigger(item);
      } catch (err: any) {
        response = {
          type: 'error',
          message: err?.response?.data?.data?.body?.error?.detail || t('somethingWentWrong', { ns: ['errors'] }),
        };
      }
    }
  }
  
  return response;
}
