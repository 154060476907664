type LocalizedValue = {
  id: number;
  language: string;
  [key: string]: string | number;
};

export const getLocalizedLabel = <T extends LocalizedValue>(localValuesArray: T[], valueId: number) => {
  const userLanguage = localStorage.getItem('i18nextLng') || 'en';
  
  return localValuesArray
    .filter((statusLocal) =>
    statusLocal.status_id === valueId)
    ?.find((statusLocal) =>
    statusLocal.language.toLowerCase() === userLanguage)
    ?.name_local || '';
}