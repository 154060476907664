export const hintsDe = {
  eventPermission:
    'Events permissions are inherited from the process that they trigger.',
  gdprHint: 'Only active objects are included in the following lists.',
  noExceptionMessage: 'No Exception Message',
  noExceptionStackTrace: 'No Exception Stack Trace',
  dataTooLArge:
    'The selected entry is too large to display. Would you like to store it as file?',
  pluginPresent:
    'This module contains plugin. Only install if you trust the source of the module!',
  saveFirst: 'Save first to use this feature!',
  addKeyFirst: 'Please add Key before opening Editor!',
  slackMessage: 'Line breaks will be removed on save',
  acceptedHint: 'Achtung: Nur AKZEPTIERTE Buchungen werden berücksichtigt.',
  loginHint: 'Geben Sie E-Mail und Passwort ein, um sich anzumelden',
  enterEmail: 'Bitte geben Sie Ihre E-Mail ein',
  enterPassword: 'Bitte geben Sie Ihr Passwort ein',
  loginFailed: 'Login fehlgeschlagen',
  passwordPlaceholder: 'Min. 8 Zeichen',
  tasksHint: 'Sie müssen mindestens eine Aufgabe hinzufügen',
  titleRequired: 'Titel erforderlich',
  titleMinLength: 'Titel muss mindestens 3 Zeichen haben',
  titleMaxLength: 'Titel darf maximal 128 Zeichen haben',
  startDateRequired: 'Startdatum erforderlich',
  amountOfHoursRequired: 'Stundenmenge erforderlich',
  amountOfHoursMax: 'Die Anzahl der Stunden muss kleiner als 4096 sein',
  amountOfHoursMin: 'Die Anzahl der Stunden muss größer als 0 sein',
  hoursPerMonthMax: 'Die Anzahl der Stunden pro Monat muss kleiner als 4096 sein',
  hoursPerMonthMin: 'Die Anzahl der Stunden pro Monat muss größer als 0 sein',
  hoursPerMonthRequired: 'Die Anzahl der Stunden pro Monat ist erforderlich',
  descriptionLength: 'Beschreibung maximale Länge: 10240 Zeichen',
  endDateBeforeStartDate: 'Enddatum kann nicht bevor Startdatum sein',
  typeRequired: 'Typ ist erforderlich',
  hourFormatRequired: 'Stundenformat ist erforderlich',
  emailRequired: 'E-Mail ist erforderlich',
  companyRequired: 'Firmenname ist erforderlich',
  errorByCompany: 'Fehler bei der Firma',
  companyNameMinLength: 'Beschreibung muss mindestens 1 Zeichen haben',
  companyNameMaxLength: 'Beschreibung darf maximal 1024 Zeichen haben',
  addressLine1MinLength: 'Adresse muss mindestens 1 Zeichen haben',
  addressLine1MaxLength: 'Adresse darf maximal 1024 Zeichen haben',
  addressLine1Required: 'Adresszeile 1 erforderlich',
  zipCodeRequired: 'Postleitzahl erforderlich',
  zipCodeMaxLength: 'Postleitzahl darf maximal 5 Zeichen haben',
  cityRequired: 'Stadt erforderlich',
  cityMinLength: 'Stadt muss mindestens 1 Zeichen haben',
  cityMaxLength: 'Stadt darf maximal 100 Zeichen haben',
  countryRequired: 'Land erforderlich',
  countryMinLength: 'Land muss mindestens 2 Zeichen haben',
  countryMaxLength: 'Land darf maximal 100 Zeichen haben',
  typeErrorNumber: 'Zahl eingeben',
  typeErrorUser: 'Benutzer auswählen',
  mustBeEmptyForAdmins: 'Muss leer sein für Admins',
  atLeastOneUser: 'Mindestens ein Benutzer ist erforderlich',
  atLeastOneTechAdmin: 'Mindestens ein Technical Admin ist erforderlich',
  roleRequired: 'Rolle auswählen',
  hoursMax: 'Maximale Stundenanzahl beträgt 240',
  hoursMin: 'Minimale Stundenanzahl beträgt 1',
  numberGreaterThanZero: 'Zahl muss größer als 0 sein',
  lessOrEqualHundred: 'Zahl muss kleiner oder gleich 100 sein.',
  roundingRequired: 'Rundung ist erforderlich',
  languageRequired: 'Sprache ist erforderlich',
  currencyRequired: 'Währung ist erforderlich',
  topRequired: 'Zahlungsbedingung ist erforderlich',
  projectRequired: 'Projekt ist erforderlich',
  taskRequired: 'Aufgabe ist erforderlich',
  taskAlreadyExists: 'Aufgabe mit diesem Namen existiert bereits',
  statusRequired: 'Status ist erforderlich',
  descriptionRequired: 'Beschreibung ist erforderlich',
  yes: 'Ja',
  no: 'Nein',
  readOnly: 'Nur lesbar, wenn die Eingabe nach Zeitrahmen ausgewählt ist',
  dateRequired: 'Datum ist erforderlich',
  durationRequired: 'Dauer ist erforderlich',
  durationMax: 'Dauer muss kleiner als 4096 sein',
  durationMin: 'Dauer muss größer als 0 sein',
  addressMinLength3: 'Die Adresse sollte mindestens 3 Zeichen lang sein',
  zipCodeMinLength3: 'Zip sollte mindestens 3 Zeichen lang sein',
  cityMinLength2: 'Ort sollte mindestens 2 Zeichen lang sein',
  countryMinLength2: 'Land sollte mindestens 2 Zeichen lang sein',
  addressRequired: 'Adresse ist erforderlich',
  minLength2: 'Die Mindestlänge ist 2',
  minLength3: 'Die Mindestlänge ist 3',
  emailShouldBeValid: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
  phoneShouldBeValid: 'Bitte geben Sie eine gültige Telefonnummer ein',
  nameRequired: 'Name ist erforderlich',
  companyNameMinLength1: 'Firmenname sollte mindestens 1 Zeichen lang sein',
  mustBeOneBilling: 'Sie können eine Rechnungsadresse angeben',
  usersShouldBeUnique: 'Benutzername sollte eindeutig sein',
  costCenterBillableRequired: 'Cost Center ist erforderlich',
  userRequired: 'Sie müssen mindestens einen Benutzer hinzufügen',
  rateRequired: 'Satz ist erforderlich',
  vatRequired: 'VAT ist erforderlich',
  allRolesInList: 'Du kannst nicht dieser Benutzer noch einmal hinzufügen. Alle Rollen sind bereits in der Liste.',
};
