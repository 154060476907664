import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { format } from 'date-fns';
import {
  Tooltip,
  IconButton,
  SimpleGrid,
  Spacer,
  Spinner,
  Flex,
  Text,
  useColorModeValue,
  Icon,
  Stack,
  Input,
} from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import useLoaderStore from 'contexts/globalStore';
import { RepeatIcon } from '@chakra-ui/icons';
import { useBookingStore } from 'contexts/globalStoreTimeBookings';
import { useReportsStore } from 'contexts/globalStoreReports';
import { tableStorage } from 'contexts/tableStorage';
import { convertMinutesToHHmmForInput, getMonthRangeFromDateValue, zonedEndOfMonth } from 'utils/dateHelpers';
import { User, useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';
import { ProjectUser, useProjectStore } from 'contexts/globalStoreProjects';
import FakturaTable from 'components/tables/FakturaTable';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = {
  user: string;
  project: string;
  currency: string;
  dateFrom: string;
  totalDuration: number;
  unit: string;
  totalResourceCost: number;
  totalClientCost: number;
};

export default function SearchTableMonthlyReports() {
  const textColor = useColorModeValue('navy.700', 'white');

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const formatDate = useBookingStore((state) => state.formatDate);

  const isInitDone = useReportsStore((state) => state.isInitDone);
  const { fakturaReportsByMonth } = useReportsStore(
    (state: any) => ({ fakturaReportsByMonth: state.reportsByMonth }),
  );
  const { reportsByMonthMeta } = useReportsStore(
    (state: any) => ({ reportsByMonthMeta: state.reportsByMonthMeta }),
  );

  const fakturaUsers = useFakturaUsersStore((state: any) => state.users);
  const allProjectUsers = useProjectStore((state) => state.allProjectUsers);
  const getProjectByProjectUserId = useProjectStore((state) => state.getProjectByProjectUserId);

  const fetchMonthsReports = useReportsStore(
    (state) => state.fetchMonthlyReports,
  );

  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);

  const toISOWithoutTimezone = (d: any) => {
    let pad = (number: number) => (number < 10 ? '0' + number : number);

    return (
      d.getFullYear() +
      '-' +
      pad(d.getMonth() + 1) +
      '-' +
      pad(d.getDate()) +
      'T' +
      pad(d.getHours()) +
      ':' +
      pad(d.getMinutes()) +
      ':' +
      pad(d.getSeconds()) +
      '.' +
      (d.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
      'Z'
    );
  };

  const beginningOfCurrentMonth = () => {
    let date = new Date(); // Current date
    date.setDate(1); // Set to the 1st of the current month
    date.setHours(0, 0, 0, 0); // Set time to the start of the day
    return toISOWithoutTimezone(date);
  };

  const endOfCurrentMonth = () => {
    let date = new Date(); // Current date
    date.setMonth(date.getMonth() + 1); // Add one month
    date.setDate(0); // Set to the last day of the previous month
    date.setHours(23, 59, 59, 999); // Set time to the end of the day
    return date.toISOString();
  };

  const [startDate, setStartDate] = useState(
    formatDate(beginningOfCurrentMonth(), 'date'),
  );
  const [endDate, setEndDate] = useState(
    formatDate(endOfCurrentMonth(), 'date'),
  );

  const [activeProjectsOnly, setActiveProjectsOnly] = useState<boolean>(true);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const refreshData = useCallback(() => {
    setLoadingState(true);
    fetchMonthsReports(
      startDate,
      zonedEndOfMonth(new Date(endDate)),
      activeProjectsOnly,
      pagination.pageIndex + 1,
      pagination.pageSize,
      sorting[0]?.id || null,
      sorting[0]?.desc ? 'desc' : 'asc'
    )
      .then(() => {
        setLoadingState(false);
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Faktura Reports:', error);
      });
  }, [activeProjectsOnly,
    endDate,
    fetchMonthsReports,
    pagination,
    setLoadingState,
    startDate,
    sorting]);

  const resetReportMonthList = useReportsStore(
    (state) => state.resetMonthlyReportList,
  );

  const isFirefox = useMemo(() => navigator.userAgent.toLowerCase().includes('firefox'), []);

  const handleStartDateChange = (e: any) => {
    const { startDay, endDay } = getMonthRangeFromDateValue(e.target.value);
    setStartDate(startDay);
    setEndDate(endDay);

    fetchMonthsReports(startDay, endDay);
    setData(fakturaReportsByMonth);
  };

  // const handleActiveProjectsToggle = (value: boolean) => {
  //   const newActiveProjectsOnlyValue = value;
  //   setActiveProjectsOnly(newActiveProjectsOnlyValue);
  // };

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && isInitDone) {
      if (startDate && endDate) {
        refreshData();
      } else {
        resetReportMonthList();
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [endDate, isInitDone, refreshData, resetReportMonthList, startDate]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) setData(fakturaReportsByMonth);

    return () => {
      isCurrent = false;
    };
  }, [fakturaReportsByMonth]);

  const [globalFilter, setGlobalFilter] = useState('');

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('dateFrom', {
      id: 'dateFrom',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('dateFrom', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const dateValue = new Date(info.getValue()).toLocaleDateString();
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {dateValue}
          </Text>
        );
      },
    }),
    columnHelper.accessor('user', {
      id: 'user',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const userId = info.row.original.userId;

        const projectUser = allProjectUsers.find(
          (projectUser: ProjectUser) => Number(projectUser.id) === Number(userId)
        )
        if (!projectUser) {
          return null;
        }

        const user = fakturaUsers.find((user: User) =>
          projectUser.userId === user.userId);

        if (user) {
          const userDisplay = `${user.name} (${user.email})`;
          return (
            <Text color={textColor} key={userId} fontSize="md" fontWeight="500">
              {userDisplay}
            </Text>
          );
        } else {
          return null;
        }
      },
    }),
    columnHelper.accessor('project', {
      id: 'project',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('project', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const userId = info.row.original.userId;
        const project = getProjectByProjectUserId(userId)

        if (project) {
          return (
            <Flex alignItems={'center'}>
              <Text
                color={textColor}
                key={project.id}
                fontSize="md"
                fontWeight="500"
              >
                {project.title}
              </Text>
              <Icon
                ml="5px"
                color={project.isActive ? 'green.500' : 'gray.500'}
                as={MdCircle}
                w="10px"
                h={'10px'}
              />
            </Flex>
          );
        } else {
          return null;
        }
      },
    }),

    columnHelper.accessor('totalDuration', {
      id: 'totalDuration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('totalAmountHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
          }}
        >
          {convertMinutesToHHmmForInput(Number(info.getValue()))}
        </Text>
      ),
    }),

    columnHelper.accessor('unit', {
      id: 'unit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('unit', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {t('hours', { ns: ['labels'] })}
        </Text>
      ),
    }),

    columnHelper.accessor('totalResourceCost', {
      id: 'totalResourceCost',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('totalResourceCostHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
          }}
        >
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('totalClientCost', {
      id: 'totalClientCost',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('totalResourceClientCostHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'center',
          }}
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('currency', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const userId = info.row.original.userId;
        const project = getProjectByProjectUserId(userId)

        if (project) {
          return (
            <Text color={textColor} fontSize="md" fontWeight="500">
              {project.currency}
            </Text>
          );
        } else {
          return null;
        }
      },
    }),
  ];

  const [data, setData] = useState(() => []);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting
    },
    pageCount: reportsByMonthMeta.totalPages,
    rowCount: reportsByMonthMeta.totalItems,
    onPaginationChange: setPagination,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const tableName = useMemo(() => 'reportsMonthly', []);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      startDate: newStartDate,
      activeProjectsOnly: newActiveProjectsOnly,
      pageSize: newPageSize,
      sorting: newSorting
    } = tableStorage.fetch(tableName);

    setGlobalFilter(newGlobalFilter);
    setActiveProjectsOnly(newActiveProjectsOnly);
    if (newPageSize) setPagination({ ...pagination, pageSize: newPageSize });
    if (newSorting) setSorting(newSorting)

    const selectedDateValue = newStartDate ?? format(new Date(), 'yyyy-MM-dd');
    const { startDay, endDay } = getMonthRangeFromDateValue(selectedDateValue);
    setStartDate(startDay);
    setEndDate(endDay);
    // Don't add 'pagination' below to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, {
      globalFilter,
      startDate,
      activeProjectsOnly,
      pageSize: pagination.pageSize,
      sorting
    })
  }, [activeProjectsOnly, globalFilter, pagination.pageSize, sorting, startDate, tableName])

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'auto', lg: 'auto' }}>
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify={{ sm: 'flex-start', lg: 'space-between' }}
        w="100%"
        px="22px"
        mb="10px"
        minWidth="max-content"
        gap="4"
      >
        <SimpleGrid columns={{ base: 1, xl: 3 }} gap="5px">
          <Stack direction="column" gap="20px" width="200px">
            <Tooltip label={t('chooseMonth', { ns: ['labels'] })}>
              {/* Input with type "month" is not supported by FF so we use "date" */}
              <Input
                type={isFirefox ? 'date' : 'month'}
                size="md"
                color={textColor}
                value={(isFirefox ? startDate : formatDate(startDate, 'month')) || ''}
                onChange={handleStartDateChange}
                onKeyDown={(e) => e.preventDefault()}
              />
            </Tooltip>
          </Stack>
          {/* <Stack direction="column" gap="20px" ml={2} justifyContent={'center'}>
            <Checkbox
              isChecked={activeProjectsOnly}
              onChange={(event: any) => {
                handleActiveProjectsToggle(event.target.checked);
              }}
            >
              {t("onlyActiveProjects", { ns: ["labels"] })}
            </Checkbox>
          </Stack> */}
        </SimpleGrid>
        {/* Only display Spacer on larger screens */}
        <Spacer display={{ sm: 'none', lg: 'block' }} />

        {loadingState ? (
          <Spinner />
        ) : (
          <IconButton
            aria-label="Reload"
            variant="brand"
            icon={<RepeatIcon />}
            onClick={refreshData}
          >
            Reload
          </IconButton>
        )}
      </Flex>
      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Flex>
  );
}
