export const hintsEn = {
  eventPermission:
    'Events permissions are inherited from the process that they trigger.',
  gdprHint: 'Only active objects are included in the following lists.',
  noExceptionMessage: 'No Exception Message',
  noExceptionStackTrace: 'No Exception Stack Trace',
  dataTooLArge:
    'The selected entry is too large to display. Would you like to store it as file?',
  pluginPresent:
    'This module contains plugin. Only install if you trust the source of the module!',
  saveFirst: 'Save first to use this feature!',
  addKeyFirst: 'Please add Key before opening Editor!',
  slackMessage: 'Line breaks will be removed on save',
  acceptedHint: 'Attention: only ACCEPTED bookings are taken into account',
  loginHint: 'Enter your email and password to sign in',
  enterEmail: 'Please enter your email',
  enterPassword: 'Please enter your password',
  loginFailed: 'Login failed',
  passwordPlaceholder: 'Min. 8 characters',
  tasksHint: 'You need to add at least one task',
  taskAlreadyExists: 'Task with this name already exists',
  titleRequired: 'Title is required',
  titleMinLength: 'Title must be at least 3 characters long',
  titleMaxLength: 'Title cannot exceed 128 characters',
  startDateRequired: 'Start date is required',
  amountOfHoursRequired: 'Amount of hours is required',
  amountOfHoursMax: 'The number of hours must be less than 4096',
  amountOfHoursMin: 'The number of hours must be greater than 0',
  hoursPerMonthMax: 'The number of hours per month must be less than 4096',
  hoursPerMonthMin: 'The number of hours per month must be greater than 0',
  hoursPerMonthRequired: 'Hours per month is required',
  descriptionLength: 'Description maximum length: 10240 characters',
  endDateBeforeStartDate: 'End date cannot be before start date',
  typeRequired: 'Type is required',
  hourFormatRequired: 'Hour format is required',
  companyRequired: 'Company name is required',
  errorByCompany: 'Error by company',
  emailRequired: 'Email is required',
  companyNameMinLength: 'Company name must be at least 1 character long',
  companyNameMaxLength: 'Company name cannot exceed 1024 characters',
  addressLine1MinLength: 'Address must be at least 1 character long',
  addressLine1MaxLength: 'Address cannot exceed 1024 characters',
  addressLine1Required: 'Address line 1 is required',
  zipCodeRequired: 'Zip code is required',
  zipCodeMaxLength: 'Zip code cannot exceed 5 characters',
  cityRequired: 'City is required',
  cityMinLength: 'City must be at least 1 character long',
  cityMaxLength: 'City cannot exceed 100 characters',
  countryRequired: 'Country is required',
  countryMinLength: 'Country must be at least 2 characters long',
  countryMaxLength: 'Country cannot exceed 100 characters',
  typeErrorNumber: 'Enter number',
  mustBeEmptyForAdmins: 'Must be empty for admins',
  atLeastOneUser: 'At least one user is required',
  atLeastOneTechAdmin: 'At least one tech admin is required',
  typeErrorUser: 'Select user',
  roleRequired: 'Select role',
  hoursMax: 'Maximum hours allowed is 240',
  hoursMin: 'Minimum hours allowed is 1',
  numberGreaterThanZero: 'Number must be greater than 0',
  lessOrEqualHundred: 'Number must be less than or equal to 100.',
  roundingRequired: 'Rounding is required',
  languageRequired: 'Language is required',
  currencyRequired: 'Currency is required',
  topRequired: 'Terms of payment is required',
  projectRequired: 'Project is required',
  taskRequired: 'Task is required',
  statusRequired: 'Status is required',
  descriptionRequired: 'Description is required',
  yes: 'Yes',
  no: 'No',
  readOnly: 'Readonly when entry by timeframe is selected',
  dateRequired: 'Date is required',
  durationRequired: 'Duration is required',
  durationMax: 'Duration must be less than 4096',
  durationMin: 'Duration must be greater than 0',
  addressMinLength3: 'Address should be at least 3 characters',
  zipCodeMinLength3: 'Zip code should be at least 3 characters',
  cityMinLength2: 'City should be at least 2 characters',
  countryMinLength2: 'Country should be at least 2 characters',
  addressRequired: 'Address is required',
  minLength2: 'Minimum length is 2',
  minLength3: 'Minimum length is 3',
  emailShouldBeValid: 'Please enter a valid email address',
  phoneShouldBeValid: 'Please enter a valid phone number',
  nameRequired: 'Name is required',
  companyNameMinLength1: 'Company name should be at least 1 character',
  mustBeOneBilling: 'You must select one billing address',
  usersShouldBeUnique: 'Usernames should be unique',
  costCenterBillableRequired: 'Cost center billable is required',
  userRequired: 'At least one user is required',
  vatRequired: 'VAT is required',
  rateRequired: 'Rate is required',
  allRolesInList: "You can't add this user again, all roles are already in the list",
};
