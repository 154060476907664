export const actionsEn = {
  createCompany: 'Create Company',
  edit: 'Edit',
  duplicate: 'Duplicate',
  activate: 'Activate',
  deactivate: 'Deactivate',
  cancel: 'Cancel',
  create: 'Create',
  update: 'Update',
  submit: 'Submit',
  paid: 'Paid',
  clear: 'Clear',
  selectTime: 'Select time',
  unpaid: 'Unpaid',
  moveItems: 'Move items',
  save: 'Save',
  open: 'Open',
  show: 'Show',
  select: 'Select',
  selectDate: 'Select date',
  selectMethod: 'Select method',
  selectTerminal: 'Select terminal',
  reprint: 'Reprint',
  back: 'Back',
  download: 'Download',
  cancellation: 'Is Cancellation',
  isCanceled: 'Is Canceled',
  isPaid: 'Is Paid',
  print: 'Print',
  delete: 'Delete',
  close: 'Close',
  yes: 'Yes',
  no: 'No',
  addItem: 'Add item',
  none: 'None',
  success: 'Success',
  signatureOk: 'Signature Ok',
  signatureFailed: 'Signature Failed',
  chooseTime: 'Choose time',
  setDefault: 'Set default',
  proceed: 'Proceed',
  generate: 'Generate',
  exportType: 'Select data to be included:',
  backToOrder: 'Back to order',
  noOfPrints: 'No. of prints',
  view: 'View',
  lineOneSearch: 'Enter Line one to Search.',
  clearBasket: 'Clear',
  orderNow: 'Order now',
  refreshBtn: 'Refresh',
  reload: 'Reload',
  selectAll: 'Select All',
  selectNone: 'Select None',
  loading: 'Loading...',
  showInvoice: 'Show invoice',
  startTyping: 'Start typing to Search',
  manageSteps: 'Manage Steps',
  copyCreate: 'Copy/Create',
  saveAll: 'Save All',
  test: 'Test',
  resetPassword: 'Reset Password',
  copy: 'Copy',
  paste: 'Paste',
  filter: 'Filter',
  execute: 'Execute',
  check: 'Check',
  restore: 'Restore',
  add: 'Add',
  openProcess: 'Open',
  export: 'Export',
  import: 'Import',
  validate: 'Validate',
  clickToImport: 'Click To Import',
  copyUrl: 'Copy Url',
  addRule: 'Add Rule',
  openRule: 'Open',
  addField: 'Add Field',
  openTemplate: 'Open',
  generateApiKey: 'Generate Api Key',
  copyApiKey: 'Copy Api Key',
  uploadModule: 'Upload',
  downloadModule: 'Download',
  install: 'Install',
  remove: 'Remove',
  addAction: 'Add Action',
  deleteAction: 'Delete Action',
  openPlugin: 'Open',
  searchStep: 'Search Step Name',
  reset: 'Reset',
  downloadSwagger: 'Swagger',
  book: 'Book',
  approve: 'Approve',
  decline: 'Decline',
  change: 'Change',
  preview: 'Preview',
  timesheetPdf: 'Timesheet',
  pdf: 'PDF',
  cancelPreview: 'Cancel Preview',
  markAsPayed: 'Mark as payed',
  markAsNotPayed: 'Mark as not payed',
  markAsSent: 'Mark as sent',
  markAsObsolete: 'Mark as obsolete',
  areYouSureDeleteBooking: 'Are you sure you want to delete this booking?',
}
