import {
  FormLabel,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  Checkbox,
  Button,
  Box,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import useFormErrorsStore from 'contexts/formErrorsStore';
import { Types, useProjectStore } from 'contexts/globalStoreProjects';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .min(3, 'titleMinLength')
    .max(128, 'titleMaxLength')
    .required('titleRequired'),
  startDate: yup.date().typeError('startDateRequired').required('startDateRequired'),
  amountOfHours: yup
    .number()
    .max(4096, 'amountOfHoursMax')
    .min(1, 'amountOfHoursMin'),
  type: yup.string().required('typeRequired'),
  hoursPerMonth: yup.number().test('isRequiredIfTypeIsHourBudgetWithMonthlyLimit',
    'hoursPerMonthRequired',
    function (value, context) {
      const type = context?.parent?.type || context.options.context?.type;
      if (!type) return false;
      return type === 'HOUR_BUDGET_WITH_MONTHLY_LIMIT' ? Boolean(value) : true;
    },
  ),
  description: yup.string().nullable().max(10240, 'descriptionLength'),
  docURL: yup.string().url().nullable(),
});

export const ProjectDetails = ({ viewOnly }: { viewOnly: boolean }) => {
  let { id } = useParams();

  const textColor = useColorModeValue('navy.700', 'white');

  const singleProjectData = useProjectStore((state: any) => state.project);
  const setTrue = useProjectStore((state) => state.setToTrue);
  const setProjectCreateObj = useProjectStore((state) => state.setProjectData);

  const initialEachFieldStateValidation = useMemo(() => ({
    title: { valid: true, error: '' },
    startDate: { valid: true, error: '' },
    amountOfHours: { valid: true, error: '' },
    type: { valid: true, error: '' },
    hoursPerMonth: { valid: true, error: '' },
    description: { valid: true, error: '' },
    endDate: { valid: true, error: '' },
    docURL: { valid: true, error: '' },
  }), []);

  const [eachFieldStateValidation, setEachFieldStateValidation] = useState(initialEachFieldStateValidation);

  const handleChange = (field: any, value: any) => {
    setProjectCreateObj({
      [field]: value,
    });
  };

  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');

  useEffect(() => {
    if (singleProjectData?.startDate) {
      const startDate = new Date(singleProjectData.startDate);

      // Check if the date was parsed correctly:
      if (!isNaN(startDate.getTime())) {
        setFormattedStartDate(startDate.toISOString().split('T')[0]);
      }
    }

    if (singleProjectData?.endDate) {

      const endDate = new Date(singleProjectData.endDate);

      if (!isNaN(endDate.getTime())) {
        setFormattedEndDate(endDate.toISOString().split('T')[0]);
      }
    } else {
      setFormattedEndDate(null);
    }
  }, [singleProjectData?.startDate, singleProjectData?.endDate])

  const projectTypes = useProjectStore((state) => state.types);

  const [endDateError, setEndDateError] = useState('');
  const [startDateError, setStartDateError] = useState('');

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteErrorsContainingString = useFormErrorsStore(
    (state) => state.deleteErrorsContainingString,
  )

  useEffect(() => {
    setEndDateError('');
    setStartDateError('');
    const startDateValue = singleProjectData?.startDate;
    const endDateValue = singleProjectData?.endDate;

    if (endDateValue && startDateValue > endDateValue) {
      setEndDateError('endDateBeforeStartDate');
    } else {
      setEndDateError('');
      setStartDateError('');
    }
  }, [singleProjectData?.startDate, singleProjectData?.endDate]);

  const validateFormData = useCallback(async (formData: any) => {
    const detailsError = t('detailsError', { ns: ['errors'] });
    deleteErrorsContainingString(detailsError);

    try {
      await validationSchema.validateSync(formData, {
        abortEarly: false,
        context: formData,
      });
      setTrue('detailsValidation', true);
      setEachFieldStateValidation(initialEachFieldStateValidation)

    } catch (error: any) {
      setTrue('detailsValidation', false);

      // This is to show errors for fields after submit
      // Even if they were not touched
      //TODO DRY with Invoice tab
      const newEachFieldStateValidation: any = { ...eachFieldStateValidation };
      if (error.inner) {
        for (const e of error.inner) {
          const fieldErrors = await validateSingleField(e.path, e.value);

          if (fieldErrors && Array.isArray(fieldErrors)) {
            newEachFieldStateValidation[e.path] = { valid: false, error: fieldErrors.join(', ') };
          } else {
            newEachFieldStateValidation[e.path] = { valid: false, error: fieldErrors };
          }
        }
      }

      setEachFieldStateValidation(newEachFieldStateValidation);

      setErrors([...error.errors.map(
        (e: string) => `${detailsError}: ${t(e, { ns: ['hints'] })}`
      )]);
    }
    // Will work incorrectly if you add validateSingleField and eachFieldStateValidation as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setErrors, setTrue]);

  const [debouncedFormData, setDebouncedFormData] = useState({});

  useEffect(() => {
    const debounceValidation = setTimeout(() => {
      setDebouncedFormData(singleProjectData);
    }, 500);

    return () => clearTimeout(debounceValidation);
  }, [singleProjectData]);

  useEffect(() => { validateFormData(debouncedFormData) }, [debouncedFormData, validateFormData]);

  const validateSingleField = useCallback(async (path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchema, path);

    try {
      await field.validateSync(input, {
        abortEarly: false,
        context: singleProjectData,
      });

      setEachFieldStateValidation({
        ...eachFieldStateValidation,
        [path]: { valid: true, error: '' },
      });
      if (path === 'type') {
        await validateSingleField('hoursPerMonth', singleProjectData?.hoursPerMonth);
      }

    } catch (e: any) {
      if (e instanceof yup.ValidationError) {
        // Handle Yup validation error
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: false, error: e.errors.join(', ') },
        });
        return e.errors;
      } else {
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: false, error: e },
        });
        return e
      }
    }
  }, [eachFieldStateValidation, singleProjectData]);

  //TODO make generic function for every form
  const getFieldErrorTranslation = useCallback((fieldError: string) => {
    const errorsArray = fieldError.split(', ');

    if (errorsArray.length > 1) {
      return errorsArray.map((field) => t(field, { ns: ['hints'] })).join(', ');
    } else {
      return t(errorsArray[0], { ns: ['hints'] });
    }
  }, []);

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="20px">
      <Stack direction="column" gap="20px" mb="5">
        <Flex direction="column">
          <InputField
            mb="0px"
            id="title"
            color={textColor}
            placeholder={t('titlePlaceHolder', { ns: ['labels'] })}
            label={t('title', { ns: ['labels'] }) + '*'}
            value={singleProjectData?.title || ''}
            onChange={(event: any) => {
              handleChange('title', event.target.value);
            }}
            onBlur={(event: any) => {
              validateSingleField('title', event.target.value);
            }}
            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors ? getFieldErrorTranslation(eachFieldStateValidation['title'].error) : null}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
      <Stack direction="column" gap="20px" justifyContent={'center'}>
        <Checkbox
          mb={4}
          isChecked={singleProjectData?.isActive}
          onChange={(event: any) => {
            handleChange('isActive', event.target.checked);
          }}
          disabled={viewOnly}
        >
          {t("isActive", { ns: ["labels"] })}
        </Checkbox>
      </Stack>
      <Stack direction="column" gap="20px" mb="5">
        <Flex direction="column">
          <InputField
            mb="0px"
            pt="13px"
            pb="25px"
            id="startDate"
            color={textColor}
            label={t('startTime', { ns: ['labels'] }) + '*'}
            type="date"
            defaultValue={id ? formattedStartDate : ''}
            onInput={(event: any) => {
              handleChange('startDate', event.target.value);
              validateSingleField('startDate', event.target.value);
            }}
            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors && eachFieldStateValidation?.['startDate']?.error
              ? t(eachFieldStateValidation['startDate'].error, { ns: ['hints'] })
              : null}
            {t(startDateError, { ns: ['hints'] })}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
      <Stack direction="column" gap="20px">
        <Flex direction="column">
          <InputField
            mb="0px"
            pt="13px"
            pb="25px"
            id="endDate"
            color={textColor}
            label={t('endTime', { ns: ['labels'] })}
            defaultValue={id ? formattedEndDate : null}
            type="date"
            onInput={(event: any) => {
              handleChange('endDate', event.target.value || null);
              // TODO needs to be fixed
              // validateSingleField('endDate', event.target.value);
            }}

            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors ? t(eachFieldStateValidation['endDate'].error, { ns: ['hints'] }) : null}{' '}
            &nbsp;
            {t(endDateError, { ns: ['hints'] })}
          </Text>
        </Flex>
      </Stack>
      <Stack direction="column" gap="20px">
        <Flex direction="column">
          <FormLabel
            ms="10px"
            htmlFor="type"
            fontSize="sm"
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
          >
            {t('type', { ns: ['labels'] }) + '*'}
          </FormLabel>
          <Select
            variant="main"
            fontSize="sm"
            id="type"
            textColor={textColor}
            h="44px"
            maxH="44px"
            placeholder={t('select', { ns: ['labels'] })}
            value={singleProjectData?.type || ''}
            required
            onChange={async (event: any) => {
              handleChange('type', event.target.value);
              await validateSingleField('type', event.target.value);
            }}
            disabled={viewOnly}
          >
            {projectTypes?.map((type: Types) => {
              return (
                <option key={type.value} value={type.value}>
                  {t(type.label, { ns: ['status'] })}
                </option>
              );
            })}
          </Select>
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors ? t(eachFieldStateValidation['type']?.error, { ns: ['hints'] }) : null}{' '}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
      <Stack direction="column" gap="20px" mb="5">
        <Flex direction="column">
          <InputField
            color={textColor}
            mb="0px"
            id="amountHours"
            placeholder={t('hourPlaceHolder', { ns: ['labels'] })}
            label={t('amountOfHours', { ns: ['labels'] }) + '*'}
            value={singleProjectData?.amountOfHours || ''}
            onChange={(event: any) => {
              handleChange('amountOfHours', Number(event.target.value));
              validateSingleField('amountOfHours', Number(event.target.value));
            }}
            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors && eachFieldStateValidation?.['amountOfHours']?.error
              ? t(eachFieldStateValidation['amountOfHours'].error, { ns: ['hints'] })
              : null}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
      <Stack direction="column" gap="20px">
        <Flex direction="column">
          <InputField
            variant="main"
            color={textColor}
            mb="0px"
            id="hoursPerMonth"
            placeholder={t('hourPlaceHolder', { ns: ['labels'] })}
            label={t('hoursPerMonth', { ns: ['labels'] })}
            value={singleProjectData?.hoursPerMonth || ''}
            onChange={(event: any) => {
              handleChange('hoursPerMonth', Number(event.target.value));
              validateSingleField('hoursPerMonth', event.target.value);
            }}
            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors ? t(eachFieldStateValidation['hoursPerMonth']?.error, {
              ns: ['hints'],
            }) : null}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
      <Stack direction="column" gap="20px">
        <Flex direction="column">
          <TextField
            variant="main"
            h="146px"
            mb="0px"
            id="Description"
            color={textColor}
            placeholder={t('description', { ns: ['labels'] })}
            value={singleProjectData?.description || ''}
            label={t('description', { ns: ['labels'] })}
            onChange={(event: any) => {
              handleChange('description', event.target.value);
              validateSingleField('description', event.target.value);
            }}
            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors ? t(eachFieldStateValidation['description'].error, {
              ns: ['hints'],
            }) : null}{' '}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
      <Stack direction="column" gap="20px" width="100%" flexGrow={1}>
        <Flex direction="row" alignItems={'end'} justifyContent={'space-between'} width="100%" flexGrow={1}>
          <Box flex="1" width="100%">
            <InputField
              mb="0px"
              width="100%"
              id="docURL"
              placeholder={'Documentation URL'}
              label={'Documentation URL'}
              value={singleProjectData?.docURL || ''}
              onChange={(event: any) => {
                handleChange('docURL', event.target.value);
              }}
              onBlur={(event: any) => {
                validateSingleField('docURL', event.target.value);
              }}
              disabled={viewOnly}
            />
          </Box>
          <Button
            onClick={() => window.open(singleProjectData.docURL, '_blank')}
            ml={2}
            mb={1}
            isDisabled={!singleProjectData?.docURL}
          >
            {t('open', { ns: ['labels'] })}
          </Button>
        </Flex>
        <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
          {showErrors ? t(eachFieldStateValidation['docURL']?.error, { ns: ['hints'] }) : null}
          &nbsp;
        </Text>
      </Stack>
    </SimpleGrid>
  );
};
