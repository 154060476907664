import { createContext, useState } from "react";
import initialTheme from '../../theme/theme';

const ThemeContext = createContext({
	theme: initialTheme,
	setTheme: (theme: any) => {},
});

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const setTheme = (theme: any) => {
    setCurrentTheme(theme);
  }

	const contextValue = {
		theme: currentTheme,
		setTheme,
	};

	return (
		<ThemeContext.Provider value={contextValue}>
			{children}
		</ThemeContext.Provider>
	);
};

export { ThemeContext, ThemeProvider };
