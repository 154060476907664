import { Api, PostosCompanyStatusesBODYValidationRule, PostosCompanyStatusesLocalizationBODYValidationRule } from 'utils/postos-api';
import { create } from 'zustand';
import { produce } from 'immer';
import { UseToastOptions } from '@chakra-ui/react';
import { t } from 'i18next';
import { bulkChangeItemsInDBTable } from 'utils/storeHelpers';
import { PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { PaginationMeta, paginationMetaInitialState } from './globalStore';

const api = new Api();

export interface Company {
  id: number;
  name: string;
  status: number;
  registration_number: string;
  uid: string;
}

interface ToastObj {
type: UseToastOptions['status'];
  message: string;
}

interface CompanyStore {
  validation: Validation;
  statuses: PostosCompanyStatusesBODYValidationRule[];
  statusesLocal: PostosCompanyStatusesLocalizationBODYValidationRule[];
  company: PostosCompany;
  companies: Company[];
  companiesMeta: PaginationMeta,
  people: Person[];
  peopleInDB: Person[];
  addresses: Address[];
  addressesInDB: Address[];
  companyWorklog: WorklogEntry[];
  worklogEntry: WorklogEntry;
  worklogStatuses: string[];
  setWorklogEntry: (field: any, value: any) => void;
  setToTrue: (field: any, check: boolean) => void;
  addEntryToCompanyWorklog: (newEntry: any, userId: number) => void;
  addPersonToCompany: (newPerson: Person) => void;
  deletePersonFromCompany: (personIndex: any) => void;
  changePersonInCompany: (index: number, field: any, value: any) => void;
  addAddressToCompany: (newAddress: Address) => void;
  deleteAddressFromCompany: (userIndex: any) => void;
  updateAddressAtIndex: (index: number, field: any, value: any) => void;
  resetValidation: () => void;
  resetAll: () => void;
  resetPeople: () => void;
  resetAddresses: () => void;
  resetWorklog: () => void;
  resetWorklogEntry: () => void;
  fetchStatuses: () => Promise<void>;
  fetchStatusesLocal: () => Promise<void>;
  fetchCompanyWorklog: (companyId: number) => Promise<any[]>;
  fetchCompanies: (
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<void>;
  fetchPeople: (companyId: number) => Promise<void>;
  fetchAddresses: (companyId: number) => Promise<void>;
  addOrEditCompanyCall: (userId: number) => Promise<ToastObj>;
  setCompanyData: (newData: Partial<CompanyStore['company']>) => void;
  fetchSingleCompany: (id: number) => Promise<ToastObj>;
}

interface Validation {
  detailsValidation: boolean;
  peopleValidation: boolean;
  addressValidation: boolean;
}
export interface Person {
  id?: number;
  fullname: string;
  contact_email?: string;
  phone_number?: string;
  company_id: number;
  address_id: number;
}

export interface Address {
  id?: number;
  address_line_1: string;
  address_line_2?: string;
  zip_code: string;
  city: string;
  country: string;
  company_id: number;
  is_billing_address: boolean;
}

export interface WorklogEntry {
  id?: number;
  company_id: number;
  status: string;
  comment?: string;
  created_at: string;
  created_by: number;
  edited_at: string;
  edited_by: number;
}

export interface PostosCompany {
  id?: number;
  name: string;
  registration_number?: string;
  uid?: string;
  people?: Person[];
  addresses?: Address[];
  status: number;
  created_at: string;
  created_by: number;
  edited_at: string;
  edited_by: number;
}

export interface PostosCompanyForUpdate extends PostosCompany {
  id: number;
}

const initialValidationState: Validation = {
  detailsValidation: false,
  peopleValidation: true,
  addressValidation: false
};

const initialState: PostosCompany = {
  name: '',
  registration_number: '',
  uid: '',
  status: null,
  created_at: null,
  created_by: null,
  edited_at: null,
  edited_by: null
};

export const mappingCompanyFunction = (company: any) => {
  const remappedPeople = company.people?.map((person: any) => ({
    id: person.id,
    fullname: person.fullname,
    contact_email: person.contact_email || '',
    phone_number: person.phone_number || '',
    company_id: person.company_id,
    address_id: person.address_id,
  }));

  const remappedAddresses = company.addresses?.map((address: any) => ({
    id: address.id,
    address_line_1: address.address_line_1,
    address_line_2: address.address_line_2 || '',
    zip_code: address.zip_code,
    city: address.city,
    country: address.country,
    company_id: address.company_id,
    is_billing_address: address.is_billing_address,
  }));

  const remappedCompany = {
    id: company.id,
    name: company.name,
    status: company.status,
    registration_number: company.registration_number || '',
    uid: company.uid || '',
    created_at: company.created_at,
    created_by: company.created_by,
    edited_at: company.edited_at,
    edited_by: company.edited_by,
  };

  return {
    ...remappedCompany,
    people: remappedPeople,
    addresses: remappedAddresses,
  };
};

const worklogEntryInitialState: WorklogEntry = {
  company_id: null,
  status: 'CALL_PLACED',
  comment: '',
  created_at: null,
  created_by: null,
  edited_at: null,
  edited_by: null,
}

export const useCompanyStore = create<CompanyStore>((set, get) => ({
  set: (fn: any) => set(produce(fn)),
  companies: [],
  companiesMeta: paginationMetaInitialState,
  statuses: [],
  statusesLocal: [],
  worklogEntry: { ...worklogEntryInitialState },
  setWorklogEntry: (field: any, value: any) =>
    set(
      produce((state) => {
        state.worklogEntry[field] = value;
      }),
    ),
  resetWorklogEntry: () =>
    set(
      produce((state) => {
        state.worklogEntry = { ...worklogEntryInitialState };
      })
    ),
  companyWorklog: [],
  worklogStatuses: [
    'CALL_PLACED',
    'CALL_RECEIVED',
    'EMAIL_SENT',
    'EMAIL_RECEIVED',
    'NOTE',
    'OFFER_SENT'
  ],
  fetchStatuses: async () => {
    try {
      const response: any = await api.postosCompanyStatuses.postosCompanyStatusesSearchTriggerRest();
      const statuses = response?.data?.data?.body || [];
      
      set(
        produce((state) => {
          state.statuses = statuses;
        }),
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  fetchStatusesLocal: async () => {
    try {
      const response: any = await api.postosCompanyStatusesLocalization.postosCompanyStatusesLocalizationSearchTriggerRest();
      const statuses = response?.data?.data?.body || [];

      set(
        produce((state) => {
          state.statusesLocal = statuses;
        }),
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  fetchCompanyWorklog: async (companyId: number) => {
    await get().resetWorklog();
    
    if (!companyId) {
      await set(
        produce((state) => {
          state.companyWorklog = [];
        }),
      );
      return [];
    }
    try {
      const response: any = await api
        .postosCompanyWorklog
        .postosCompanyWorklogSearchTriggerRest({ company_id: companyId });

      const worklogResponse = response?.data?.data?.body || [];

      if (worklogResponse?.length && Array.isArray(worklogResponse)) {
        const worklog = worklogResponse.sort((a, b) => {
          const aCreatedAt = new Date(a.created_at)
          const bCreatedAt = new Date(b.created_at)
          
          if (aCreatedAt > bCreatedAt) {
              return -1;
          }
          if (aCreatedAt < bCreatedAt) {
              return 1;
          }
          return 0;
        });
        set(
          produce((state) => {
            state.companyWorklog = worklog;
          }),
        );
        return worklog;
      } else {
        await set(
          produce((state) => {
            state.companyWorklog = [];
          }),
        );
        return [];
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  fetchCompanies: async (
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc'
  ) => {
    if (!get().statuses.length) {
      await get().fetchStatuses();
    }
    if (!get().statusesLocal.length) {
      await get().fetchStatusesLocal();
    }
    try {
      const params: any = {
        page,
        size,
        orderField,
        orderDirection,
      };

      const response: any = await api.postosCompanies.postosCompaniesSearchTriggerRest(params);

      const companies = response?.data?.data?.body?.items || [];

      if (companies && Array.isArray(companies)) {
        const mappedCompanies: Company[] = companies.map((company) => ({
          id: company.id,
          name: company.name,
          status: company.status,
          registration_number: company.registration_number || '',
          uid: company.uid || '',
        }));

        set(
          produce((state) => {
            state.companies = mappedCompanies;
            state.companiesMeta = response.data?.data?.body?.meta 
            || { totalItems: mappedCompanies.length, totalPages: 1 };
          }),
        );
      } else {
        set(
          produce((state) => {
            state.companies = [];
            state.companiesMeta = paginationMetaInitialState; 
          }),
        );
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  validation: {
    ...initialValidationState,
  },
  setToTrue: (field: any, check: boolean) => {
    set(
      produce((state) => {
        state.validation[field] = check;
      }),
    );
  },
  resetValidation: () => {
    set({
      validation: { ...initialValidationState },
    });
  },
  company: { ...initialState },
  resetAll: () => {
    set({
      company: { ...initialState },
      people: [],
      addresses: [],
      peopleInDB: [],
      addressesInDB: [],
      companyWorklog: [],
      companiesMeta: paginationMetaInitialState,
    });
  },
  resetPeople: () => {
    set({
      people: [],
      peopleInDB: [],
    });
  },
  resetAddresses: () => {
    set({
      addresses: [],
      addressesInDB: [],
    });
  },
  resetWorklog: () => {
    set({
      companyWorklog: [],
    });
  },
  people: [],
  peopleInDB: [],
  fetchPeople: async (companyId: number) => {
    if (!companyId) {
      return;
    }
    get().resetPeople();
    try {
      const response: any = await api.postosCompanyPeople.postosCompanyPeopleSearchTriggerRest({company_id: companyId});

      const mappedPeople: Person[] = response?.data?.data?.body?.map(
        (person: any) => ({
          id: person.id,
          fullname: person.fullname,
          contact_email: person.contact_email || '',
          phone_number: person.phone_number || '',
          company_id: person.company_id,
          address_id: person.address_id,
        }),
      ) || [];

      set(
        produce((state) => {
          state.people = mappedPeople;
          state.peopleInDB = mappedPeople;
        }),
      );
    } catch (error) {
      console.error('Error fetching people:', error);
    }
  },
  addPersonToCompany: (newPerson: any) => {
    set(
      produce((state) => {
        state.people.push(newPerson);
      }),
    )
  },
  deletePersonFromCompany: (personIndex: any) => {
    set(
      produce((state) => {
        state.people.splice(personIndex, 1);
      }),
    )
  },
  changePersonInCompany: (index: number, field: any, value: any) => {
    set(
      produce((state) => {
        state.people[index][field] = value;
      }),
    )
  },
  addEntryToCompanyWorklog: async (newEntry: any, userId: number) => {
    try {
      const res: any = await api
        .postosCompanyWorklog
        .postosCompanyWorklogCreateTriggerRest({
          ...newEntry,
          company_id: get().company.id,
          created_at: new Date().toISOString(),
          created_by: userId,
          edited_at: new Date().toISOString(),
          edited_by: userId,
        });
      const entryIdFromRest = await res?.data?.data?.body?.id;
      
      return {
        type: 'success',
        message: `${t('entry', { ns: ['labels'] })} ${ t('created2', { ns: ['labels'] })}`,
        entryId: entryIdFromRest
      };
    } catch (error: any) {
      return {
        type: 'error',
        message:
          t('error', { ns: ['labels'] }) ||
          error.response?.data?.data?.body?.message,
      };
    }
  },

  addresses: [],
  addressesInDB: [],
  updateAddressAtIndex: (index: number, field: any, value: any) => {
    set(
      produce((state) => {
        state.addresses[index][field] = value;
      }),
    );
  },

  addAddressToCompany: (newAddress: any) => 
    set(
      produce((state) => {
        state.addresses.push(newAddress);
      }),
    ),
  deleteAddressFromCompany: (addressIndex: any) =>
    set(
      produce((state) => {
        state.addresses.splice(addressIndex, 1);
      }),
    ),
    
  setCompanyData: (newData) =>
    set(
      produce((state) => {
        state.company = { ...state.company, ...newData };
      }),
    ),

  fetchAddresses: async (companyId: number) => {
    if (!companyId) {
      return;
    }
    get().resetAddresses();
    try {
      const response: any = await api.postosCompanyAddresses.postosCompanyAddressesSearchTriggerRest({company_id: companyId});
      const mappedAddresses: Address[] = response?.data?.data?.body?.map(
        (address: any) => ({
          id: address.id,
          address_line_1: address.address_line_1,
          address_line_2: address.address_line_2 || '',
          zip_code: address.zip_code,
          city: address.city,
          country: address.country,
          company_id: address.company_id,
          is_billing_address: address.is_billing_address,
        }),
      ) || [];
      set(
        produce((state) => {
          state.addresses = mappedAddresses;
          state.addressesInDB = mappedAddresses;
        }),
      );
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  },
  fetchSingleCompany: async (companyId: number) => {
    await get().resetAll();

    if (!get().statuses.length) {
      await get().fetchStatuses();
    }
    if (!get().statusesLocal.length) {
      await get().fetchStatusesLocal();
    }

    try {
      const companyResponse: any = await api.postosCompanies.postosCompaniesFindByIdTriggerRest(companyId);
      const basicCompany = companyResponse.data.data.body;

      const peopleResponse: any = await api.postosCompanyPeople.postosCompanyPeopleSearchTriggerRest({company_id: companyId});
      const people = peopleResponse.data.data.body;

      const addressesResponse: any = await api.postosCompanyAddresses.postosCompanyAddressesSearchTriggerRest({company_id: companyId});
      const addresses = addressesResponse.data.data.body;

      const company = mappingCompanyFunction({ ...basicCompany, people, addresses });

      get().fetchPeople(companyId);
      get().fetchAddresses(companyId);

      set(
        produce((state) => {
          state.company = company;
          state.people = people;
          state.peopleInDB = people;
          state.addresses = addresses;
          state.addressesInDB = addresses;
        }),
      );
      
      return {
        status: companyResponse.status,
        type: 'success',
        message: t('fetchSuccess', { ns: ['success'] }),
      };
    } catch (error: any) {
      console.error('Error fetching company:', error);

      return {
        type: 'error',
        message:
          error?.response?.data?.message || t('fetchErrorCompany', { ns: ['errors'] }),
      };
    }
  },
  addOrEditCompanyCall: async (userId) => {
    const localCompany = structuredClone(get().company);
    const localPeople = structuredClone(get().people);
    const localAddresses = structuredClone(get().addresses);

    // We do it to not let a user create a company without addresses
    // Company_id removed, so we don't need to add it, we don't have it when company is just created
    // 'addresses' field in the company is just for validation, doesn't affect the entity itself
    const localAddressesForValidation = structuredClone(get().addresses)
      .map((address: Address) => {
        const { company_id, ...addressWithoutCompanyId } = address;
        return addressWithoutCompanyId;
      });

    if (localAddresses?.length < 1) {
      return {
        type: 'error',
        message: t('companyAddressRequired', { ns: ['labels'] })
      };
    }

    try {
      let res: any
      const { id: idFromLocal, name, registration_number, uid } = localCompany;
      if (idFromLocal) {
        res = await api.postosCompanies.postosCompaniesUpdateTriggerRest(
          idFromLocal,
          { name,
            registration_number,
            uid,
            status: Number(localCompany.status),
            created_at: localCompany.created_at,
            created_by: userId,
            edited_at: new Date().toISOString(),
            edited_by: userId,
            addresses: localAddressesForValidation
          });
      } else {
        res = await api.postosCompanies.postosCompaniesCreateTriggerRest(
          { name,
            registration_number,
            uid,
            status: Number(localCompany.status),
            created_at: new Date().toISOString(),
            created_by: userId,
            edited_at: new Date().toISOString(),
            edited_by: userId,
            addresses: localAddressesForValidation
          });
      }
      
      if (res) {
        const companyIdFromRest = await res?.data?.data?.body?.company?.id;

        localPeople.forEach((person: Person) => {
          if (!person.company_id) {
            person.company_id = companyIdFromRest;
          }
        })
    
        localAddresses.forEach((address: Address) => {
          if (!address.company_id) {
            address.company_id = companyIdFromRest;
          }
        })

        // Changing company addresses in corresponding entity
        const addressesChangeResponse = await bulkChangeItemsInDBTable(
          structuredClone(get().addressesInDB),
          localAddresses || [],
          api.postosCompanyAddresses.postosCompanyAddressesCreateTriggerRest,
          api.postosCompanyAddresses.postosCompanyAddressesUpdateTriggerRest,
          api.postosCompanyAddresses.postosCompanyAddressesDeleteTriggerRest
        )
        
        if (addressesChangeResponse.type !== 'success') {
          await get().fetchSingleCompany(companyIdFromRest);

          return {
            type: 'error',
            message: addressesChangeResponse.message
          }
        }

        // Changing company people in corresponding entity
        const peopleChangeResponse = await bulkChangeItemsInDBTable(
          structuredClone(get().peopleInDB),
          localPeople || [],
          api.postosCompanyPeople.postosCompanyPeopleCreateTriggerRest,
          api.postosCompanyPeople.postosCompanyPeopleUpdateTriggerRest,
          api.postosCompanyPeople.postosCompanyPeopleDeleteTriggerRest
        )

        if (peopleChangeResponse.type !== 'success') {
          await get().fetchSingleCompany(companyIdFromRest);

          return {
            type: 'error',
            message: peopleChangeResponse.message
          }
        }

        await get().fetchSingleCompany(companyIdFromRest);

        return {
          type: 'success',
          message: `${t('company', { ns: ['labels'] })} ${
            idFromLocal
              ? t('updated2', { ns: ['labels'] })
              : t('created2', { ns: ['labels'] })
          }`,
          companyId: companyIdFromRest
        };
      }
    } catch (error: any) {
      return {
        type: 'error',
        message:
          t('error', { ns: ['labels'] }) ||
          error.response?.data?.data?.body?.message,
      };
    }
  },
}));
