import { create } from 'zustand';

export interface PaginationMeta {
  totalItems: number,
  totalPages: number
}

export const paginationMetaInitialState = {
  totalItems: 0,
  totalPages: 0
}

interface LoadingState {
  isLoading: boolean;
  setLoader: (state: boolean) => void;
}

const useLoaderStore = create<LoadingState>((set) => ({
  isLoading: false,
  setLoader: (state: boolean) => set({ isLoading: state }),
}));

export default useLoaderStore;