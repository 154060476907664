import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableCompanies from './components/SearchTableCompanies';

export default function Companies() {
	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<SearchTableCompanies />
			</Card>
		</Flex>
	);
}
