import {
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import useFormErrorsStore from 'contexts/formErrorsStore';
import { useCompanyStore } from 'contexts/globalStoreCompanies';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getLocalizedLabel } from 'utils/translationHelpers';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(1, 'companyNameMinLength1')
    .required('companyNameMinLength1'),
  uid: yup
    .string()
    .nullable(),
  registration_number: yup
    .string()
    .nullable(),
  status: yup
    .number()
    .typeError('statusRequired')
    .required('statusRequired'),
});

export const CompanyDetails = ({ viewOnly }: { viewOnly: boolean }) => {
  const singleCompanyData = useCompanyStore((state: any) => state.company);
  const setTrue = useCompanyStore((state) => state.setToTrue);
  const setCompanyCreateObj = useCompanyStore((state) => state.setCompanyData);
  const companyStatuses = useCompanyStore((state: any) => state.statuses);
  const companyStatusesLocal = useCompanyStore((state: any) => state.statusesLocal);

  const statusesForSelect = useMemo(() => {
    return companyStatuses?.map((status: any) => {
      return {
        value: status.id,
        label: getLocalizedLabel(companyStatusesLocal, status.id)
      };
    }) || [];
  }, [companyStatuses, companyStatusesLocal]);

  const initialEachFieldStateValidation = useMemo(() => ({
    name: { valid: true, error: '' },
    status: { valid: true, error: '' },
    registration_number: { valid: true, error: '' },
    uid: { valid: true, error: '' },
  }), []);

  const [eachFieldStateValidation, setEachFieldStateValidation] = useState(initialEachFieldStateValidation);

  const handleChange = (field: any, value: any) => {
    setCompanyCreateObj({
      [field]: value,
    });
  };

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);

  const deleteErrorsContainingString = useFormErrorsStore(
    (state) => state.deleteErrorsContainingString,
  )

  const validateFormData = useCallback(async (formData: any) => {
    const detailsError = t('detailsError', { ns: ['errors'] });
    deleteErrorsContainingString(detailsError);

    try {
      await validationSchema.validateSync(formData, {
        abortEarly: false,
        context: formData,
      });
      setTrue('detailsValidation', true);
      setEachFieldStateValidation(initialEachFieldStateValidation)

    } catch (error: any) {
      setTrue('detailsValidation', false);

      // This is to show errors for fields after submit
      // Even if they were not touched
      //TODO DRY with Invoice tab
      const newEachFieldStateValidation: any = { ...eachFieldStateValidation };
      if (error.inner) {
        for (const e of error.inner) {
          const fieldErrors = await validateSingleField(e.path, e.value);

          if (fieldErrors && Array.isArray(fieldErrors)) {
            newEachFieldStateValidation[e.path] = { valid: false, error: fieldErrors[0] };
          } else {
            newEachFieldStateValidation[e.path] = { valid: false, error: fieldErrors };
          }
        }
      }

      setEachFieldStateValidation(newEachFieldStateValidation);

      setErrors([...error.errors.map(
        (e: string) => `${detailsError}: ${t(e, { ns: ['hints'] })}`
      )]);
    }
    // Will work incorrectly if you add validateSingleField and eachFieldStateValidation as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setErrors, setTrue]);

  const [debouncedFormData, setDebouncedFormData] = useState({});

  useEffect(() => {
    const debounceValidation = setTimeout(() => {
      setDebouncedFormData(singleCompanyData);
    }, 500);

    return () => clearTimeout(debounceValidation);
  }, [singleCompanyData]);

  useEffect(() => {
    validateFormData(debouncedFormData)
  }, [debouncedFormData, validateFormData]);

  const validateSingleField = useCallback(async (path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchema, path);

    try {
      await field.validateSync(input, {
        abortEarly: false,
        context: singleCompanyData,
      });

      setEachFieldStateValidation({
        ...eachFieldStateValidation,
        [path]: { valid: true, error: '' },
      });

    } catch (e: any) {
      if (e instanceof yup.ValidationError) {
        // Handle Yup validation error
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: false, error: e.errors.join(', ') },
        });
        return e.errors;
      } else {
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: false, error: e },
        });
        return e
      }
    }
  }, [eachFieldStateValidation, singleCompanyData]);

  const textColor = useColorModeValue('navy.700', 'white');

  const getFieldErrorTranslation = useCallback((fieldError: string) => {
    const errorsArray = fieldError.split(', ');

    if (errorsArray.length > 1) {
      return errorsArray.map((field) => t(field, { ns: ['hints'] })).join(', ');
    } else {
      return t(errorsArray[0], { ns: ['hints'] });
    }
  }, []);

  return (
    <SimpleGrid columns={{ base: 1, md: 3 }} spacingX="20px">
      <Stack direction="column" gap="20px" mb="5">
        <Flex direction="column">
          <InputField
            mb="0px"
            id="title"
            color={textColor}
            placeholder={t('name', { ns: ['labels'] })}
            label={t('name', { ns: ['labels'] }) + '*'}
            value={singleCompanyData?.name || ''}
            onChange={(event: any) => {
              handleChange('name', event.target.value);
              validateSingleField('name', event.target.value);
            }}
            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors && eachFieldStateValidation['name'].error
              ? getFieldErrorTranslation(eachFieldStateValidation['name'].error)
              : null}
            &nbsp;
          </Text>
        </Flex>
        <Flex direction="column">
          <InputField
            mb="0px"
            id="title"
            color={textColor}
            placeholder={t('registrationNumber', { ns: ['labels'] })}
            label={t('registrationNumber', { ns: ['labels'] })}
            value={singleCompanyData?.registration_number || ''}
            onChange={(event: any) => {
              handleChange('registration_number', event.target.value);
              validateSingleField('registration_number', event.target.value);
            }}
            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {t(eachFieldStateValidation['registration_number'].error, { ns: ['hints'] })}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
      <Stack direction="column" gap="20px">
        <Flex direction="column">
          <FormLabel
            ms="10px"
            htmlFor="status"
            fontSize="sm"
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
          >
            {t('status', { ns: ['labels'] }) + '*'}
          </FormLabel>
          <Select
            variant="main"
            fontSize="sm"
            id="status"
            color={textColor}
            h="44px"
            maxH="44px"
            placeholder={t('select', { ns: ['labels'] })}
            value={singleCompanyData?.status || ''}
            required
            onChange={async (event: any) => {
              handleChange('status', event.target.value);
              await validateSingleField('status', event.target.value);
            }}
            disabled={viewOnly}
          >
            {statusesForSelect?.map((status: any) => {
              return (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              );
            })}
          </Select>
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors ? t(eachFieldStateValidation['status']?.error, { ns: ['hints'] }) : null}{' '}
            &nbsp;
          </Text>
        </Flex>
        <Flex direction="column">
          <InputField
            mb="0px"
            id="title"
            color={textColor}
            placeholder={t('uid', { ns: ['labels'] })}
            label={t('uid', { ns: ['labels'] })}
            value={singleCompanyData?.uid || ''}
            onChange={(event: any) => {
              handleChange('uid', event.target.value);
              validateSingleField('uid', event.target.value);
            }}
            disabled={viewOnly}
          />
          <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {t(eachFieldStateValidation['uid'].error, { ns: ['hints'] })}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
    </SimpleGrid>
  );
};
