import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
// actions
import {actionsEn} from "./lang/en/actions.js";
import {actionsDe} from "./lang/de/actions.js";
// days
import {daysEn} from "./lang/en/days.js";
import {daysDe} from "./lang/de/days.js";
// months
import {monthsEn} from "./lang/en/months.js";
import {monthsDe} from "./lang/de/months.js";
// errors
import {errorsEn} from "./lang/en/errors.js";
import {errorsDe} from "./lang/de/errors.js";
// success
import {successEn} from "./lang/en/success.js";
import {successDe} from "./lang/de/success.js";
// labels
import {labelsEn} from "./lang/en/labels.js";
import {labelsDe} from "./lang/de/labels.js";
// header
import {headerEn} from "./lang/en/header.js";
import {headerDe} from "./lang/de/header.js";
// global
import {globalEn} from "./lang/en/global.js";
import {globalDe} from "./lang/de/global.js";
// hints
import {hintsEn} from "./lang/en/hints.js";
import {hintsDe} from "./lang/de/hints.js";
// routes
import {routesEn} from "./lang/en/routes.js";
import {routesDe} from "./lang/de/routes.js";
// status
import {statusEn} from "./lang/en/status.js";
import {statusDe} from "./lang/de/status.js";


//---Using different namespaces
const resources = {
    en: {
      actions: actionsEn,
      days: daysEn,
      months: monthsEn,
      errors: errorsEn,
      success: successEn,
      labels: labelsEn,
      header: headerEn,
      global: globalEn,
      hints: hintsEn,
      routes: routesEn,
      status: statusEn
    },
    de: {
      actions: actionsDe,
      days: daysDe,
      months: monthsDe,
      errors: errorsDe,
      success: successDe,
      labels: labelsDe,
      header: headerDe,
      global: globalDe,
      hints: hintsDe,
      routes: routesDe,
      status: statusDe
    }
}

i18next
.use(initReactI18next)
.init({
  resources,
  lng:"en", //default language
});

export default i18next;
